<template>
  <div class="ai-search">
    <el-input
      v-model="search"
      size="mini"
      placeholder="请输入内容"
      style="width: 780px"
    ></el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.ai-search {
  height: 36px;
  line-height: 36px;
  margin-right: 20px;
}
</style>
