<template>
  <div
    v-loading="loading"
    class="gitlabcode_wrap"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <collapse :title="$t('nodeDetail.activity.code.commit.title')">
      <el-table
      :data="commit_tableData"
      style="width: 95%;">
      <el-table-column
        prop="title"
        :label="$t('nodeDetail.activity.code.commit.repoName')"
        show-overflow-tooltip
        min-width="200">
        <template slot-scope="scope">
          <span>{{ get_repository_name(scope.row.gitProjectId) ? get_repository_name(scope.row.gitProjectId).nameWithNamespace :'' }}</span>
        </template>
      </el-table-column>
        <el-table-column
        prop="title"
        :label="$t('nodeDetail.activity.code.commit.repoTitle')"
        show-overflow-tooltip
        min-width="200">
        <template slot-scope="scope">
          <a :href="scope.row.url" target="_blank">{{ scope.row.title }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="stageList"
        :label="$t('nodeDetail.activity.code.commit.pipelineRes')"
        min-width="250"
        >
        <div slot-scope="scope" class="stageList_class">
          <span v-for="(item, index) in scope.row.stageList" :key="index">
            <el-tooltip
                effect="dark"
                :content="item.stage"
                placement="bottom"
                >
              <i class="iconfont" :class="'icon-gitlab_' + item.status"></i>
            </el-tooltip>
          </span>
        </div>
      </el-table-column>
      <el-table-column
        prop="pipelineVulnerabilityCount"
        :label="$t('nodeDetail.activity.code.commit.securityScan')">
        <template slot-scope="scope">
           <span v-if="scope.row.pipelineVulnerabilityCount <= 0 || !scope.row.pipelineVulnerabilityCount">{{ $t('nodeDetail.activity.history.null') }}</span>
           <span v-else>{{ scope.row.pipelineVulnerabilityCount }}{{ $t('nodeDetail.activity.code.commit.info') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="authorName"
        :label="$t('nodeDetail.activity.code.commit.operator')">
      </el-table-column>
      <el-table-column
        prop="timestamp"
        min-width="150"
        :label="$t('nodeDetail.activity.code.commit.opeTime')">
          <template slot-scope="scope">
           <span>{{ new Date(scope.row.timestamp).Format('yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
      </el-table-column>
    </el-table>
    </collapse>
     <collapse :title="$t('nodeDetail.activity.code.merge.title')" style="margin-top: 20px">
    <el-table
      :data="mrs_tableData"
      :border="false"
      style="width: 95%;">
      <el-table-column
        prop="title"
        :label="$t('nodeDetail.activity.code.merge.repoName')"
        show-overflow-tooltip
        min-width="200">
        <template slot-scope="scope">
          <span>{{ get_repository_name(scope.row.gitProjectId) ? get_repository_name(scope.row.gitProjectId).nameWithNamespace :'' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        :label="$t('nodeDetail.activity.code.merge.repoTitle')"
        show-overflow-tooltip
        min-width="160">
        <template slot-scope="scope">
          <a :href="scope.row.url" target="_blank">{{ scope.row.title }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="yfz"
        :label="$t('nodeDetail.activity.code.merge.originToTarget')"
        min-width="160">
          <template slot-scope="scope">
           <span>{{ scope.row.sourceBranch }} > {{ scope.row.targetBranch }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="stageList"
        :label="$t('nodeDetail.activity.code.merge.pipelineRes')"
        min-width="250"
        >
        <div slot-scope="scope" class="stageList_class">
          <span v-for="(item, index) in scope.row.stageList" :key="index">
              <el-tooltip
                  effect="dark"
                  :content="item.stage"
                  placement="bottom">
                <i class="iconfont" :class="'icon-gitlab_' + item.status"></i>
              </el-tooltip>
          </span>
        </div>
      </el-table-column>
      <el-table-column
        prop="pipelineVulnerabilityCount"
        :label="$t('nodeDetail.activity.code.merge.securityScan')">
        <template slot-scope="scope">
           <span v-if="scope.row.pipelineVulnerabilityCount <= 0 || !scope.row.pipelineVulnerabilityCount">{{ $t('nodeDetail.activity.history.null') }}</span>
           <span v-else>{{ scope.row.pipelineVulnerabilityCount }}{{ $t('nodeDetail.activity.code.commit.info') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        min-width="100"
        :label="$t('nodeDetail.activity.code.merge.operator')">
      </el-table-column>
      <el-table-column
        prop="createdAt"
        min-width="150"
        :label="$t('nodeDetail.activity.code.merge.opeTime')">
          <template slot-scope="scope">
           <span>{{ new Date(scope.row.createdAt).Format('yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="mergeStatus"
        min-width="150"
        :label="$t('nodeDetail.activity.code.merge.reviewResult')">
        <template slot-scope="scope">
           <span>{{ get_mergeStatus(scope.row.approvalState.rules) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="authorName"
        min-width="200"
        show-overflow-tooltip
        :label="$t('nodeDetail.activity.code.merge.reviewMember')">
          <template slot-scope="scope">
           <span v-if="get_authorName(scope.row.approvalState.rules) === $t('nodeDetail.activity.code.commit.info2')">{{ $t('nodeDetail.activity.code.commit.info2') }}</span>
           <div v-else>
                <el-tooltip
                  effect="dark"
                  :disabled="!get_authorName(scope.row.approvalState.rules).length"
                  :content="get_authorName(scope.row.approvalState.rules).join('，')"
                  placement="top"
                >
                <span style="color: rgb(0, 0, 238)">
                  {{ get_authorName(scope.row.approvalState.rules).length }}
                </span>
                </el-tooltip>
                /
                  <el-tooltip
                  effect="dark"
                  :disabled="!get_authorName2(scope.row.approvalState.rules).length"
                  :content="get_authorName2(scope.row.approvalState.rules).join('，')"
                  placement="top"
                >
                <span style="color: rgb(0, 0, 238)">
                  {{ get_authorName2(scope.row.approvalState.rules).length }}
                </span>
                </el-tooltip>
           </div>
        </template>
      </el-table-column>
    </el-table>
    </collapse>
  </div>
</template>

<script>
import Collapse from "./Collapse.vue";
import { gitlabProjects, getcommits, getmrs } from "@/network/gitlab";
export default {
  components: { Collapse },
  inject: ['nodeKey'],
  data() {
    return {
      loading: false,
      commit_tableData: [],
      mrs_tableData: [],
      repository_list: [],
    };
  },
  mounted() {
    this.getcommits();
    this.getmrs();
    this.gitlabProjects();
  },
  methods: {
    get_repository_name(id) {
      return this.repository_list.find((item) => {
        if(item.id === id) {
          return true
        }
      })
    },
    get_mergeStatus(rules) {
      let flag = this.$t('nodeDetail.activity.code.commit.info4');
      if(rules) {
        rules.forEach((item) => {
          if(!item.approved) {
            flag = this.$t('nodeDetail.activity.code.commit.info3');
          }
        })
      } else {
        flag = this.$t('nodeDetail.activity.code.commit.info2')
      }

      return flag
    },
    get_authorName(rules) {
      let arr = [];
      if(rules) {
        rules.forEach((item) => {
          item.approved_by.forEach((user) => {
            arr.push(user.username)
          })
        })
        arr = [...new Set(arr)];
      } else {
        arr = this.$t('nodeDetail.activity.code.commit.info2')
      }
      return arr
    },
    get_authorName2(rules) {
      let arr = [];
      if(rules) {
        rules.forEach((item) => {
          item.eligible_approvers.forEach((user) => {
            arr.push(user.username)
          })
        })
        arr = [...new Set(arr)];
      } else {
        arr = this.$t('nodeDetail.activity.code.commit.info2')
      }
      return arr
    },
    gitlabProjects() {
      // this.loading = true;
      const params = {
        projectId: this.get_pid()
      }
      gitlabProjects(params).then((res) => {
        this.repository_list = res;
        // this.loading = false;
      })
    },
    getcommits() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey
      }
      getcommits(params).then((res) => {
        this.commit_tableData = res;
      })
    },
    getmrs() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey
      }
      getmrs(params).then((res) => {
        this.mrs_tableData = res;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.gitlabcode_wrap{
  padding-bottom: 40px;
  ::v-deep {
    .el-table{
      border: 1px solid rgba(229, 229, 229, 1);
    }
  }
}
.stageList_class{
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  &>span {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    &:last-of-type{
      &::before{
        display: none;
      }
    }
    &::before{
      display: inline-block;
      content: "";
      position: absolute;
      width: 10px;
      height: 1px;
      background-color: rgba(204, 204, 204, 1);
      right: -10px;
      top: 50%;
    }
  }
}
</style>
