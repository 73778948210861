import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取V模型映射
export function getvmodelFileTypeRelation(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/vmodelFileTypeRelation`,
    method: "get",
    params: params.vmodelProcess,
  });
}
// 修改V模型映射
export function setvmodelFileTypeRelation(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/vmodelFileTypeRelation`,
    method: "put",
    data: params.data,
  });
}
// 获取指定节点在v模型中的关联分布
export function getVnode(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/vmodel/nodes/${params.nodeKey}`,
    method: "get"
  });
}