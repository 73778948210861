<template>
  <div
    v-loading="loading"
    class="gitlabcode_wrap"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <el-table :data="tableData" style="width: 95%">
      <el-table-column
        prop="repo"
        :label="$t('nodeDetail.activity.svn.repoName')"
        show-overflow-tooltip
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        prop="commitMessage"
        :label="$t('nodeDetail.activity.svn.commitMessage')"
        show-overflow-tooltip
        min-width="400  "
      >
      </el-table-column>
      <el-table-column
        prop="author"
        :label="$t('nodeDetail.activity.svn.author')"
        show-overflow-tooltip
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        prop="revision"
        :label="$t('nodeDetail.activity.svn.revision')"
        show-overflow-tooltip
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        prop="createdAt"
        :label="$t('nodeDetail.activity.svn.createdAt')"
        show-overflow-tooltip
        min-width="200"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { commits } from "@/network/svn";
import Collapse from "./Collapse.vue";
export default {
  components: { Collapse },
  inject: ["nodeKey"],
  data() {
    return {
      loading: false,
      tableData: [],
    };
  },
  mounted() {
    this.getcommits();
  },
  methods: {
    getcommits() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
      };
      commits(params).then((res) => {
        this.tableData = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
