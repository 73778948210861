<template>
  <div class="third_web_component" id="third_web_component">
    <el-tabs v-model="activeName" :before-leave="beforeLeave">
      <el-tab-pane :name="String(ind)" v-for="(tabs, ind) in data" :key="ind">
        <el-input
          ref="third_web_component_edit_input"
          id="third_web_component_edit_input"
          slot="label"
          v-if="edit_tabs_name == String(ind)"
          v-model="input_tab"
          clearable
          :placeholder="$t('nodeDetail.third.input_tab')"
        ></el-input>

        <div
          v-else
          slot="label"
          @dblclick="edit_tabs"
          @mouseover="mouse_in"
          @mouseleave="mouse_out"
          :id="'tabs_id&' + ind"
        >
          {{ tabs.name
          }}<i
            :id="'tabs_id&' + ind"
            class="el-icon-close"
            style="margin-left: 10px"
            @click="delete_tab"
            v-show="show_delete == String(ind) && activeName == String(ind)"
          ></i>
        </div>
        <div style="overflow: auto; width: 100%">
          <el-carousel
            indicator-position="outside"
            :autoplay="false"
            v-if="tab_data && tab_data[ind] && tab_data[ind].link.length > 0"
          >
            <el-carousel-item
              v-for="(item, index) in tab_data[ind].link"
              :key="index"
              style="border: 1px solid #cfcfcf; border-radius: 8px"
            >
              <div class="header">
                <el-tooltip
                  style="margin-left: 10px"
                  effect="dark"
                  :content="$t('nodeDetail.third.open')"
                  placement="top-start"
                >
                  <i
                    @click="open_target(item.raw)"
                    style="cursor: pointer; font-size: 20px"
                    class="iconfont icon-a-yonghuzhinan2"
                  ></i>
                </el-tooltip>

                <i
                  @click="del_target(item.raw)"
                  class="el-icon-delete"
                  style="cursor: pointer; font-size: 20px"
                ></i>
              </div>

              <iframe
                v-if="item.flag"
                style="min-width: 1000px; height: 500px; width: 100%"
                :src="item.url"
                frameborder="0"
              ></iframe>
              <div
                v-else
                v-html="item.url"
                style="
                  min-width: 1000px;
                  height: 500px;
                  width: 100%;
                  overflow: auto;
                "
              ></div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-tab-pane>

      <el-tab-pane key="add" name="add">
        <span slot="label" style="font-size: 20px; font-weight: bold"
          ><i class="iconfont icon-add"></i
        ></span>
      </el-tab-pane>
    </el-tabs>

    <div class="add_relation" v-show="data.length > 0">
      <el-form
        v-limits-of-authority="'NODE_EDIT'"
        :inline="true"
        class="demo-form-inline"
        size="medium"
      >
        <el-form-item :label="$t('nodeDetail.third.add')">
          <el-input
            v-model="input"
            :placeholder="$t('nodeDetail.third.input')"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="add_relation(false)">{{
            $t("btn.confirmBtn")
          }}</el-button>
        </el-form-item>
        <el-form-item :label="$t('nodeDetail.third.or')">
          <div style="display: flex; align-items: center">
            <el-upload
              class="upload-demo"
              action="#"
              accept=".html, .htm, .txt, .png"
              :http-request="upload_attachment_handler"
              :show-file-list="false"
              :before-upload="judge_howBig"
            >
              <el-button size="mini" type="primary">{{
                $t("btn.uploadBtn")
              }}</el-button>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="delete_delog_show"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i
      ><span>&nbsp;{{ $t("nodeDetail.third.del") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="Delete_node">{{
          $t("nodeDetail.third.del_btn")
        }}</el-button>
        <el-button @click="delete_delog_show = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { only_set_property } from "@/network/node/index.js";
import config from "@/../config/index";
import { ossAuthorization } from "@/utils/oss";
import { ulid } from "ulid";
import axios from "axios";
export default {
  components: {},
  props: {
    node_key: {
      type: String,
      default: "",
    },
    nodeInfo: {
      type: Object,
    },
    fileTypeId: {
      type: String,
    },
  },
  data() {
    return {
      delete_delog_show: false,
      input_tab: "",
      edit_tabs_name: "",
      edit_tabs_id: "",
      show_delete: "",
      activeName: "0",
      input: "",
      tab_data: [],
      data: [],
      // data: [
      //   {
      //     name: "即时设计",
      //     link: [],
      //   },
      //   {
      //     name: "figma",
      //     link: [],
      //   },
      //   {
      //     name: "matlab",
      //     link: [],
      //   },
      // ],
    };
  },
  watch: {
    activeName() {
      this.get_item();
    },
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (
        e.target.id != "third_web_component_edit_input" &&
        this.edit_tabs_name.length > 0
      ) {
        if (this.input_tab.length > 0) {
          this.data[Number(this.edit_tabs_name)].name = this.input_tab;
          const params = {
            projectId: this.get_pid(),
            nodeKey: this.node_key,
            data: {
              isCustom: false,
              fieldId: "third_link",
              value: this.data,
            },
          };
          only_set_property(params).then(() => {
            this.get_item();
            this.$message({
              type: "success",
              message: this.$t("nodeDetail.third.edsucc"),
            });
          });
        }
        this.edit_tabs_name = "";
        this.input_tab = "";
      }
    });
    // if (!this.nodeInfo.thirdLink) {
    //   const params = {
    //     projectId: this.get_pid(),
    //     nodeKey: this.node_key,
    //     data: {
    //       isCustom: false,
    //       fieldId: "third_link",
    //       value: this.data,
    //     },
    //   };
    //   only_set_property(params);
    // } else {
    //   this.data = this.nodeInfo.thirdLink;
    // }
    this.data = this.nodeInfo.thirdLink ? this.nodeInfo.thirdLink : [];
    this.get_item();
    this.$emit("hideLoading", false);
  },
  methods: {
    beforeLeave(currentName, oldName) {
      if (currentName == "add") {
        this.add_tabs();
        return false;
      }
    },
    add_tabs() {
      this.data.push({
        name: "Tab" + this.data.length,
        link: [],
      });
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        data: {
          isCustom: false,
          fieldId: "third_link",
          value: this.data,
        },
      };
      only_set_property(params).then(() => {
        this.$message({
          type: "success",
          message: this.$t("nodeDetail.third.addsucc"),
        });
      });
    },
    Delete_node() {
      this.data.splice(Number(this.activeName), 1);
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        data: {
          isCustom: false,
          fieldId: "third_link",
          value: this.data,
        },
      };
      only_set_property(params).then(() => {
        this.get_item();
        this.$message({
          type: "success",
          message: this.$t("nodeDetail.third.delsucc"),
        });
      });
      this.delete_delog_show = false;
      this.activeName = "0";
    },
    mouse_out() {
      this.show_delete = "";
    },
    mouse_in(a) {
      this.show_delete = a.target.id.split("&")[1];
    },
    edit_tabs(a) {
      this.edit_tabs_name = a.target.id.split("&")[1];
      // edit_tabs_id
      this.input_tab = this.data[Number(this.activeName)].name;
      setTimeout(() => {
        document.getElementById("third_web_component_edit_input").focus();
      }, 100);
      // this.$nextTick(() => {
      // });
    },
    delete_tab(e) {
      this.delete_delog_show = true;
    },
    async upload_attachment_handler(params) {
      const self = this;
      let file = params.file;
      await ossAuthorization.call(self);
      self.client
        .put(`upload_third_html/${ulid()}${file.name}`, file)
        .then((ossres) => {
          this.add_relation(ossres.url);
        });
    },
    judge_howBig(file) {
      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          type: "error",
          message: this.$t("homeTopBar.newFileForm.limit"),
          duration: "5000",
        });
        return false;
      }
    },
    async fetchFileContent(url) {
      const response = await axios.get(url);
      return response.data;
    },
    open_target(item) {
      let l = item;
      window.open((l.slice(0, 4) == "http" ? "" : "//") + l, "_blank");
    },
    del_target(item) {
      for (let i in this.data) {
        if (i + "" == this.activeName) {
          let index = this.data[i].link.indexOf(item);
          if (index > -1) {
            this.data[i].link.splice(index, 1);
          }
        }
      }
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        data: {
          isCustom: false,
          fieldId: "third_link",
          value: this.data,
        },
      };
      only_set_property(params).then(() => {
        this.get_item();
        this.$message({
          type: "success",
          message: this.$t("nodeDetail.third.delsucc"),
        });
      });
    },
    judge_url(url) {
      if (url.includes("//")) {
        let parts = url.split("//")[1];
        let domainPrefix = parts.split("/")[0];
        if (domainPrefix.includes("oss")) {
          return true;
        }
      } else {
        let parts = url.split("//")[0];
        let domainPrefix = parts.split("/")[0];
        if (domainPrefix.includes("oss")) {
          return true;
        }
      }
      return false;
    },
    async get_item() {
      this.tab_data = [];
      for (let i of this.data) {
        let res = [];

        for (let j of i.link) {
          if (typeof j === "string") {
            if (this.judge_url(j)) {
              res.push({
                url: await this.fetchFileContent(j),
                flag: false,
                raw: j,
              });
            } else {
              res.push({ url: j, flag: true, raw: j });
            }
          } else {
            res.push(j);
          }
        }
        this.tab_data.push({ name: i.name, link: res });
      }
    },
    add_relation(flag) {
      if (this.input.length < 1 && !flag) {
        this.$message({
          type: "warning",
          message: this.$t("nodeDetail.third.input"),
        });
        return;
      }
      for (let i in this.data) {
        if (i + "" == this.activeName) {
          this.data[i].link = [flag ? flag : this.input, ...this.data[i].link];
        }
      }
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        data: {
          isCustom: false,
          fieldId: "third_link",
          value: this.data,
        },
      };
      only_set_property(params).then(() => {
        this.get_item();
        this.input = "";
        this.$message({
          type: "success",
          message: this.$t("nodeDetail.third.addsucc"),
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 5px 0;
  background: rgb(244, 244, 245);
  display: flex;
  align-items: center;
  justify-content: end;
  i {
    margin: 0 10px;
  }
}
::v-deep .el-carousel__container {
  height: 500px !important;
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
.third_web_component {
  padding: 0 20px;

  #relation_chart {
    height: 60vh;

    div {
      height: 100%;
    }
  }

  .add_relation {
    margin-top: 25px;
  }
}
</style>
