<template>
  <div>
    <a href="#" class="check-btn" @click="dialogTableVisible = true">{{
      $t("permission.node.view")
    }}</a>
    <el-dialog
      :title="$t('nodeDetail.activity.history.tp1')"
      :visible.sync="dialogTableVisible"
      :append-to-body="true"
    >
      <el-table
        :data="data"
        border
        row-key="id"
        class="filter"
        max-height="300"
        style="width: 100%; margin-bottom: 20px"
        header-row-class-name="table_header"
      >
        <!-- 编号 -->
        <el-table-column
          type="index"
          width="100"
          class-name="table_index"
          :label="$t('addNodeTestPlan.lab1')"
          align="center"
        >
        </el-table-column>

        <!-- 前置条件 -->
        <el-table-column
          prop="preConditions"
          :label="$t('addNodeTestPlan.lab2')"
          align="left"
          header-align="center"
        >
          <template slot-scope="scope">
            <span style="white-space: pre-wrap">{{
              scope.row.preConditions
            }}</span>
          </template>
        </el-table-column>

        <!-- 测试步骤 -->
        <el-table-column
          prop="testingProcedure"
          :label="$t('addNodeTestPlan.lab3')"
          align="left"
          header-align="center"
        >
          <template slot-scope="scope">
            <span style="white-space: pre-wrap">{{
              scope.row.testingProcedure
            }}</span>
          </template>
        </el-table-column>

        <!-- 预期结果 -->
        <el-table-column
          prop="expectedResult"
          :label="$t('addNodeTestPlan.lab4')"
          align="left"
          header-align="center"
        >
          <template slot-scope="scope">
            <span style="white-space: pre-wrap">{{
              scope.row.expectedResult
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 测试步骤信息弹窗
      dialogTableVisible: false,
      // 判断点击的是那一行的,通过操作时间判断
      isline: "",
      // 判断点击的是原数据还是更改后的数据
      Original_change: "",
    };
  },
};
</script>
<style lang="scss" scope>
.check-btn {
  &:link,
  &:visited {
    color: #555;
    font-weight: 700;
  }
  &:hover,
  &:active {
    color: #003b73;
  }
}
</style>
