import { render, staticRenderFns } from "./GitLabCode.vue?vue&type=template&id=c0dfbefa&scoped=true"
import script from "./GitLabCode.vue?vue&type=script&lang=js"
export * from "./GitLabCode.vue?vue&type=script&lang=js"
import style0 from "./GitLabCode.vue?vue&type=style&index=0&id=c0dfbefa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0dfbefa",
  null
  
)

export default component.exports