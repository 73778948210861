<template>
  <div
    v-loading="loading"
    class="jenkinscode_wrap"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <el-table :data="build_history_list" style="width: 95%">
      <el-table-column
        prop="title"
        :label="$t('nodeDetail.activity.jenkins.time')"
        show-overflow-tooltip
        min-width="200"
      >
        <template slot-scope="scope">
          <span>{{
            new Date(scope.row.timestamp).Format("yyyy-MM-dd hh:mm:ss")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        :label="$t('nodeDetail.activity.jenkins.job')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <a :href="scope.row.url" target="_blank">{{
            scope.row.fullDisplayName
          }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="stageList"
        :label="$t('nodeDetail.activity.jenkins.result')"
        min-width="120"
      >
        <template slot-scope="scope">
          <img class="result_icon" :src="icon_list[scope.row.result]" />
          <span>{{ scope.row.result }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="pipelineVulnerabilityCount"
        :label="$t('nodeDetail.activity.jenkins.during')"
      >
        <template slot-scope="scope">
          <span>{{ getTime(scope.row.duration) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        :label="$t('nodeDetail.activity.jenkins.code')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-button type="text" @click="show_code_history(scope.row)">
            {{ $t("nodeDetail.activity.jenkins.codeBtn") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="code_history_dialog"
      width="80%"
    >
      <el-table :data="code_history_list" style="width: 95%">
        <el-table-column
          prop="title"
          :label="$t('nodeDetail.activity.jenkins.dialog.email')"
          show-overflow-tooltip
          min-width="150"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.authorEmail }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="stageList" min-width="180">
          <label slot="label">commit&nbsp;id</label>
          <template slot-scope="scope">
            <span>{{ scope.row.commitId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="pipelineVulnerabilityCount"
          :label="$t('nodeDetail.activity.jenkins.dialog.summary')"
          min-width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.comment }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          :label="$t('nodeDetail.activity.jenkins.dialog.time')"
          show-overflow-tooltip
          min-width="180"
        >
          <template slot-scope="scope">
            <span>
              {{ new Date(scope.row.timestamp).Format("yyyy-MM-dd hh:mm:ss") }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getBuildHistory } from "@/network/jenkins/index";
import jenkinsSuccess from "@/assets/img/jenkins-success.png";
import jenkinsUnstable from "@/assets/img/jenkins-unstable.png";
import jenkinsFaileds from "@/assets/img/jenkins-failed.png";
import jenkinsNobuilt from "@/assets/img/jenkins-nobuilt.png";
import jenkinsAbort from "@/assets/img/jenkins-abort.png";
export default {
  inject: ["nodeKey"],
  data() {
    return {
      loading: false,
      build_history_list: [],
      code_history_list: [],
      code_history_dialog: false,
      icon_list: {
        SUCCESS: jenkinsSuccess,
        UNSTABLE: jenkinsUnstable,
        FAILURE: jenkinsFaileds,
        NOT_BUILT: jenkinsNobuilt,
        ABORTED: jenkinsAbort,
      },
    };
  },
  mounted() {
    this.get_build_history();
  },
  methods: {
    get_build_history() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
      };
      getBuildHistory(params)
        .then((res) => {
          this.build_history_list = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTime(mytime) {
      let my_time = parseInt(mytime);
      let days = my_time / 1000 / 60 / 60 / 24;
      let daysRound = Math.floor(days);
      let hours = my_time / 1000 / 60 / 60 - 24 * daysRound;
      let hoursRound = Math.floor(hours);
      let minutes = my_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
      let minutesRound = Math.floor(minutes);
      let seconds = parseFloat(
        my_time / 1000 -
          24 * 60 * 60 * daysRound -
          60 * 60 * hoursRound -
          60 * minutesRound
      );
      seconds = Math.floor(seconds * 10) / 10;
      let time = "";
      if (daysRound) {
        time =
          daysRound +
          this.$t("chart.message.ms17") +
          hoursRound +
          this.$t("chart.message.ms18") +
          minutesRound +
          this.$t("chart.message.ms19");
      } else if (!daysRound && hoursRound) {
        time =
          hoursRound +
          this.$t("chart.message.ms18") +
          minutesRound +
          this.$t("chart.message.ms19");
      } else if (!daysRound && !hoursRound && minutesRound) {
        time = minutesRound + this.$t("chart.message.ms19");
        if (seconds) {
          time += Math.floor(seconds) + this.$t("chart.message.ms20");
        }
      } else if (!daysRound && !hoursRound && !minutesRound && seconds) {
        time = seconds + this.$t("chart.message.ms20");
      }
      return time;
    },
    show_code_history(row) {
      this.code_history_list = row.realChangeSet;
      this.code_history_dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.jenkinscode_wrap {
  padding-bottom: 40px;
  ::v-deep {
    .el-table {
      border: 1px solid rgba(229, 229, 229, 1);
    }
  }
}
.result_icon {
  width: 30px;
  height: 30px;
  position: relative;
  transform: translateY(25%);
  margin-right: 5px;
}
</style>
