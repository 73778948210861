<template>
  <div v-loading="loading" class="canvas-view-wrap">
    <el-alert
      class="tip"
      show-icon
      :closable="false"
      :title="$t('nodeDetail.relationChart.tip2')"
      type="info"
      style="margin-left: -40px"
    >
    </el-alert>
    <div style="width: 1400px; position: relative">
      <canvas
        ref="canvas-view"
        class="canvas-view"
        :style="{ cursor: cursor }"
        :height="height"
        @mousemove="canvas_move"
        @click="canvas_click"
        @dblclick="db_click"
      >
      </canvas>
      <div
        v-for="(item, index) in rect_array"
        :ref="'select_' + index"
        :key="index"
        class="select_style"
      >
        <div>{{ match_filetype(select_value[index]) }}</div>
      </div>
      <div
        v-if="now_topic_show"
        ref="now_topic"
        class="now_topic"
        :style="{ top: topic_top + 'px', left: topic_left + 'px' }"
      >
        {{ now_topic }}
      </div>
    </div>
  </div>
</template>

<script>
import { get_filetype_List, searchByKeys } from "@/network/fileType/index.js";
import { getwmodelFileTypeRelation, getWnode } from "@/network/wmodel/index.js";
let time = null;
export default {
  props: {
    relation_data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      nodesNumber: 0,
      jump_list: [
        [1],
        [0, 2, 3],
        [1, 4],
        [1, 4],
        [2, 3],
        [6, 8],
        [5, 7, 9],
        [6, 10],
        [5, 9],
        [6, 8, 10],
        [7, 9],
        [12, 14],
        [11, 13, 15],
        [12, 16],
        [11, 15],
        [12, 14, 16],
        [13, 15],
      ],
      // 新增两条线的关联逻辑
      other_jump_list: {
        1: [5, 11],
        2: [5, 6, 11, 12],
        5: [1, 2, 7],
        6: [2],
        7: [5],
        11: [1, 2, 13],
        12: [2],
        13: [11],
      },
      position_map: {
        SWE1: 5,
        SWE2: 6,
        SWE3: 7,
        SWE4: 10,
        SWE5: 9,
        SWE6: 8,
        SYS1: 0,
        SYS2: 1,
        SYS3: 2,
        SYS4: 4,
        SYS5: 3,
        HWE1: 11,
        HWE2: 12,
        HWE2_1: 13,
        HWE3: 15,
        HWE4: 14,
        HWE2_2: 16,
      }, //位置对应关系
      timeout_list: [], //定时器数组
      other_timeout_list: [], //其他定时器数组
      height: 1400,
      cvs: null,
      ctx: null,
      select_value: [],
      select_options_default: [],
      all_node_info: [],
      rect_array: [
        {
          left: 900,
          top: 50,
          width: 250,
          height: 150,
          location: "left",
          name: "SYS1",
        },
        {
          left: 920,
          top: 250,
          width: 250,
          height: 150,
          location: "left",
          name: "SYS2",
        },
        {
          left: 940,
          top: 450,
          width: 250,
          height: 150,
          location: "left",
          name: "SYS3",
        },
        {
          right: 1000,
          top: 250,
          width: 250,
          height: 150,
          location: "right",
          name: "SYS5",
        },
        {
          right: 1020,
          top: 450,
          width: 250,
          height: 150,
          location: "right",
          name: "SYS4",
        },
        {
          left: 200,
          top: 700,
          width: 250,
          height: 150,
          location: "left",
          name: "SWE1",
        },
        {
          left: 220,
          top: 900,
          width: 250,
          height: 150,
          location: "left",
          name: "SWE2",
        },
        {
          left: 240,
          top: 1100,
          width: 250,
          height: 150,
          location: "left",
          name: "SWE3",
        },
        {
          right: 1700,
          top: 700,
          width: 250,
          height: 150,
          location: "right",
          name: "SWE6",
        },
        {
          right: 1720,
          top: 900,
          width: 250,
          height: 150,
          location: "right",
          name: "SWE5",
        },
        {
          right: 1740,
          top: 1100,
          width: 250,
          height: 150,
          location: "right",
          name: "SWE4",
        },
        {
          left: 1600,
          top: 700,
          width: 250,
          height: 150,
          location: "left",
          name: "HWE1",
        },
        {
          left: 1620,
          top: 900,
          width: 250,
          height: 150,
          location: "left",
          name: "HWE2",
        },
        {
          left: 1640,
          top: 1100,
          width: 250,
          height: 150,
          location: "left",
          name: "HWE2_1",
        },
        {
          right: 280,
          top: 500,
          width: 250,
          height: 150,
          location: "right",
          name: "HWE4",
        },
        {
          right: 300,
          top: 700,
          width: 250,
          height: 150,
          location: "right",
          name: "HWE3",
        },
        {
          right: 320,
          top: 900,
          width: 250,
          height: 150,
          location: "right",
          name: "HWE2_2",
        },
      ],
      already_jump: [], //记录已经跳转过的线框
      already_clicked_node: [], //记录已经跳转过的线框
      all_step_target: [], //记录所有路线
      all_step_own: [], //记录有所起点
      other_step_target: [], //记录新增两条线的记录路线
      other_step_own: [], //新增两条线的记录起点
      other_already_jump: [],
      final_rect: [],
      topic_list: [],
      now_topic: "",
      now_topic_show: false,
      topic_top: 0,
      topic_left: 0,
      cursor: "default",
      loading: false,
    };
  },
  computed: {
    current_node() {
      return this.relation_data.filter((node) => {
        return node.current;
      });
    },
    select_options() {
      return this.select_options_default;
    },
  },
  async mounted() {
    this.loading = true;
    await this.get_all_node_filetype();
    await this.get_all_file_type();
    this.matching_node();
    this.build_rect_array();
    this.loading = false;
  },
  methods: {
    // 获取所有节点的filetype
    async get_all_node_filetype() {
      const params = {
        projectId: this.get_pid(),
        data: [
          this.relation_data.filter((item) => {
            return item.nodeKey && item.current;
          })[0].nodeKey,
        ],
      };
      this.all_node_info = await searchByKeys(params);
    },
    // 获取所有文件类型
    async get_all_file_type() {
      const res = await get_filetype_List(this.get_pid());
      this.select_options_default = [];
      res.forEach((item) => {
        const obj = {
          label: item.name,
          value: item.fileTypeId,
        };
        this.select_options_default.push(obj);
      });
      // 获取每个位置的文件类型
      const params1 = {
        projectId: this.get_pid(),
        wmodelProcess: [
          "SYS1",
          "SYS2",
          "SYS3",
          "SYS4",
          "SYS5",
          "SWE1",
          "SWE2",
          "SWE3",
          "SWE4",
          "SWE5",
          "SWE6",
          "HWE1",
          "HWE2",
          "HWE2_1",
          "HWE3",
          "HWE4",
          "HWE2_2",
        ],
      };
      const res1 = await getwmodelFileTypeRelation(params1);
      res1.forEach((item) => {
        this.select_value[this.position_map[item.wmodelProcess]] =
          item.fileTypeId;
      });
    },
    match_filetype(id) {
      const arr = this.select_options_default.filter((item) => {
        return item.value === id;
      });
      let name = "";
      arr && arr.length ? (name = arr[0].label) : "";
      return name;
    },
    matching_node() {
      this.rect_array.forEach((rect) => {
        rect.nodeList = [];
      });
      this.all_node_info.forEach((item) => {
        if (this.select_value.indexOf(item.fileTypeId) !== -1) {
          this.rect_array[this.select_value.indexOf(item.fileTypeId)].nodeList
            ? ""
            : (this.rect_array[
                this.select_value.indexOf(item.fileTypeId)
              ].nodeList = []);
          this.rect_array[
            this.select_value.indexOf(item.fileTypeId)
          ].nodeList.push(item);
        }
      });
    },
    // 新建需要画布构建的数组
    build_rect_array(res, clickRect) {
      //动态计算每个盒子的宽高
      const arr1 = [];
      // 检测窗口宽度
      const windowWidth = window.innerWidth;
      // 设置每行的节点数量
      // let nodesNumber = 0;
      if (windowWidth >= 1600) {
        this.nodesNumber = 1;
      } else if (windowWidth >= 1400 && windowWidth < 1600) {
        this.nodesNumber = 1;
      } else if (windowWidth < 1400) {
        this.nodesNumber = 1;
      }
      // 对应一行的关系
      const map = [
        false,
        3,
        4,
        false,
        false,
        8,
        9,
        10,
        false,
        false,
        false,
        14,
        15,
        16,
        false,
        false,
        false,
      ];
      // 检测窗口宽度
      for (let i = 0; i < 17; i++) {
        let obj;
        if ([0, 1, 2, 5, 6, 7, 11, 12, 13].indexOf(i) !== -1) {
          const length = this.rect_array[i].nodeList
            ? this.rect_array[i].nodeList.length
            : 0;
          const width_number =
            length >= this.nodesNumber ? this.nodesNumber : length;
          const height_number =
            Math.ceil(length / this.nodesNumber) > 0
              ? Math.ceil(length / this.nodesNumber) - 1
              : 0;
          let top;
          if (i == 5 || i == 11) {
            top = arr1[2].top + Math.max(arr1[2].height, arr1[4].height) + 100;
          } else {
            top = i === 0 ? 50 : arr1[i - 1].top + arr1[i - 1].height + 50;
          }
          obj = {
            left: this.rect_array[i].left,
            top: top,
            width: this.nodesNumber * 100 + width_number * 150,
            height: 150 + height_number * 150,
            location: "left",
            nodeList: this.rect_array[i].nodeList,
            name: this.rect_array[i].name,
          };
        } else {
          const length = this.rect_array[i].nodeList
            ? this.rect_array[i].nodeList.length
            : 0;
          const width_number =
            length >= this.nodesNumber ? this.nodesNumber : length;
          const height_number =
            Math.ceil(length / this.nodesNumber) > 0
              ? Math.ceil(length / this.nodesNumber) - 1
              : 0;
          let top;
          if (i == 8 || i == 14) {
            top = arr1[4].top + Math.max(arr1[2].height, arr1[4].height) + 100;
          } else if (i == 4) {
            top =
              arr1[i - 1].top +
              Math.max(arr1[i - 3].height, arr1[i - 1].height) +
              50;
          } else if (i == 9 || i == 10 || i == 15 || i == 16) {
            top =
              arr1[i - 1].top +
              Math.max(arr1[i - 4].height, arr1[i - 1].height) +
              50;
          } else {
            top = i === 3 ? 250 : arr1[i - 1].top + arr1[i - 1].height + 50;
          }
          obj = {
            right: this.rect_array[i].right,
            top: top,
            width: this.nodesNumber * 100 + width_number * 150,
            height: 150 + height_number * 150,
            location: "right",
            nodeList: this.rect_array[i].nodeList,
            name: this.rect_array[i].name,
          };
        }
        arr1.push(obj);
      }
      arr1.forEach((item, index) => {
        if (index == 6 || index == 7 || index == 12 || index == 13) {
          item.top =
            arr1[index - 1].top +
            Math.max(arr1[index - 1].height, arr1[index + 2].height) +
            50;
        } else if (index == 2) {
          item.top =
            arr1[index - 1].top +
            Math.max(arr1[index - 1].height, arr1[index + 1].height) +
            50;
        } else if (index == 5 || index == 11) {
          item.top =
            arr1[2].top + Math.max(arr1[2].height, arr1[4].height) + 100;
        }
      });
      this.final_rect = arr1;
      this.final_rect.forEach((item, index) => {
        if (map[index]) {
          this.final_rect[index].top = Math.max(
            this.final_rect[index].top,
            this.final_rect[map[index]].top
          );
          this.final_rect[map[index]].top = Math.max(
            this.final_rect[index].top,
            this.final_rect[map[index]].top
          );
          this.final_rect[index].height = Math.max(
            this.final_rect[index].height,
            this.final_rect[map[index]].height
          );
          this.final_rect[map[index]].height = Math.max(
            this.final_rect[index].height,
            this.final_rect[map[index]].height
          );
        }
      });
      this.height =
        Math.max(
          this.final_rect[this.final_rect.length - 1].top,
          this.final_rect[10].top
        ) +
        Math.max(
          this.final_rect[this.final_rect.length - 1].height,
          this.final_rect[10].height
        ) +
        200;
      this.$nextTick(() => {
        this.init_canvas(res, clickRect);
      });
    },
    // 初始化画布
    init_canvas(res, clickRect) {
      this.cvs = this.$refs["canvas-view"]; // 获取元素
      this.ctx = this.cvs.getContext("2d"); // 创建上下文 context 对象 便于理解也可以取名叫 pen
      this.cvs.width = 2 * this.cvs.offsetWidth;
      this.cvs.height = 2 * this.cvs.offsetHeight;
      this.ctx.clearRect(0, 0, this.cvs.width, this.cvs.height);
      this.draw_rect(this.ctx, this.final_rect);
      this.draw_node(this.ctx, this.final_rect);
      this.draw_level_line(this.ctx, this.final_rect);
      this.draw_between_line(this.ctx, this.final_rect);

      setTimeout(() => {
        if (this.all_step_target.length) {
          this.direct_draw_line();
          this.animation_fn(
            this.final_rect[this.all_step_own[this.all_step_target.length - 1]],
            this.all_step_target[this.all_step_target.length - 1],
            res,
            clickRect
          );
        }
        if (this.other_step_target.length) {
          // this.other_draw_Line();
          this.other_animation_fn(
            this.final_rect[
              this.other_step_own[this.other_step_target.length - 1]
            ],
            this.other_step_target[this.other_step_target.length - 1],
            res,
            clickRect
          );
        }
      });
    },
    // ctx画笔 data节点数据
    // 画矩形
    draw_rect(ctx, data) {
      ctx.lineWidth = 2;
      ctx.setLineDash([10]);
      ctx.strokeStyle = "#A6A6A6";
      ctx.lineJoin = "round";
      data.forEach((item, index) => {
        let flag = false;
        item.nodeList.forEach((node) => {
          if (node.key === this.current_node[0].nodeKey) {
            flag = true;
          }
        });
        if (item.location === "left") {
          ctx.moveTo(item.left, item.top);
          ctx.strokeRect(item.left, item.top, item.width, item.height);
          if (flag) {
            ctx.fillStyle = "#F2F2F2";
            ctx.fillRect(
              item.left + 2,
              item.top + 2,
              item.width - 4,
              item.height - 4
            );
          }
          this.$refs["select_" + index][0].style.top =
            (item.top + item.height / 2 - 32) / 2 + "px";
          this.$refs["select_" + index][0].style.left =
            item.left / 2 - 100 + "px";
        } else {
          ctx.moveTo(this.cvs.width - item.width - item.right, item.top);
          ctx.strokeRect(
            this.cvs.width - item.width - item.right,
            item.top,
            item.width,
            item.height
          );
          if (flag) {
            ctx.fillStyle = "#F2F2F2";
            ctx.fillRect(
              this.cvs.width - item.width - item.right + 2,
              item.top + 2,
              item.width - 4,
              item.height - 4
            );
          }
          this.$refs["select_" + index][0].style.top =
            (item.top + item.height / 2 - 32) / 2 + "px";
          this.$refs["select_" + index][0].style.right =
            item.right / 2 - 140 + "px";
        }
      });
    },
    // 画节点
    draw_node(ctx, data) {
      this.topic_list = [];
      function circle(context, x, y, a, b, flag, topic) {
        // x,y是坐标;a是半径
        let step = a > b ? 1 / a : 1 / b;
        context.beginPath();
        context.fillStyle = flag ? "#30648F" : "#E2F1FF";
        context.moveTo(x + a, y);
        for (let i = 0; i < 2 * Math.PI; i += step) {
          context.lineTo(x + a * Math.cos(i), y + b * Math.sin(i));
        }
        context.closePath();
        context.fill();
      }
      function draw_text(context, x, y, a, b, flag, topic) {
        let temp = [];
        context.beginPath();
        context.fillStyle = flag ? "#fff" : "#30648F";
        context.font = '30px "微软雅黑"'; //设置字体
        context.textBaseline = "bottom"; //设置字体底线对齐绘制基线
        context.textAlign = "center"; //设置字体对齐的方式
        if (topic.length > 5 && topic.length <= 9) {
          temp = [topic.substring(0, 5), topic.substring(5, 9)];
          context.fillText(temp[0], x, y + b / 3 - 14, a * 2);
          context.fillText(temp[1], x, y + b / 3 + 20, a * 2);
        } else if (topic.length <= 5) {
          temp = [topic.substring(0, 5)];
          context.fillText(temp[0], x, y + b / 3, a * 2);
        } else {
          temp = [topic.substring(0, 5), topic.substring(5, 9)];
          context.fillText(temp[0], x, y + b / 3 - 14, a * 2);
          context.fillText(temp[1] + "...", x, y + b / 3 + 20, a * 2);
        }
        context.closePath();
      }
      data.forEach((rect) => {
        rect.nodeList.forEach((node, index) => {
          const num = index % this.nodesNumber;
          const layer = Math.floor(index / this.nodesNumber);
          let flag = false;
          if (node.key === this.current_node[0].nodeKey) {
            flag = true;
          }
          let x = 0;
          let y = 0;
          if (rect.location === "left") {
            x = rect.left + num * 250 + 120;
            y = rect.top + layer * 150 + 75;
            circle(ctx, x, y, 100, 50, flag, node.topic);
            draw_text(ctx, x, y, 100, 50, flag, node.topic);
          } else {
            x = this.cvs.width - rect.width - rect.right + num * 250 + 120;
            y = rect.top + layer * 150 + 75;
            circle(ctx, x, y, 100, 50, flag, node.topic);
            draw_text(ctx, x, y, 100, 50, flag, node.topic);
          }
          const obj = {
            x: x,
            y: y,
            name: rect.name,
            key: node.key,
            topic: node.topic,
          };
          this.topic_list.push(obj);
        });
      });
    },
    // 画纵向线
    draw_level_line(ctx, data) {
      ctx.beginPath();
      ctx.setLineDash([]);
      data.forEach((item, index) => {
        if (item.location === "left" && data[index + 1].location === "left") {
          ctx.moveTo(item.left + 50, item.top + item.height);
          ctx.lineTo(data[index + 1].left + 50, data[index + 1].top);
          ctx.stroke();
        } else if (item.location === "right" && data[index + 1]) {
          ctx.moveTo(this.cvs.width - item.right - 50, item.top + item.height);
          ctx.lineTo(
            this.cvs.width - data[index + 1].right - 50,
            data[index + 1].top
          );
          ctx.stroke();
        }
      });
      ctx.closePath();
    },
    // 画横向线
    draw_between_line(ctx, data) {
      ctx.beginPath();
      ctx.setLineDash([20]);
      ctx.strokeStyle = "#9AB9D6";
      data.forEach((item, index) => {
        if (index > 0 && index < 3) {
          ctx.moveTo(item.left + item.width, item.top + item.height / 2);
          ctx.lineTo(
            this.cvs.width - data[index + 2].right - data[index + 2].width,
            data[index + 2].top + data[index + 2].height / 2
          );
          ctx.stroke();
        }
        if (index > 4 && index < 8) {
          ctx.moveTo(item.left + item.width, item.top + item.height / 2);
          ctx.lineTo(
            this.cvs.width - data[index + 3].right - data[index + 3].width,
            data[index + 3].top + data[index + 3].height / 2
          );
          ctx.stroke();
        }
        if (index > 10 && index < 14) {
          ctx.moveTo(item.left + item.width, item.top + item.height / 2);
          ctx.lineTo(
            this.cvs.width - data[index + 3].right - data[index + 3].width,
            data[index + 3].top + data[index + 3].height / 2
          );
          ctx.stroke();
        }
      });
      ctx.closePath();
    },
    // 监听鼠标位置显示tooltip
    canvas_move(e) {
      let x = e.clientX;
      let y = e.clientY;
      let rect = this.$refs["canvas-view"].getBoundingClientRect();
      x -= rect.left;
      y -= rect.top;
      x = x * 2;
      y = y * 2;
      let flag = false;
      this.cursor = "auto";
      this.topic_list.forEach((item) => {
        if (Math.abs(item.x - x) <= 50 && Math.abs(item.y - y) <= 50) {
          this.cursor = "pointer";
        }
        if (
          Math.abs(item.x - x) <= 50 &&
          Math.abs(item.y - y) <= 50 &&
          item.topic.length > 9
        ) {
          flag = true;
          this.now_topic = item.topic;
          this.topic_left = e.offsetX;
          this.topic_top = e.offsetY + 60;
        }
      });
      this.now_topic_show = flag;
    },
    // 画布点击事件
    canvas_click(e) {
      clearTimeout(time);
      time = setTimeout(() => {
        let x = e.clientX;
        let y = e.clientY;
        let rect = this.$refs["canvas-view"].getBoundingClientRect();
        x -= rect.left;
        y -= rect.top;
        x = x * 2;
        y = y * 2;
        this.topic_list.forEach(async (item) => {
          if (Math.abs(item.x - x) <= 50 && Math.abs(item.y - y) <= 50) {
            if (this.already_clicked_node.indexOf(item.key) !== -1) {
              return;
            } else {
              this.already_clicked_node.push(item.key);
            }
            const params = {
              projectId: this.get_pid(),
              nodeKey: item.key,
            };
            this.loading = true;
            const res = await getWnode(params);
            const own = this.position_map[item.name];
            const clickRect = this.rect_array[this.position_map[item.name]];
            const target = [];
            this.jump_list[this.position_map[item.name]].forEach((number) => {
              if (this.already_jump.indexOf(number) === -1) {
                target.push(number);
                this.already_jump.push(this.position_map[item.name]);
                this.rect_array[number].nodeList.push(
                  ...(res[this.final_rect[number].name]
                    ? res[this.final_rect[number].name]
                    : [])
                );
              }
            });
            // 新增两条线的跳转逻辑
            if (this.other_jump_list[this.position_map[item.name]]) {
              this.other_already_jump.push(this.position_map[item.name]);
              const other_target = [];
              const other_own = this.position_map[item.name];
              this.other_jump_list[this.position_map[item.name]].forEach(
                (number) => {
                  if (this.other_already_jump.indexOf(number) === -1) {
                    other_target.push(number);
                    this.other_already_jump.push(this.position_map[item.name]);
                    this.rect_array[number].nodeList.push(
                      ...(res[this.final_rect[number].name]
                        ? res[this.final_rect[number].name]
                        : [])
                    );
                  }
                }
              );
              this.other_step_target.push(other_target);
              this.other_step_own.push(other_own);
            }
            this.all_step_target.push(target);
            this.all_step_own.push(own);
            this.loading = false;
            this.build_rect_array(res, clickRect);
          }
        });
      }, 300);
    },
    db_click(e) {
      clearTimeout(time);
      let x = e.clientX;
      let y = e.clientY;
      let rect = this.$refs["canvas-view"].getBoundingClientRect();
      x -= rect.left;
      y -= rect.top;
      x = x * 2;
      y = y * 2;
      this.topic_list.forEach(async (item) => {
        if (Math.abs(item.x - x) <= 50 && Math.abs(item.y - y) <= 50) {
          const { href } = this.$router.resolve({
            name: "node_detail",
            params: {
              projectId: this.get_pid(),
              nodeKey: item.key,
            },
          });
          //结合open打开新的页面
          window.open(href, "_blank");
        }
      });
    },
    matchEmpty(res, target, clickRect) {
      let flag = true;
      // 解决其他线条渲染时候会出现红色
      if (clickRect) {
        flag =
          this.other_jump_list[this.position_map[clickRect.name]] &&
          this.other_jump_list[this.position_map[clickRect.name]].indexOf(
            this.position_map[target.name]
          ) !== -1;
      }
      if (res[target.name].length || !flag) {
        return true;
      } else {
        return false;
      }
    },
    // 监听
    // 动效测试
    animation_fn(own, arr, res, clickRect) {
      this.timeout_list.forEach((item) => {
        clearInterval(item);
      });
      const target_list = []; //移动目标列表
      let start_item = own; //起点对象
      this.ctx.setLineDash([]); //设置实线
      this.ctx.lineWidth = 4; //设置线条宽度
      this.ctx.strokeStyle = "#30648f"; //设置线条颜色
      arr.forEach((item) => {
        target_list.push(this.final_rect[item]);
      });
      target_list.forEach((target, index) => {
        if (start_item.location === "left" && target.location === "right") {
          const start_left = start_item.left + start_item.width;
          const start_top = start_item.top + start_item.height / 2;
          const end_left = this.cvs.width - target.right - target.width;
          const end_top = target.top + target.height / 2;
          const step_align = (end_left - start_left) / 100;
          const step_vertical = (end_top - start_top) / 100;
          let every_left = start_left;
          let every_top = end_top;
          this.timeout_list[index] = setInterval(() => {
            let every_step_align = (every_left += step_align);
            const every_step_vertical = (every_top += step_vertical);
            // 画小箭头
            if (every_step_align >= end_left) {
              every_step_align -= step_align;
              this.ctx.beginPath();
              if (target.nodeList.length && this.matchEmpty(res, target)) {
                this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                this.ctx.fillStyle = "#30648f";
              } else {
                this.ctx.strokeStyle = "#f56c6c";
                this.ctx.fillStyle = "#f56c6c";
              }
              this.ctx.moveTo(every_step_align - 10, every_step_vertical + 10);
              this.ctx.lineTo(every_step_align, every_step_vertical);
              this.ctx.lineTo(every_step_align - 10, every_step_vertical - 10);
              this.ctx.stroke();
              this.ctx.closePath();
              clearInterval(this.timeout_list[index]);
            } else {
              this.ctx.beginPath();
              if (target.nodeList.length && this.matchEmpty(res, target)) {
                this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                this.ctx.fillStyle = "#30648f";
              } else {
                this.ctx.strokeStyle = "#f56c6c";
                this.ctx.fillStyle = "#f56c6c";
              }
              this.ctx.moveTo(start_left, start_top);
              this.ctx.lineTo(every_step_align, every_step_vertical);
              this.ctx.stroke();
              this.ctx.closePath();
            }
          }, 1);
        } else if (
          start_item.location === "left" &&
          target.location === "left"
        ) {
          let start_left, start_top, end_left, end_top;
          if (target.top > start_item.top) {
            start_left = start_item.left + 50;
            start_top = start_item.top + start_item.height;
            end_left = target.left + 50;
            end_top = target.top;
          } else {
            start_left = start_item.left + 50;
            start_top = start_item.top;
            end_left = target.left + 50;
            end_top = target.top + target.height;
          }
          const rate = (end_top - start_top) / (end_left - start_left); //根据斜率公式算出K
          const B = start_top - rate * start_left; //根据斜率公式算出Kb
          const step_align = (end_left - start_left) / 100;

          let every_left = start_left;
          this.timeout_list[index] = setInterval(() => {
            const every_step_align = (every_left += step_align);

            // 画小箭头
            if (target.top < start_item.top && every_step_align <= end_left) {
              this.ctx.beginPath();
              if (target.nodeList.length && this.matchEmpty(res, target)) {
                this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                this.ctx.fillStyle = "#30648f";
              } else {
                this.ctx.strokeStyle = "#f56c6c";
                this.ctx.fillStyle = "#f56c6c";
              }
              this.ctx.moveTo(
                every_step_align - 7,
                every_step_align * rate + B + 12
              );
              this.ctx.lineTo(every_step_align, every_step_align * rate + B);
              this.ctx.lineTo(
                every_step_align + 10,
                every_step_align * rate + B + 10
              );
              this.ctx.stroke();
              this.ctx.closePath();
              clearInterval(this.timeout_list[index]);
            } else if (
              target.top > start_item.top &&
              every_step_align >= end_left
            ) {
              this.ctx.beginPath();
              if (target.nodeList.length && this.matchEmpty(res, target)) {
                this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                this.ctx.fillStyle = "#30648f";
              } else {
                this.ctx.strokeStyle = "#f56c6c";
                this.ctx.fillStyle = "#f56c6c";
              }
              this.ctx.moveTo(
                every_step_align - 10,
                every_step_align * rate + B - 10
              );
              this.ctx.lineTo(every_step_align, every_step_align * rate + B);
              this.ctx.lineTo(
                every_step_align + 7,
                every_step_align * rate + B - 12
              );
              this.ctx.stroke();
              this.ctx.closePath();
              clearInterval(this.timeout_list[index]);
            } else {
              this.ctx.beginPath();
              if (target.nodeList.length && this.matchEmpty(res, target)) {
                this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                this.ctx.fillStyle = "#30648f";
              } else {
                this.ctx.strokeStyle = "#f56c6c";
                this.ctx.fillStyle = "#f56c6c";
              }
              this.ctx.moveTo(start_left, start_top);
              this.ctx.lineTo(every_step_align, every_step_align * rate + B);
              this.ctx.stroke();
              this.ctx.closePath();
            }
          }, 1);
        } else if (
          start_item.location === "right" &&
          target.location === "left"
        ) {
          const start_left =
            this.cvs.width - start_item.right - start_item.width;
          const start_top = start_item.top + start_item.height / 2;
          const end_left = target.left + target.width;
          const end_top = target.top + target.height / 2;
          const step_align = (start_left - end_left) / 100;
          const step_vertical = (end_top - start_top) / 100;
          let every_left = start_left;
          let every_top = end_top;
          this.timeout_list[index] = setInterval(() => {
            let every_step_align = (every_left -= step_align);
            const every_step_vertical = (every_top += step_vertical);
            // 画小箭头
            if (every_step_align <= end_left) {
              every_step_align += step_align;
              this.ctx.beginPath();
              if (target.nodeList.length && this.matchEmpty(res, target)) {
                this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                this.ctx.fillStyle = "#30648f";
              } else {
                this.ctx.strokeStyle = "#f56c6c";
                this.ctx.fillStyle = "#f56c6c";
              }
              this.ctx.moveTo(every_step_align + 10, every_step_vertical + 10);
              this.ctx.lineTo(every_step_align, every_step_vertical);
              this.ctx.lineTo(every_step_align + 10, every_step_vertical - 10);
              this.ctx.stroke();
              this.ctx.closePath();
              clearInterval(this.timeout_list[index]);
            } else {
              this.ctx.beginPath();
              if (target.nodeList.length && this.matchEmpty(res, target)) {
                this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                this.ctx.fillStyle = "#30648f";
              } else {
                this.ctx.strokeStyle = "#f56c6c";
                this.ctx.fillStyle = "#f56c6c";
              }
              this.ctx.moveTo(start_left, start_top);
              this.ctx.lineTo(every_step_align, every_step_vertical);
              this.ctx.stroke();
              this.ctx.closePath();
            }
          }, 1);
        }
      });
    },
    // 贝塞尔曲线
    twoBezier(t, p1, cp, p2) {
      const [x1, y1] = p1;
      const [cx, cy] = cp;
      const [x2, y2] = p2;
      let x = (1 - t) * (1 - t) * x1 + 2 * t * (1 - t) * cx + t * t * x2;
      let y = (1 - t) * (1 - t) * y1 + 2 * t * (1 - t) * cy + t * t * y2;
      return [x, y];
    },
    threeBezier(t, p1, cp1, cp2, p2) {
      const [x1, y1] = p1;
      const [cx1, cy1] = cp1;
      const [cx2, cy2] = cp2;
      const [x2, y2] = p2;
      let x =
        (1 - t) * (1 - t) * (1 - t) * x1 +
        3 * (1 - t) * (1 - t) * t * cx1 +
        3 * (1 - t) * t * t * cx2 +
        t * t * t * x2;
      let y =
        (1 - t) * (1 - t) * (1 - t) * y1 +
        3 * (1 - t) * (1 - t) * t * cy1 +
        3 * (1 - t) * t * t * cy2 +
        t * t * t * y2;
      return [x, y];
    },
    // 其他线的动效
    other_animation_fn(own, arr, res, clickRect) {
      this.other_timeout_list.forEach((item) => {
        clearInterval(item);
      });
      const target_list = []; //移动目标列表
      let start_item = own; //起点对象
      this.ctx.setLineDash([]); //设置实线
      this.ctx.lineWidth = 4; //设置线条宽度
      this.ctx.strokeStyle = "#30648f"; //设置线条颜色
      arr.forEach((item) => {
        target_list.push(this.final_rect[item]);
      });
      target_list.forEach((target, index) => {
        let start_left, start_top, end_left, end_top;
        let flag = false;
        let cp = [];
        let cp2 = [];
        start_top = start_item.top + start_item.height / 2;
        end_top = target.top + target.height / 2;
        if (
          (start_item.name === "SYS2" && target.name === "SWE1") ||
          (start_item.name === "SYS3" && target.name === "SWE1") ||
          (start_item.name === "SYS3" && target.name === "SWE2") ||
          (start_item.name === "SWE1" && target.name === "SYS2") ||
          (start_item.name === "SWE1" && target.name === "SYS3") ||
          (start_item.name === "SWE2" && target.name === "SYS3") ||
          (start_item.name === "SYS2" && target.name === "HWE1") ||
          (start_item.name === "SYS3" && target.name === "HWE1") ||
          (start_item.name === "SYS3" && target.name === "HWE2") ||
          (start_item.name === "HWE1" && target.name === "SYS2") ||
          (start_item.name === "HWE1" && target.name === "SYS3") ||
          (start_item.name === "HWE2" && target.name === "SYS3")
        ) {
          start_left =
            start_item.left > target.left
              ? start_item.left
              : start_item.left + start_item.width;
          end_left =
            start_item.left > target.left
              ? target.left + target.width
              : target.left;
          // 确定控制点的位置
          if (start_item.left > target.left) {
            cp = [
              target.left + (start_item.left - target.left) / 2,
              start_item.top + start_item.height / 2,
            ];
            cp2 = [
              target.left + (start_item.left - target.left) / 2,
              target.top + target.height / 2,
            ];
          } else {
            cp = [
              start_item.left +
                start_item.width +
                (target.left - (start_item.left + start_item.width)) / 2,
              start_item.top + start_item.height / 2,
            ];
            cp2 = [
              start_item.left +
                start_item.width +
                (target.left - (start_item.left + start_item.width)) / 2,
              target.top + target.height / 2,
            ];
          }
          flag = true;
        } else {
          start_left = start_item.left;
          end_left = target.left;
          // 确定控制点的位置
          if (start_item.top > target.top) {
            cp = [target.left - 200, (start_top - end_top) / 2 + end_top];
          } else {
            cp = [start_item.left - 200, (end_top - start_top) / 2 + start_top];
          }
          flag = false;
        }
        let step = 0;
        this.other_timeout_list[index] = setInterval(() => {
          let x, y, ex, ey;
          if (flag) {
            [x, y] = this.threeBezier(step, [start_left, start_top], cp, cp2, [
              end_left,
              end_top,
            ]);
            step += 0.05;
            [ex, ey] = this.threeBezier(
              step,
              [start_left, start_top],
              cp,
              cp2,
              [end_left, end_top]
            );
          } else {
            [x, y] = this.twoBezier(step, [start_left, start_top], cp, [
              end_left,
              end_top,
            ]);
            step += 0.05;
            [ex, ey] = this.twoBezier(step, [start_left, start_top], cp, [
              end_left,
              end_top,
            ]);
          }
          // 画小箭头
          if (step > 1.05) {
            // 不同落点小箭头位置不同
            // 确定控制点的位置
            if (flag) {
              if (start_item.left > target.left) {
                this.ctx.beginPath();
                if (target.nodeList.length && this.matchEmpty(res, target)) {
                  this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                  this.ctx.fillStyle = "#30648f";
                } else {
                  this.ctx.strokeStyle = "#f56c6c";
                  this.ctx.fillStyle = "#f56c6c";
                }
                this.ctx.moveTo(end_left + 10, end_top + 10);
                this.ctx.lineTo(end_left, end_top);
                this.ctx.lineTo(end_left + 10, end_top - 10);
                this.ctx.stroke();
                this.ctx.fill();
                this.ctx.closePath();
              } else {
                this.ctx.beginPath();
                if (target.nodeList.length && this.matchEmpty(res, target)) {
                  this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                  this.ctx.fillStyle = "#30648f";
                } else {
                  this.ctx.strokeStyle = "#f56c6c";
                  this.ctx.fillStyle = "#f56c6c";
                }
                this.ctx.moveTo(end_left - 10, end_top + 10);
                this.ctx.lineTo(end_left, end_top);
                this.ctx.lineTo(end_left - 10, end_top - 10);
                this.ctx.stroke();
                this.ctx.fill();
                this.ctx.closePath();
              }
            } else {
              this.ctx.beginPath();
              if (start_item.top > target.top) {
                if (target.nodeList.length && this.matchEmpty(res, target)) {
                  this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                  this.ctx.fillStyle = "#30648f";
                } else {
                  this.ctx.strokeStyle = "#f56c6c";
                  this.ctx.fillStyle = "#f56c6c";
                }
                this.ctx.moveTo(end_left - 10, end_top + 15);
                this.ctx.lineTo(end_left, end_top + 5);
                this.ctx.lineTo(end_left - 10, end_top - 5);
                this.ctx.stroke();
                this.ctx.fill();
                this.ctx.closePath();
              } else {
                this.ctx.beginPath();
                if (target.nodeList.length && this.matchEmpty(res, target)) {
                  this.ctx.strokeStyle = "#30648f"; //设置线条颜色
                  this.ctx.fillStyle = "#30648f";
                } else {
                  this.ctx.strokeStyle = "#f56c6c";
                  this.ctx.fillStyle = "#f56c6c";
                }
                this.ctx.moveTo(end_left - 10, end_top + 7);
                this.ctx.lineTo(end_left, end_top - 3);
                this.ctx.lineTo(end_left - 10, end_top - 13);
                this.ctx.stroke();
                this.ctx.fill();
                this.ctx.closePath();
              }
            }
            clearInterval(this.other_timeout_list[index]);
          } else {
            this.ctx.beginPath();
            if (target.nodeList.length && this.matchEmpty(res, target)) {
              this.ctx.strokeStyle = "#30648f"; //设置线条颜色
              this.ctx.fillStyle = "#30648f";
            } else {
              this.ctx.strokeStyle = "#f56c6c";
              this.ctx.fillStyle = "#f56c6c";
            }
            this.ctx.moveTo(x, y);
            this.ctx.lineTo(ex, ey);
            this.ctx.stroke();
            this.ctx.closePath();
          }
        }, 1);
      });
    },
    // 直接画出前几步的线条
    direct_draw_line() {
      this.all_step_target.forEach((item, index) => {
        if (index < this.all_step_target.length - 1) {
          let target_list = []; //移动目标列表
          const own = this.final_rect[this.all_step_own[index]];
          item.forEach((target) => {
            target_list.push(this.final_rect[target]);
          });
          target_list.forEach((target) => {
            this.ctx.lineWidth = 4; //设置线条宽度
            this.ctx.strokeStyle = "#30648f"; //设置线条颜色
            this.ctx.setLineDash([]); //设置实线
            if (own.location === "left" && target.location === "left") {
              if (own.top > target.top) {
                this.ctx.beginPath();
                this.ctx.moveTo(own.left + 50, own.top);
                this.ctx.lineTo(target.left + 50, target.top + target.height);
                this.ctx.moveTo(
                  target.left + 50 - 7,
                  target.top + target.height + 12
                );
                this.ctx.lineTo(target.left + 50, target.top + target.height);
                this.ctx.lineTo(
                  target.left + 50 + 10,
                  target.top + target.height + 10
                );
                this.ctx.stroke();
                this.ctx.closePath();
              } else {
                this.ctx.beginPath();
                this.ctx.moveTo(own.left + 50, own.top + own.height);
                this.ctx.lineTo(target.left + 50, target.top);
                this.ctx.moveTo(target.left + 50 - 10, target.top - 10);
                this.ctx.lineTo(target.left + 50, target.top);
                this.ctx.lineTo(target.left + 50 + 7, target.top - 12);
                this.ctx.stroke();
                this.ctx.closePath();
              }
            } else if (own.location === "left" && target.location === "right") {
              this.ctx.beginPath();
              this.ctx.moveTo(own.left + own.width, own.top + own.height / 2);
              this.ctx.lineTo(
                this.cvs.width - target.right - target.width,
                target.top + target.height / 2
              );
              this.ctx.moveTo(
                this.cvs.width - target.right - target.width - 10,
                target.top + target.height / 2 + 10
              );
              this.ctx.lineTo(
                this.cvs.width - target.right - target.width,
                target.top + target.height / 2
              );
              this.ctx.lineTo(
                this.cvs.width - target.right - target.width - 10,
                target.top + target.height / 2 - 10
              );
              this.ctx.stroke();
              this.ctx.closePath();
            } else if (own.location === "right" && target.location === "left") {
              this.ctx.beginPath();
              this.ctx.moveTo(
                this.cvs.width - own.right - own.width,
                own.top + own.height / 2
              );
              this.ctx.lineTo(
                target.left + target.width,
                target.top + target.height / 2
              );
              this.ctx.moveTo(
                target.left + target.width + 10,
                target.top + target.height / 2 + 10
              );
              this.ctx.lineTo(
                target.left + target.width,
                target.top + target.height / 2
              );
              this.ctx.lineTo(
                target.left + target.width + 10,
                target.top + target.height / 2 - 10
              );
              this.ctx.stroke();
              this.ctx.closePath();
            }
          });
        }
      });
    },
    other_draw_Line() {
      this.other_step_target.forEach((item, index) => {
        if (index < this.other_step_target.length - 1) {
          const target_list = []; //移动目标列表
          const own = this.final_rect[this.other_step_own[index]];
          item.forEach((target) => {
            target_list.push(this.final_rect[target]);
          });
          target_list.forEach((target) => {
            this.ctx.lineWidth = 4; //设置线条宽度
            this.ctx.strokeStyle = "#30648f"; //设置线条颜色
            this.ctx.setLineDash([]); //设置实线
            let start_left = own.left;
            let start_top = own.top + own.height / 2;
            let end_left = target.left;
            let end_top = target.top + target.height / 2;
            let cp = [];
            let cp2 = [];
            let flag = false;
            // 确定控制点的位置
            if (
              (own.name === "SYS2" && target.name === "SWE1") ||
              (own.name === "SYS3" && target.name === "SWE1") ||
              (own.name === "SYS3" && target.name === "SWE2") ||
              (own.name === "SWE1" && target.name === "SYS2") ||
              (own.name === "SWE1" && target.name === "SYS3") ||
              (own.name === "SWE2" && target.name === "SYS3") ||
              (own.name === "SYS2" && target.name === "HWE1") ||
              (own.name === "SYS3" && target.name === "HWE1") ||
              (own.name === "SYS3" && target.name === "HWE2") ||
              (own.name === "HWE1" && target.name === "SYS2") ||
              (own.name === "HWE1" && target.name === "SYS3") ||
              (own.name === "HWE2" && target.name === "SYS3")
            ) {
              start_left =
                own.left > target.left ? own.left : own.left + own.width;
              end_left =
                own.left > target.left
                  ? target.left + target.width
                  : target.left;
              // 确定控制点的位置
              if (own.left > target.left) {
                cp = [
                  target.left + (own.left - target.left) / 2,
                  own.top + own.height / 2,
                ];
                cp2 = [
                  target.left + (own.left - target.left) / 2,
                  target.top + target.height / 2,
                ];
              } else {
                cp = [
                  own.left +
                    own.width +
                    (target.left - (own.left + own.width)) / 2,
                  own.top + own.height / 2,
                ];
                cp2 = [
                  own.left +
                    own.width +
                    (target.left - (own.left + own.width)) / 2,
                  target.top + target.height / 2,
                ];
              }
              flag = true;
            } else {
              if (own.top > target.top) {
                cp = [target.left - 200, (start_top - end_top) / 2 + end_top];
              } else {
                cp = [own.left - 200, (end_top - start_top) / 2 + start_top];
              }
              flag = false;
            }
            this.ctx.beginPath();
            this.ctx.moveTo(start_left, start_top);
            if (flag) {
              this.ctx.bezierCurveTo(
                cp[0],
                cp[1],
                cp2[0],
                cp2[1],
                end_left,
                end_top
              );
            } else {
              this.ctx.quadraticCurveTo(cp[0], cp[1], end_left, end_top);
            }
            this.ctx.stroke();
            this.ctx.closePath();
            this.ctx.beginPath();
            if (flag) {
              if (own.left > target.left) {
                this.ctx.moveTo(end_left + 10, end_top + 10);
                this.ctx.lineTo(end_left, end_top);
                this.ctx.lineTo(end_left + 10, end_top - 10);
                this.ctx.stroke();
                this.ctx.fillStyle = "#30648f";
                this.ctx.fill();
              } else {
                this.ctx.moveTo(end_left - 10, end_top + 10);
                this.ctx.lineTo(end_left, end_top);
                this.ctx.lineTo(end_left - 10, end_top - 10);
                this.ctx.stroke();
                this.ctx.fillStyle = "#30648f";
                this.ctx.fill();
              }
            } else {
              if (own.top > target.top) {
                this.ctx.moveTo(end_left - 10, end_top + 15);
                this.ctx.lineTo(end_left, end_top + 5);
                this.ctx.lineTo(end_left - 10, end_top - 5);
                this.ctx.stroke();
                this.ctx.fillStyle = "#30648f";
                this.ctx.fill();
              } else {
                this.ctx.moveTo(end_left - 10, end_top + 7);
                this.ctx.lineTo(end_left, end_top - 3);
                this.ctx.lineTo(end_left - 10, end_top - 13);
                this.ctx.stroke();
                this.ctx.fillStyle = "#30648f";
                this.ctx.fill();
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-view-wrap {
  position: relative;
  padding: 0 40px;
  overflow-x: scroll;
  .now_topic {
    position: absolute;
    padding: 10px 20px;
    border-radius: 4px;
    background: #303133;
    color: #fff;
  }
  .canvas-view {
    width: 1400px;
  }
  .select_style {
    width: 80px;
    position: absolute;
    word-break: keep-all;
    & > div {
      word-break: keep-all;
    }
  }
}
</style>
