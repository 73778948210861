<template>
  <div class="node_releaseinfo_row">
    <collapse :title="$t('nodeDetail.publish.title')">
      <div class="grid">
        <div class="row">
          <span
            style="
              font-weight: bold;
              margin-right: 20px;
              font-size: 14px;
              line-height: 30px;
              padding-top: 5px;
              word-break: normal;
            "
            @click.prevent="item_blur"
            >{{ $t("nodeDetail.publish.baseline") }}</span
          >
          <div
            v-if="!baselines_edit"
            v-limits-of-authority="'NODE_EDIT'"
            class="not_edit show_two_line"
            @click.stop="edit_baseline"
          >
            <div
              v-if="baseline_value.concat(baselines).length < 1"
              style="margin: 0 20px"
            ></div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="getBaselineTest(baseline_value)"
              placement="top-start"
            >
              <span v-if="getBaselineTest(baseline_value).length > 30">{{
                getBaselineTest(baseline_value).slice(0, 30) + "..."
              }}</span>
              <span v-else>{{ getBaselineTest(baseline_value) }}</span>
            </el-tooltip>

            <!-- <span v-for="(line,index) in baseline_static" :key="index+'base'" style="margin-right:20px">{{ line.name+'('+ switch_status(line.status) +')' }}</span> -->
            <!-- <span v-for="(line,index) in baselines" :key="index+'a'" style="margin-right:20px">{{ line.name+'('+ switch_status(line.status) +')' }}</span> -->
          </div>
          <div v-else>
            <el-select
              v-if="baselineModelFinished"
              v-model="baseline_value"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              class="baseline_select"
            >
              <el-option
                v-for="(baseline, index) in baseline_list"
                v-show="baseline.status != 'COMPLETE'"
                :key="index + 'a'"
                :label="
                  baseline.name + '(' + switch_status(baseline.status) + ')'
                "
                :value="baseline.baselineId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <span
            style="
              font-weight: bold;
              margin-right: 20px;
              font-size: 14px;
              line-height: 30px;
              padding-top: 5px;
              word-break: normal;
            "
            @click.prevent="item_blur"
            >{{ $t("nodeDetail.publish.version") }}</span
          >
          <div
            v-if="!versions_edit"
            v-limits-of-authority="'NODE_EDIT'"
            class="not_edit show_two_line"
            @click.stop="edit_versions"
          >
            <el-tooltip
              v-if="getVersionText(versions_value)"
              class="item"
              :content="getVersionText(versions_value)"
              effect="dark"
              placement="top-start"
            >
              <span v-if="getVersionText(versions_value).length > 30">{{
                getVersionText(versions_value).slice(0, 30) + "..."
              }}</span>
              <span v-else>{{ getVersionText(versions_value) }}</span>
            </el-tooltip>
            <!-- <span v-for="(version,index) in versions_static" :key="index" style="margin:0 20px">{{ version.name+'('+ switch_status(version.status) +')' }}</span> -->
            <div v-if="versions.length < 1" style="margin: 0 20px"></div>
            <!-- <span v-for="(version_,index) in versions " :key="index+'c'" style="margin:0 20px">{{ version_.name+'('+ switch_status(version_.status) +')' }}</span> -->
          </div>
          <div v-else>
            <el-select
              ref="version_select_zhu"
              v-if="versionFinished"
              v-model="versions_value"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              @change="versions_change"
              class="version_select"
            >
              <el-option
                v-for="(version, index) in versions_list"
                v-show="!version.status"
                :key="index"
                :label="
                  version.name + '(' + switch_status(version.status) + ')'
                "
                :value="version.versionId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div v-if="$store.getters.project_type == 'SCRUM'" class="row">
          <span
            style="
              font-weight: bold;
              margin-right: 20px;
              font-size: 14px;
              line-height: 30px;
              padding-top: 5px;
              width: 70px;
              word-break: normal;
            "
            @click.prevent="item_blur"
            >Sprint</span
          >
          <div
            v-if="!sprints_edit"
            v-limits-of-authority="'NODE_EDIT'"
            class="not_edit show_two_line"
            @click.stop="edit_sprints"
          >
            <div
              v-for="(sprint, index) in sprints_static"
              :key="index + 'spr'"
              style="margin: 0 20px"
            >
              {{ sprint.name + "(" + switch_status(sprint.status) + ")" }}
            </div>
            <div
              v-if="sprints.concat(sprints_static).length < 1"
              style="margin: 0 20px"
            ></div>
            <div
              v-for="(sprint_, index) in sprints"
              :key="index"
              style="margin: 0 20px"
            >
              {{ sprint_.name + "(" + switch_status(sprint_.status) + ")" }}
            </div>
          </div>
          <div v-else>
            <el-select
              v-model="sprints_value"
              :placeholder="$t('placeholder.dropdown')"
              clearable
              filterable
              @change="sprints_change"
            >
              <el-option
                v-for="(sprint, index) in sprints_list"
                :key="index"
                :label="sprint.name + '(' + switch_status(sprint.status) + ')'"
                :value="sprint.sprintId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div v-else class="row">
          <span
            style="
              font-weight: bold;
              margin-right: 20px;
              font-size: 14px;
              line-height: 30px;
              padding-top: 5px;
              width: 70px;
              word-break: normal;
            "
            @click.prevent="item_blur"
            >KANBAN</span
          >
          <div style="height: 100%; display: flex; align-items: center">
            <el-checkbox
              v-model="KANBAN_value"
              @change="changeKANBAN"
              style="vertical-align: middle"
            ></el-checkbox>
          </div>
        </div>
      </div>
      <div v-if="file_test" class="row1 row">
        <span
          style="
            font-weight: bold;
            margin-right: 20px;
            font-size: 14px;
            line-height: 30px;
            padding-top: 5px;
            word-break: normal;
          "
          @click.prevent="test_blur"
          >{{ $t("nodeDetail.publish.testPlan") }}</span
        >
        <div
          v-if="!testPlans_edit"
          v-limits-of-authority="'NODE_EDIT'"
          class="not_edit show_two_line"
          @click.stop="edit_testPlans"
        >
          <div v-if="testPlans.length < 1" style="margin: 0 20px"></div>

          <el-tooltip
            class="item"
            effect="dark"
            :content="
              getTestPlanText(
                testPlans_value,
                testPlans_static1,
                testPlans_static2
              )
            "
            placement="top-start"
          >
            <!-- <span v-for="(test,index) in testPlans_static1" :key="index+'test'" style="margin-right:20px">{{ test.name+'('+ switch_status(test.status) +')' }}</span> -->
            <!-- <span v-for="(test,index) in testPlans_static2" :key="index+'te'" style="margin-right:20px">{{ test.name+'('+ switch_status(test.status) +')' }}</span> -->
            <span>{{
              getTestPlanText(
                testPlans_value,
                testPlans_static1,
                testPlans_static2
              )
            }}</span>
            <!-- <span v-for="(testPlan,index) in testPlans" :key="index" style="margin-right:20px">{{ testPlan.name+'('+ switch_status(testPlan.status) +')' }}</span> -->
          </el-tooltip>
        </div>

        <div v-else>
          <el-select
            v-model="testPlans_value"
            multiple
            filterable
            :placeholder="$t('placeholder.dropdown')"
          >
            <el-option-group
              v-for="group in testPlans_list"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="testPlan in group.option"
                :key="testPlan.testPlanId"
                :disabled="testPlan.disable"
                :label="
                  testPlan.name + '(' + switch_status(testPlan.status) + ')'
                "
                :value="testPlan.testPlanId"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </div>
      </div>
    </collapse>
  </div>
</template>

<script>
import { get_filetype_List } from "@/network/fileType/index.js";
import {
  get_node_baselines,
  get_node_sprints,
  get_node_testPlans,
  get_node_versions,
  updateBaselineBatch,
} from "@/network/node/index.js";
import {
  add_nodes as baseline_add_nodes,
  delete_nodes as baseline_delete_nodes,
} from "@/network/baseline/index.js";
import {
  add_nodes as version_add_nodes,
  delete_nodes as version_delete_nodes,
} from "@/network/version/index.js";
import { updateTestPlanBatch } from "@/network/testPlan/index.js";
import {
  add_sprint_nodes,
  delete_sprint_nodes,
  kanbanFetch,
  add_kanban_nodes,
  delete_kanban_nodes,
} from "@/network/quick/index.js";
import { updateVersionBatch } from "@/network/version/index.js";
import Collapse from "./Collapse.vue";
import { get_all_versions } from "@/network/version/index.js";
export default {
  components: { Collapse },
  props: {
    nodeKey: {
      type: String,
      default: "",
    },
    nodeInfo: {
      type: [],
    },
  },
  data() {
    return {
      allPlans: [],
      versionFinished: false,
      baselineModelFinished: false,
      file_test: false,
      value: "",
      baselines: [],
      sprints: [],
      versions: [],
      testPlans: [],
      baselines_edit: false,
      sprints_edit: false,
      versions_edit: false,
      testPlans_edit: false,
      baseline_list: [],
      baseline_value: [],
      baseline_static: [],
      baseline_const: [],
      versions_static: [],
      versions_list: [],
      versions_value: [],
      versions_const: [],
      testPlans_list: [],
      testPlans_value: [],
      testPlans_const: [],
      testPlans_static1: [],
      testPlans_static2: [],
      sprints_list: [],
      sprints_value: "",
      KANBAN_value: false,
      sprints_static: [],
      sprints_const: "",
      has_versions_list: [],
    };
  },
  watch: {
    versions_edit(newValue) {
      if (newValue === false) {
        const obj = {};
        this.has_versions_list.forEach((item) => {
          obj[item.versionId] = item.referenced;
        });
        const arr = this.versions_value.filter((item) => {
          return !obj[item];
        });
        updateVersionBatch(this.get_pid(), this.nodeKey, arr);
      } else {
        this.$nextTick((_) => {
          this.$refs["version_select_zhu"].toggleMenu();
        });
      }
    },
    baselines_edit(newValue) {
      if (newValue === false) {
        // 剔除已完成基线并上传
        // let baseline_value_change = [];
        // this.baseline_list.forEach((item1) => {
        //   this.baseline_value.forEach((item2) => {
        //     if (item2 == item1.baselineId && item1.status != "COMPLETE") {
        //       baseline_value_change.push(item2);
        //     }
        //   });
        // });
        updateBaselineBatch(this.get_pid(), this.nodeKey, this.baseline_value);
      }
    },
    testPlans_edit(newValue) {
      const planIds = [];
      this.testPlans_static1.forEach((plan1) => planIds.push(plan1.testPlanId));
      this.testPlans_static2.forEach((plan2) => planIds.push(plan2.testPlanId));
      if (newValue === false) {
        updateTestPlanBatch(this.get_pid(), this.nodeKey, [
          ...this.testPlans_value,
          ...planIds,
        ]).then((res) => {
          this.g_node_t();
        });
      }
      let i = 0;
      const static2Id = [];
      for (const plan of this.testPlans_value) {
        if (this.isInProgress(plan)) {
          this.testPlans_value.splice(i, 1);
          static2Id.push(plan);
        }
        i++;
      }
      this.allPlans.forEach((plan) => {
        if (static2Id.indexOf(plan.testPlanId) !== -1) {
          this.testPlans_static2.push(plan);
        }
      });
    },
  },
  created() {
    let fileTypeId = this.nodeInfo.fileTypeId;
    get_filetype_List(this.get_pid()).then((res) => {
      for (let i of res) {
        if (i["fileTypeId"] == fileTypeId) {
          this.file_test = i.testCase;
        }
      }
    });
    this.g_node_v();
    this.g_node_b();
    this.g_node_t();
    this.g_node_s();
    //获取所有基线列表
    this.baselineModelFinished = true;
    this.baseline_list = [];
    Object.values(JSON.parse(sessionStorage.getItem("_baseLine"))).forEach(
      (e) => {
        this.baseline_list.push(e);
      }
    );

    this.baseline_list.sort((a, b) => {
      if (a.status === "ALREADY_STARTED" && b.status !== "ALREADY_STARTED") {
        return -1;
      } else if (
        a.status !== "ALREADY_STARTED" &&
        b.status === "ALREADY_STARTED"
      ) {
        return 1;
      } else {
        return 0;
      }
    });
    //获取所有version列表

    this.versions_value = [];
    for (let i of this.versions) {
      this.versions_value.push(i.versionId);
    }
    this.versionFinished = true;
    for (let i of Object.values(
      JSON.parse(sessionStorage.getItem("_version"))
    )) {
      this.versions_list.push(i);
    }

    //获取所有sprint列表
    this.sprints_list = [];
    Object.values(JSON.parse(sessionStorage.getItem("_sprint"))).forEach(
      (e) => {
        e.status == "ENDED" ? "" : this.sprints_list.push(e);
      }
    );

    this.sprints_list.sort((a, b) => {
      if (a.status === "IN_PROGRESS" && b.status !== "IN_PROGRESS") {
        return -1;
      } else if (a.status !== "IN_PROGRESS" && b.status === "IN_PROGRESS") {
        return 1;
      } else {
        return 0;
      }
    });
  },
  mounted() {
    if (this.$store.getters.project_type !== "SCRUM") {
      this.kanbanFetch();
    }
  },
  methods: {
    changeKANBAN(value) {
      if (value) {
        const params = {
          projectId: this.get_pid(),
          data: [this.nodeKey],
        };
        add_kanban_nodes(params).then((res) => {});
      } else {
        const params = {
          projectId: this.get_pid(),
          nodeKeys: [this.nodeKey],
        };
        delete_kanban_nodes(params).then((res) => {});
      }
    },
    kanbanFetch() {
      const params = {
        projectId: this.get_pid(),
        data: [this.nodeKey],
      };
      kanbanFetch(params).then((res) => {
        if (!res) {
          this.KANBAN_value = false;
        } else {
          this.KANBAN_value = true;
        }
      });
    },
    // 移除版本回调
    versions_change(params) {
      let flag = false;
      const arr = this.versions_value_before.filter(
        (item) => !params.includes(item)
      );
      this.has_versions_list.forEach((item) => {
        if (item.versionId === arr[0] && item.referenced) {
          flag = true;
        }
      });
      if (flag) {
        this.$message({
          message: this.$t("canvas.testPlan.message.warn"),
          type: "warning",
        });
        this.versions_value.unshift(arr[0]);
      } else {
        this.versions_value_before = params;
      }
    },
    isInProgress(testPlanId) {
      for (const plan of this.allPlans) {
        if (plan.testPlanId === testPlanId && plan.status === "IN_PROGRESS") {
          return true;
        }
      }
      return false;
    },

    getTestPlanText(testPlanIds, t1, t2) {
      const t = [];
      t1.forEach((item) =>
        t.push(`${item.name}(${this.switch_status(item.status)}) `)
      );
      t2.forEach((item) =>
        t.push(`${item.name}(${this.switch_status(item.status)}) `)
      );
      testPlanIds.forEach((testPlanId) => {
        this.allPlans.forEach((testPlan) => {
          if (testPlanId === testPlan.testPlanId) {
            t.push(`${testPlan.name}(${this.switch_status(testPlan.status)}) `);
          }
        });
      });

      return t.toString();
    },
    getBaselineTest(baselineIds) {
      const baselineNames = [];
      for (const baselineId of baselineIds) {
        this.baseline_list.forEach((baseline) => {
          if (baseline.baselineId === baselineId) {
            baselineNames.push(
              `${baseline.name}(${this.switch_status(baseline.status)}) `
            );
          }
        });
      }
      return baselineNames.toString();
    },
    getVersionText(versionIds) {
      const versionNames = [];
      for (const versionId of versionIds) {
        this.versions_list.forEach((version) => {
          if (version.versionId === versionId) {
            versionNames.push(
              `${version.name}(${this.switch_status(version.status)}) `
            );
          }
        });
      }

      return versionNames.toString();
    },
    get_text(...list) {
      let str = "";
      for (let i of list) {
        for (let j of i) {
          str += j.name + "(" + this.switch_status(j.status) + ")";
          str = str + "   " + "，" + "   ";
        }
      }
      return str.slice(0, -4);
    },
    // sprint改变保存
    sprints_change() {
      if (this.sprints_value.length < 1) {
        delete_sprint_nodes({
          projectId: this.get_pid(),
          sprintId: this.sprints_const,
          nodeKey: this.nodeKey,
        });
        this.sprints_const = "";
        this.sprints = "";
      } else {
        const params = {
          projectId: this.get_pid(),
          sprintId: this.sprints_value,
          data: [this.nodeKey],
        };
        add_sprint_nodes(params).then((res) => {
          this.sprints_const = JSON.parse(JSON.stringify(this.sprints_value));
          this.g_node_s();
        });
      }
    },
    // 开始编辑sprint
    edit_sprints() {
      this.item_blur();
      this.sprints_edit = true;
      this.sprints_const = JSON.parse(JSON.stringify(this.sprints_value));
    },
    test_blur() {
      this.testPlans_change();
    },
    testPlans_change() {
      // for (let i of this.testPlans_const) {
      //   if (this.testPlans_value.indexOf(i) < 0) {
      //     await delete_test_node(this.$route.params.projectId || this.get_pid(), i, [
      //       this.nodeKey,
      //     ]).then((res) => {});
      //   }
      // }
      // for (let i of this.testPlans_value) {
      //   if (this.testPlans_const.indexOf(i) < 0) {
      //     await add_test_node(this.$route.params.projectId || this.get_pid(), [this.nodeKey], i).then(
      //       (res) => {}
      //     );
      //   }
      // }
      // this.g_node_t();
      this.item_blur();
    },
    // 开始编辑测试计划
    edit_testPlans() {
      this.item_blur();
      this.testPlans_edit = true;
      this.testPlans_const = JSON.parse(JSON.stringify(this.testPlans_value));
    },
    // // 版本改变保存
    // versions_change() {
    //   for (let i of this.versions_const) {
    //     if (this.versions_value.indexOf(i) < 0) {
    //       version_delete_nodes(this.get_pid(), i, [
    //         this.nodeKey,
    //       ]).then((res) => {
    //         this.g_node_v();
    //         this.versions_const = JSON.parse(
    //           JSON.stringify(this.versions_value)
    //         );
    //       });
    //     }
    //   }
    //   for (let i of this.versions_value) {
    //     if (this.versions_const.indexOf(i) < 0) {
    //       version_add_nodes(this.get_pid(), i, [this.nodeKey]).then(
    //         (res) => {
    //           this.g_node_v();
    //           this.versions_const = JSON.parse(
    //             JSON.stringify(this.versions_value)
    //           );
    //         }
    //       );
    //     }
    //   }
    // },
    // 开始编辑版本
    edit_versions() {
      this.item_blur();
      this.versions_edit = true;
      this.versions_const = JSON.parse(JSON.stringify(this.versions_value));
      // 保存修改前的version 选中项
      this.versions_value_before = this.versions_value;
    },
    // 基线改变保存
    baseline_change() {
      for (let i of this.baseline_const) {
        if (this.baseline_value.indexOf(i) < 0) {
          baseline_delete_nodes(this.get_pid(), i, [this.nodeKey]).then(
            (res) => {
              this.g_node_b();
              this.baseline_const = JSON.parse(
                JSON.stringify(this.baseline_value)
              );
            }
          );
        }
      }
      for (let i of this.baseline_value) {
        if (this.baseline_const.indexOf(i) < 0) {
          baseline_add_nodes(this.get_pid(), i, [this.nodeKey]).then((res) => {
            this.g_node_b();
            this.baseline_const = JSON.parse(
              JSON.stringify(this.baseline_value)
            );
          });
        }
      }
    },
    // 开始编辑基线
    edit_baseline() {
      this.item_blur();
      this.baselines_edit = true;
      this.baseline_const = JSON.parse(JSON.stringify(this.baseline_value));
    },
    // 恢复默认样式
    item_blur() {
      this.baselines_edit = false;
      this.sprints_edit = false;
      this.versions_edit = false;
      this.testPlans_edit = false;
    },
    // 翻译状态
    switch_status(val) {
      switch (val) {
        case "IN_PROGRESS":
          return this.$t("canvas.testPlan.status.inProgress");
        case "ENDED":
          return this.$t("canvas.testPlan.status.completed");
        case "ALREADY_STARTED":
          return this.$t("canvas.testPlan.status.started");
        case "NOT_STARTED":
          return this.$t("canvas.testPlan.status.notStarted");
        case "COMPLETE":
          return this.$t("canvas.testPlan.status.ended");
        case true:
          return this.$t("canvas.testPlan.status.published");
        case false:
          return this.$t("canvas.testPlan.status.notPublished");
      }
    },
    g_node_v() {
      get_node_versions(this.get_pid(), this.nodeKey).then((res) => {
        this.versions = [];
        this.versions_static = [];
        for (let i of res) {
          if (i.status) {
            this.versions_static.push(i);
          } else {
            this.versions.push(i);
          }
        }
        // this.versions_value = res.map((item) => item.versionId);
        this.has_versions_list = res;
        this.versions_value = this.has_versions_list.map(
          (item) => item.versionId
        );
      });
    },
    g_node_b() {
      get_node_baselines(this.get_pid(), this.nodeKey).then((res) => {
        this.baselines = [];
        this.baseline_static = [];
        for (let i of res) {
          if (i.status == "COMPLETE") {
            this.baseline_static.push(i);
          } else {
            this.baselines.push(i);
          }
        }
        this.baseline_value = res.map((item) => item.baselineId);
        // this.baseline_value = this.baselines.map((item) => item.baselineId);
      });
    },
    g_node_t() {
      get_node_testPlans(this.get_pid(), this.nodeKey).then((res) => {
        this.testPlans = [];
        this.testPlans_static1 = [];
        this.testPlans_static2 = [];
        this.testPlans_value = [];
        for (let i of res) {
          if (i.status == "NOT_STARTED") {
            this.testPlans.push(i);
            this.testPlans_value.push(i.testPlanId);
          } else if (i.status == "IN_PROGRESS") {
            this.testPlans_static1.push(i);
          } else {
            this.testPlans_static2.push(i);
          }
        }
        let li = Object.values(JSON.parse(sessionStorage.getItem("_testPlan")));
        let inp = [];
        let nop = [];
        this.allPlans = [];
        li.forEach((item) => {
          if (item.status == "NOT_STARTED") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.notStarted") +
              ")";
            this.allPlans.push(item);
            nop.push(item);
          } else if (item.status == "IN_PROGRESS") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.inProgress") +
              ")";
            this.allPlans.push(item);
            inp.push(item);
          } else if (item.status == "COMPLETE") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.completed") +
              ")";
            // this.test_plan_list.push(item)
          }
        });
        // this.allPlans = [...(res["NOT_STARTED"]||[]), ...(res["IN_PROGRESS"]||[])]

        // let inp = res.IN_PROGRESS;
        for (let i of this.testPlans_static1) {
          for (let j in inp) {
            if (i.testPlanId == inp[j]["testPlanId"]) {
              inp.splice(j, 1);
            }
          }
        }
        this.testPlans_list = [
          {
            label: this.$t("nodeDetail.publish.info1"),
            option: nop,
          },
          {
            label: this.$t("nodeDetail.publish.info2"),
            option: inp,
          },
        ];
      });
    },
    g_node_s() {
      get_node_sprints(this.get_pid(), this.nodeKey).then((res) => {
        this.sprints = [];
        this.sprints_static = [];
        for (let i of res) {
          if (i.status == "ENDED") {
            this.sprints_static.push(i);
          } else {
            this.sprints.push(i);
          }
        }
        const spirnt_id = res.map((item) => item.sprintId);
        this.sprints_value = spirnt_id.length ? spirnt_id[0] : "";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.node_releaseinfo_row {
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 8px;
}
.grid {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.row {
  align-items: flex-start;
  display: flex;
  margin: 10px 0;
  padding-left: 5px;
  ::v-deep {
    .el-input {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
}
.not_edit {
  margin-right: 10%;
  padding: 5px;
  width: 70%;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #e5e5e5;
  }
}
.show_two_line {
  min-height: 40px;
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2; //自定义行数
  line-height: 30px;
}
::v-deep .baseline_select,
::v-deep .version_select {
  .el-tag__close {
    display: none;
  }
}
::v-deep .el-select {
  .tags-select-input {
    height: 40px;
    .el-select__tags {
      height: 40px;
      white-space: nowrap;
      overflow: hidden;
      flex-wrap: wrap;
    }
  }

  .el-input__icon {
    height: 40px;
  }

  .el-select__tags-text {
    display: inline-block;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  // .el-tag__close.el-icon-close {
  //   top: -7px;
  // }
}
</style>
<style lang="scss">
.row1 {
  .el-select {
    width: 150% !important;
  }
}
</style>
