<template>
  <div class="workflow">
    <!-- this.node_status.statusCategory -->
    <el-button
      slot="reference"
      size="mini"
      :class="{
        status_todo: node_status.statusCategory == 'TODO',
        status_progress: node_status.statusCategory == 'IN_PROGRESS',
        status_open: node_status.statusCategory == 'DONE',
      }"
      @click="show_workflow_list"
    >
      <span
        style="
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        "
      >
        {{ node_status.name }}
      </span>
      <i style="font-weight: 800" class="el-icon-arrow-down"></i>
    </el-button>
    <div v-show="showWorkflow" class="workflow_select">
      <template v-if="workflows.length > 0">
        <div
          v-for="(item, index) in workflows"
          :key="index"
          class="workflow_item"
        >
          <div v-limits-of-authority="'NODE_EDIT'">
            <span
              style="
                display: inline-block;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
              @click="changeWorkflow(item)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="workflow_item" @click="view_workflow">
          <span class="view_workflow">{{
            $t("canvas.floatWindow.workflowView")
          }}</span>
        </div>
      </template>
      <template v-else>
        <div class="workflow_none">
          <span>{{ $t("placeholder.table2") }}</span>
        </div>
        <div class="workflow_item" @click="view_workflow">
          <span class="view_workflow">{{
            $t("canvas.floatWindow.workflowView")
          }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  get_workflow_list,
  set_workflow,
  set_workflow_myself,
} from "@/network/node/index.js";
import { get_node_use_node_key } from "@/network/node/index.js";
import { mapGetters } from "vuex";

export default {
  props: {
    nodeKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      node_status: {},
      node_info: {},
      showWorkflow: false,
      workflows: [],
      config: {
        fileTypeId: null,
        layer: null,
        projectId: null,
      },
    };
  },
  computed: {
    ...mapGetters({ allstatus: "status" }),
  },
  mounted() {
    // this.node_status.statusId = this.nodeInfo.status
    // this.node_status.name = this.nodeInfo.statusName;
    // this.node_status.statusCategory = this.nodeInfo.statusCategory;
    // Object.keys(this.config).forEach((name) => {
    //   this.config[name] = this.nodeInfo[name];
    // });
    // let params = {
    //   projectId: this.config.projectId,
    //   fileTypeId: this.config.fileTypeId,
    //   layer: this.config.layer,
    //   currentStatusId: this.node_status.statusId,
    // }
    // get_workflow_list({ ...params }).then((res) => {
    //   this.workflows = res.filter((item) => {
    //     return item.name != this.node_status.name
    //   })
    //   this.$emit('headCallBack', this.node_status.name)
    // })
    this.get_node_data();
  },
  methods: {
    show_workflow_list() {
      this.showWorkflow = !this.showWorkflow;
      if (this.showWorkflow) {
        this.get_node_data();
      }
    },
    async get_node_data() {
      const res = await get_node_use_node_key(this.get_pid(), this.nodeKey);
      this.node_info = res;
      this.node_status.statusId = res.status;
      this.node_status.statusId
        ? ((this.node_status.name = this.allstatus[
            this.node_status.statusId
          ].name),
          (this.node_status.statusCategory = this.allstatus[
            this.node_status.statusId
          ].statusCategory))
        : "";
      Object.keys(this.config).forEach((name) => {
        this.config[name] = res[name];
      });
      this.get_status();
    },
    changeWorkflow(val) {
      this.set_properties_value(val);
    },
    async get_status() {
      let params = {
        projectId: this.config.projectId,
        fileTypeId: this.config.fileTypeId,
        layer: this.config.layer,
        currentStatusId: this.node_status.statusId,
      };
      const res = await get_workflow_list({ ...params });
      this.workflows = res.filter((item) => {
        return item.name != this.node_status.name;
      });
      this.$emit("headCallBack", this.node_status.name);
    },
    view_workflow() {
      this.$emit("show_workflow");
    },
    set_properties_value(val) {
      let params = {
        nodeId: this.node_info.id,
        nodeUuid: this.node_info.nodeUuid,
        targetStatus: val.statusId,
      };
      set_workflow_myself(this.config.projectId, params).then((res) => {
        if (res.length != 0) {
          // 此时有验证条件未通过
          // 对弹窗进行改错

          this.$message({
            showClose: true,
            message: res,
            duration: 0,
            type: "error",
          });
        } else {
          // 验证条件通过
          set_workflow(this.config.projectId, params)
            .then((res) => {
              this.node_status = val;
              this.showWorkflow = false;
              this.get_status();
            })
            .catch((err) => {});
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.workflow {
  position: relative;
  width: 120px;
  float: right;
  .el-button {
    width: 100%;
    height: 27px;
    // line-height: 27px;
    // background: rgba(48, 100, 143, 1);
    font-size: 12px;
    font-weight: 800;
    // color: #fff;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .status_todo {
    background-color: rgba(166, 166, 166, 1);
    border: 1px solid rgba(166, 166, 166, 1);
    color: white;
  }
  .status_open {
    color: white;
    background-color: rgba(67, 207, 124, 1);
    border: 1px solid rgba(67, 207, 124, 1);
  }
  .status_progress {
    color: white;
    background-color: rgba(42, 130, 228, 1);
    border: 1px solid rgba(42, 130, 228, 1);
  }
  .workflow_select {
    z-index: 12;
    width: 120px;
    background: #fff;
    position: absolute;
    padding: 10px 10px;
    margin-top: 6px;
    border-radius: 6px;
    right: 0;
    text-align: left;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border: 1px solid #e4e7ed;
    .workflow_item,
    .workflow_none {
      height: 32px;
      align-items: center;
      width: 100%;
      span {
        width: 100%;
        height: 100%;
        line-height: 32px;
        cursor: pointer;
      }
    }
    .workflow_item:hover {
      background: rgba(9, 30, 66, 0.06);
    }
  }
}
</style>
