<template>
  <div>
    <div v-if="basedata.length > 0" v-loading="isload">
      <el-carousel
        trigger="click"
        :autoplay="false"
        :class="{ limitHeight: limitHeight }"
        class="con"
        indicator-position="none"
        arrow="always"
        @change="c"
      >
        <el-carousel-item v-for="(item, index) in basedata" :key="index">
          <div class="box" :class="{ limitHeight: limitHeight }">
            <div class="tool">
              <p class="block_title">
                {{ +(current + 1) + "/" + basedata.length }}
              </p>
              <div class="line"></div>
              <i
                v-if="lock"
                class="svgimg r2 iconfont icon-add"
                @click="show_msg"
              />
              <!-- <svg-icon v-else class="svgimg" icon-class="add" @click="add_draw" /> -->
              <el-button
                v-else
                type="info"
                style="
                  width: 100%;
                  border: none;
                  background-color: rgb(230, 230, 230);
                  font-size: 26px;
                "
                ><i
                  class="svgimg iconfont icon-add"
                  style="font-size: 26px"
                  @click="add_draw"
                />
                <i
                  class="iconfont icon-AIbuchong"
                  style="margin-left: 10px; font-size: 24px"
                  @click="showAIdialog"
                ></i>
              </el-button>
              <div class="line"></div>
              <img
                v-if="lock"
                class="r2"
                src="@/assets/img/edit.png"
                @click="show_msg"
              />
              <!-- <img v-else src="@/assets/img/edit.png" @click="edit_draw(item.id)" /> -->
              <el-button
                v-else
                type="info"
                style="
                  width: 100%;
                  border: none;
                  background-color: rgb(230, 230, 230);
                "
                @click="edit_draw(item.id)"
                ><img src="@/assets/img/edit.png"
              /></el-button>
              <div class="line"></div>
              <!-- <img src="@/assets/img/zoom.png" @click="zoom(index)" /> -->
              <el-button
                type="info"
                style="
                  width: 100%;
                  border: none;
                  background-color: rgb(230, 230, 230);
                "
              >
                <!-- <el-image :src="require('@/assets/img/zoom.png')" :preview-src-list="[basedata[index].data]"></el-image> -->
                <img src="@/assets/img/zoom.png" @click="zoom(index)" />
              </el-button>
              <div class="line"></div>
              <img
                v-if="lock"
                class="r2"
                src="@/assets/img/del.png"
                @click="show_msg"
              />
              <!-- <img v-else src="@/assets/img/del.png" @click="del_draw(item.id)" /> -->
              <el-button
                v-else
                type="info"
                style="
                  width: 100%;
                  border: none;
                  background-color: rgb(230, 230, 230);
                "
                @click="showDelDia(item.id)"
                ><img src="@/assets/img/del.png"
              /></el-button>
              <div class="line"></div>
              <el-tooltip v-if="!id_edit" effect="dark" :content="item.name">
                <div v-if="lock" class="r2" @click="show_msg">
                  {{
                    item.name.length > 12
                      ? item.name.slice(0, 12) + "..."
                      : item.name
                  }}
                </div>
                <div v-else @click="id_edit = true">
                  {{
                    item.name.length > 12
                      ? item.name.slice(0, 12) + "..."
                      : item.name
                  }}
                </div>
              </el-tooltip>
              <div v-else>
                <input
                  v-model="item.name"
                  type="text"
                  @keyup.enter="away()"
                  @blur="away()"
                />
              </div>
            </div>
            <div class="img" :class="{ limitHeight: limitHeight }">
              <img
                :id="item.id"
                :key="once[index]"
                :src="item.data"
                :class="{ limitImgHeight: limitHeight }"
                :node-key="nodeKey"
              />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <el-img-viewer
        class="viewer"
        :z-index="100000"
        preview-teleported
        v-if="visiblelist"
        :url-list="[itemdata]"
        :on-close="closeImgViewer"
      ></el-img-viewer>
      <el-dialog
        :close-on-click-modal="false"
        :show-close="true"
        :modal="false"
        :title="$t('review.tip11')"
        :visible.sync="delStructDia"
        width="25%"
      >
        <span style="font-weight: normal">{{
          $t("nodeDetail.basicInfo.ms1")
        }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="del_draw">{{
            $t("addMyColla.table.title10")
          }}</el-button>
          <el-button @click="delStructDia = false">{{
            $t("btn.cancelBtn")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-else>
      <img
        v-if="lock"
        v-limits-of-authority="'NODE_EDIT'"
        src="@/assets/img/drawio.png"
        class="svgimg1 r2"
        @click="add_highlight"
      />
      <img
        v-else
        v-limits-of-authority="'NODE_EDIT'"
        src="@/assets/img/drawio.png"
        class="svgimg1"
        @click="add_draw"
      />
      <i
        v-if="!lock"
        class="iconfont icon-AIbuchong"
        style="
          font-size: 25px;
          vertical-align: top;
          margin-left: 10px;
          color: #174fa9;
        "
        @click="showAIdialog"
      ></i>
    </div>
    <el-dialog
      :visible.sync="AIdialogVisible"
      :close-on-click-modal="false"
      width="60%"
      :title="$t('node.ai.title')"
      :modal="false"
    >
      <div>
        <el-input
          ref="ai_adilog1"
          v-model="aiForm.aiText"
          type="textarea"
          :rows="2"
          :placeholder="$t('node.ai.placeholder1')"
          style="margin-bottom: 20px"
          maxlength="3000"
          show-word-limit
        >
        </el-input>
      </div>
      <div>
        <el-select
          v-model="aiForm.aiSelect"
          :placeholder="$t('node.ai.placeholder2')"
          style="margin-right: 20px"
          size="medium"
        >
          <el-option
            v-for="item in aiOps"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          type="text"
          size="medium"
          :loading="robotTyping"
          :disabled="!aiForm.aiSelect || !aiForm.aiText"
          style="vertical-align: middle"
          @click="initMermaid"
        >
          <i
            v-if="!aiForm.aiSelect || !aiForm.aiText"
            class="iconfont icon-shengcheng"
            style="font-size: 28px; color: grey; cursor: not-allowed"
          ></i>
          <i
            v-else
            class="iconfont icon-shengcheng"
            style="font-size: 28px; color: rgb(0, 76, 170)"
          ></i>
        </el-button>
      </div>
      <div class="mermaid-wrap">
        <div>
          <el-input
            ref="ai_adilog2"
            v-model="aiForm.aiCode"
            type="textarea"
            :rows="20"
            :placeholder="$t('node.ai.placeholder1')"
            style="margin-bottom: 20px"
            maxlength="5000"
            show-word-limit
          >
          </el-input>
        </div>
        <div v-if="aiForm.aiCode" @click="zoomMermaid">
          <vue-mermaid-string id="svgdom" :value="aiForm.aiCode" />
        </div>
      </div>
      <div
        v-show="aiForm.aiCode && !robotTyping"
        slot="footer"
        class="dialog-footer"
      >
        <el-button type="info" @click="bumanyiClick">{{
          $t("knowledge.knowledge_ai.btn2")
        }}</el-button>
        <el-button type="primary" class="button-confirm" @click="manyiClick">{{
          $t("knowledge.knowledge_ai.btn3")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="zoomMermaidVisible"
      :close-on-click-modal="true"
      width="60%"
      :title="$t('templateLibrary.preview')"
      :modal="false"
    >
      <div v-if="zoomMermaidVisible">
        <vue-mermaid-string id="svgdom" :value="aiForm.aiCode" />
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="bumanyi_show"
      :close-on-click-modal="false"
      width="50%"
      :title="$t('knowledge.knowledge_ai.title2')"
      append-to-body
      class="ai_bumanyi"
      @close="before_bumanyi"
      :modal="false"
    >
      <el-alert
        v-show="bumanyi2_show"
        :title="$t('knowledge.knowledge_ai.title3')"
        type="warning"
        show-icon
        :closable="false"
        style="background-color: #edf6fb; margin-bottom: 20px"
      >
      </el-alert>
      <div v-if="languageType != 'en-US'">
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form1") }}
          <el-input
            v-model="bumanyi_form.a"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 150px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form2")
          }}<el-input
            v-model="bumanyi_form.b"
            :placeholder="$t('knowledge.knowledge_ai.placeholder5')"
            size="mini"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form3")
          }}<el-input
            v-model="bumanyi_form.c"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input
          >{{ $t("knowledge.knowledge_ai.bumanyi_form4") }}
        </div>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form5") }}
          <el-input
            v-model="bumanyi_form.d"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
      </div>
      <div v-else>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form1") }}
          <el-input
            v-model="bumanyi_form.a"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 150px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
        <div>
          I hope to

          <el-input
            v-model="bumanyi_form.b"
            :placeholder="$t('knowledge.knowledge_ai.placeholder5')"
            size="mini"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
          the above content
        </div>
        <div>
          To achieve the goal of

          <el-input
            v-model="bumanyi_form.c"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
        <div>
          {{ $t("knowledge.knowledge_ai.bumanyi_form5") }}
          <el-input
            v-model="bumanyi_form.d"
            size="mini"
            :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
            style="
              width: 200px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 15px;
            "
          ></el-input>
        </div>
      </div>
      <div v-show="!show_res" slot="footer" class="dialog-footer">
        <el-button
          type="text"
          size="medium"
          :loading="robotTyping"
          style="display: flex; align-items: center"
          :disabled="bumanyi_form.b < 1"
          @click="bumanyi_shengcheng"
        >
          <i
            v-if="bumanyi_form.b < 1"
            class="iconfont icon-shengcheng"
            style="font-size: 28px; color: grey; cursor: not-allowed"
          ></i>
          <i
            v-else
            class="iconfont icon-shengcheng"
            style="font-size: 28px; color: rgb(0, 76, 170)"
          ></i>
        </el-button>
      </div>
      <div v-show="show_res" class="mermaid-wrap">
        <div>
          <el-input
            ref="ai_adilog2"
            v-model="aiForm.aiCode"
            type="textarea"
            :rows="20"
            :placeholder="$t('node.ai.placeholder1')"
            style="margin-bottom: 20px"
            maxlength="5000"
            show-word-limit
          >
          </el-input>
        </div>
        <div v-if="aiForm.aiCode" @click="zoomMermaid">
          <vue-mermaid-string id="svgdom" :value="aiForm.aiCode" />
        </div>
      </div>
      <div v-show="show_res" slot="footer" class="dialog-footer">
        <el-button type="info" @click="bumanyiClick">{{
          $t("knowledge.knowledge_ai.btn2")
        }}</el-button>
        <el-button type="primary" class="button-confirm" @click="manyiClick">{{
          $t("knowledge.knowledge_ai.btn3")
        }}</el-button>
      </div>
    </el-dialog>
    <payDialog
      :dialog-visible.sync="payDialog_isShow"
      :title_text="$t('AI.tip6')"
      :tip_text="$t('AI.tip7')"
    ></payDialog>
    <mavon-editor
      ref="ai_md"
      v-model="ai_last_value"
      style="display: none"
      @change="ai_markdown_last_value_change"
    >
    </mavon-editor>
  </div>
</template>

<script>
import {
  get_node_use_node_key,
  only_set_property,
} from "@/network/node/index.js";
import {
  upload_drawio,
  download_drawio,
} from "@/network/fileOperation/index.js";
import { aiRobotPPayFeatureStatus } from "@/network/ai/index.js";
import { Drawio } from "@/plugins/drawio.js";
import { ossAuthorization } from "@/utils/oss";
import { ulid } from "ulid";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { mapGetters } from "vuex";
import payDialog from "@/components/payFeatureValidDialog";
import markdownEditor from "@/components/markdownEditor";
export default {
  components: {
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
    payDialog,
    markdownEditor,
  },
  props: {
    nodeKey: {
      type: String,
      default: "",
    },
    customvalue: {
      type: String,
      default: "",
    },
    custom: {
      type: String,
      default: "",
    },
    limitHeight: {
      type: Boolean,
      default: false,
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    lineWordNodeData: {
      type: Object,
      default() {
        return {};
      },
    },
    parentClient: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      new_graph: "",
      node: {},
      basedata: [],
      once: [],
      current: 0,
      itemdata: "",
      visiblelist: false,
      id_edit: false,
      client: "",
      isload: false,
      lock: false,
      delId: "",
      delStructDia: false,
      // AI相关变量
      AIdialogVisible: false,
      zoomMermaidVisible: false,
      aiForm: {
        aiText: "",
        aiSelect: "",
        aiCode: "",
      },
      robotTyping: false,
      aiOps: [
        {
          label: this.$t("node.ai.aiDiagram1"),
          value: "SEQUENCE_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram2"),
          value: "FLOW_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram3"),
          value: "CLASS_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram4"),
          value: "STATE_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram5"),
          value: "ER_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram6"),
          value: "GANTT_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram7"),
          value: "GIT_DIAGRAM",
        },
        {
          label: this.$t("node.ai.aiDiagram8"),
          value: "MIND_MAP_DIAGRAM",
        },
      ],
      payDialog_isShow: false,
      show_res: false,
      bumanyi_show: false,
      bumanyi2_show: false,
      bumanyi_form: {
        a: "",
        b: "",
        c: "",
        d: "",
      },
      ai_last_value: "",
    };
  },
  computed: {
    ...mapGetters(["token"]),
    languageType() {
      let type = "";
      const lang = localStorage.getItem("lang");
      if (lang === "en") {
        type = "en-US";
      } else if (lang === "zh") {
        type = "zh-CN";
      }
      return type;
    },
  },
  watch: {
    lineWordNodeData(newVal) {
      let tais_ = this;
      let res = newVal;
      this.node = res;
      this.lock = res.lock;
      if (this.custom) {
        for (let i of res.content) {
          if (i.fieldReferenceId == this.custom) {
            this.basedata = i.value || [];
            if (i.value) {
              tais_.basedata = [];
              JSON.parse(i.value).forEach(async (ele) => {
                let res = await download_drawio(ele.data);
                const reader = new FileReader();
                reader.readAsText(res);
                reader.onload = function (e) {
                  tais_.basedata.push({
                    id: ele.id,
                    name: ele.name,
                    data: e.target.result,
                  });
                };
              });
            } else {
              tais_.basedata = [];
            }
          }
        }
      } else {
        // console.log(res.architectureDiagrams);
        // this.basedata = res.architectureDiagrams || [];
        if (res.architectureDiagrams) {
          tais_.basedata = [];
          res.architectureDiagrams.forEach(async (ele) => {
            let res = await download_drawio(ele.data);
            const reader = new FileReader();
            reader.readAsText(res);
            reader.onload = function (e) {
              tais_.basedata.push({
                id: ele.id,
                name: ele.name,
                data: e.target.result,
              });
            };
          });
        } else {
          tais_.basedata = [];
        }
      }
    },
  },
  async created() {
    this.$store.commit("SET_IS_COPY", this.isCopy);
    // 获取阿里云oss授权
    if (!this.parentClient) {
      ossAuthorization.call(this);
    } else {
      this.client = this.parentClient;
    }
    let tais_ = this;
    let res = null;
    if (this.lineWordNodeData.key) {
      res = this.lineWordNodeData;
    } else {
      res = await get_node_use_node_key(this.get_pid(), this.nodeKey);
    }
    this.node = res;
    this.lock = res.lock;
    if (this.custom) {
      for (let i of res.content) {
        if (i.fieldReferenceId == this.custom) {
          this.basedata = i.value || [];
          if (i.value) {
            tais_.basedata = [];
            JSON.parse(i.value).forEach(async (ele) => {
              let res = await download_drawio(ele.data);
              const reader = new FileReader();
              reader.readAsText(res);
              reader.onload = function (e) {
                tais_.basedata.push({
                  id: ele.id,
                  name: ele.name,
                  data: e.target.result,
                });
              };
            });
          } else {
            tais_.basedata = [];
          }
        }
      }
    } else {
      // console.log(res.architectureDiagrams);
      // this.basedata = res.architectureDiagrams || [];
      if (res.architectureDiagrams) {
        tais_.basedata = [];
        res.architectureDiagrams.forEach(async (ele) => {
          let res = await download_drawio(ele.data);
          const reader = new FileReader();
          reader.readAsText(res);
          reader.onload = function (e) {
            tais_.basedata.push({
              id: ele.id,
              name: ele.name,
              data: e.target.result,
            });
          };
        });
      } else {
        tais_.basedata = [];
      }
    }
  },
  mounted() {
    for (let i in this.basedata) {
      this.once.push(0);
    }
    let this_ = this;
    window.addEventListener("message", function (e) {
      try {
        if (!this_.$store.getters.is_copy) {
          if (typeof e.data == "string") {
            let event = JSON.parse(e.data).event;
            let msg = "";
            let data = "";
            if (event == "export") {
              msg = JSON.parse(e.data).message.originelement;
              data = JSON.parse(e.data).data;
              this_.binddata(msg, data);
              this_.new_graph = "";
            }
            if (event == "save") {
              this_.new_graph = "";
            }
            if (event == "exit") {
              let l = [];
              if (this_.new_graph) {
                this_.basedata.forEach((item) => {
                  item.id == this_.new_graph ? "" : l.push(item);
                });
                this_.basedata = l;
              }
            }
          }
        }
      } catch (error) {}
    });
  },
  methods: {
    zoomMermaid() {
      this.zoomMermaidVisible = true;
    },
    // 生成mermaid代码
    initMermaid() {
      const self = this;
      aiRobotPPayFeatureStatus().then((res) => {
        if (res) {
          let controller = new AbortController();
          const signal = controller.signal;
          // 清空代码区域
          this.aiForm.aiCode = "";
          this.robotTyping = true;
          fetchEventSource(
            `${self.wsurl}${
              self.wsurl ? "" : "/prod-api"
            }/file-manage-service/${self.get_pid()}/aiRobot/generateDiagram`,
            {
              method: "POST",
              signal: signal,
              headers: {
                "Content-Type": "application/json",
                Authorization: self.token,
                "Accept-Language": self.languageType,
              },
              body: JSON.stringify({
                sessionId: ulid(),
                content: self.aiForm.aiText,
                operate: "CREATE_DIAGRAM",
                diagramTypes: self.aiForm.aiSelect,
              }),
              openWhenHidden: true,
              onmessage(event) {
                const data = JSON.parse(event.data);
                self.aiForm.aiCode += data.value
                  ? data.value.replaceAll(
                      "\\n",
                      `
  `
                    )
                  : "";
              },
              onerror(err) {
                self.robotTyping = false;
                throw err;
              },
              onclose() {
                let regex = /```mermaid([^`]*?)```/g;
                let matches = self.aiForm.aiCode.match(regex);
                self.aiForm.aiCode =
                  matches && matches[0]
                    ? matches[0].replace(/```mermaid|```/g, "").trim()
                    : self.aiForm.aiCode.trim();
                self.robotTyping = false;
              },
            }
          );
        } else {
          this.payDialog_isShow = true;
        }
      });
    },
    before_bumanyi() {
      this.right_tip ? this.right_tip.close() : "";
    },
    // 不满意生成
    bumanyi_shengcheng() {
      let msgg = this.$t("knowledge.knowledge_ai.message3");
      if (this.bumanyi_show) {
        if (this.bumanyi_form.b.length < 1) {
          this.$message.warning(msgg);
          return;
        }
        let content = "";

        if (this.languageType == "en-US") {
          if (this.bumanyi_form.a.length > 1) {
            content += `As a ${this.bumanyi_form.a},`;
          }
          if (this.bumanyi_form.b.length > 1) {
            content += `I hope to ${this.bumanyi_form.b} the above content`;
          }
          if (this.bumanyi_form.c.length > 1) {
            content += `,To achieve the goal of ${this.bumanyi_form.c}`;
          }
          if (this.bumanyi_form.d.length > 1) {
            content += `.In Addition, I Have Another Requirement: ${this.bumanyi_form.d}.`;
          }
        } else {
          if (this.bumanyi_form.a.length > 1) {
            content += `作为一名${this.bumanyi_form.a}，`;
          }
          if (this.bumanyi_form.b.length > 1) {
            content += `我希望对上述内容进行${this.bumanyi_form.b}`;
          }
          if (this.bumanyi_form.c.length > 1) {
            content += `，以达成${this.bumanyi_form.c}目的。`;
          }
          if (this.bumanyi_form.d.length > 1) {
            content += `除此之外，我还有一项要求：${this.bumanyi_form.d}`;
          }
        }

        let self = this;
        let lastRobotMessage = this.aiForm.aiCode;
        this.show_res = true;
        let controller = new AbortController();
        const signal = controller.signal;
        this.aiForm.aiCode = "";
        fetchEventSource(
          `${self.wsurl}${
            self.wsurl ? "" : "/prod-api"
          }/file-manage-service/${self.get_pid()}/aiRobot/optimize`,
          {
            method: "POST",
            signal: signal,
            headers: {
              "Content-Type": "application/json",
              Authorization: self.token,
              "Accept-Language": self.languageType,
            },
            body: JSON.stringify({
              sessionId: this.tab1Ulid,
              content: content,
              operate: "OPTIMIZE_TEXT_CONTENT",
              lastRobotMessage: lastRobotMessage,
            }),
            openWhenHidden: true,
            onmessage(event) {
              self.robotTyping = true;
              const data = JSON.parse(event.data);
              self.aiForm.aiCode += data.value.replaceAll(
                "\\n",
                `
  `
              );
              if (!self.bumanyi_show) {
                controller.abort();
                self.robotTyping = false;
              }
            },
            onerror(err) {
              self.robotTyping = false;
              throw err;
            },
            onclose() {
              self.robotTyping = false;
            },
          }
        );
      }
    },
    // 满意和不满意事件
    bumanyiClick() {
      if (this.right_tip) {
        this.right_tip.close();
      }
      this.ai_last_value = this.aiForm.aiCode;
      this.AIdialogVisible = false;
      this.bumanyi_form = {
        a: "",
        b: "",
        c: "",
        d: "",
      };
      this.show_res = false;
      if (!this.bumanyi_show) {
        this.bumanyi_show = true;
        this.bumanyi2_show = false;
      } else {
        this.bumanyi_show = true;
        this.bumanyi2_show = true;
      }
    },
    ai_markdown_last_value_change(a, b) {
      this.right_tip = this.$notify({
        title: this.$t("knowledge.knowledge_ai.title4"),
        dangerouslyUseHTMLString: true,
        message: b,
        showClose: false,
        customClass: "ai_res_content",
        duration: 0,
      });
    },
    manyiClick() {
      const dom = this.$refs.ai_adilog2;
      dom.select(); // 选取文本域内容;
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("Copy");
      this.$notify({
        title: this.$t("node.ai.alertTitle"),
        message: this.$t("node.ai.alertMessage"),
        duration: 0,
        customClass: "ai-mermaid-alert",
      });
      setTimeout(() => {
        this.AIdialogVisible = false;
        this.bumanyi_show = false;
        this.bumanyi2_show = false;
        this.before_bumanyi();
        this.add_draw();
      });
    },
    // 打开AI画架构图
    showAIdialog() {
      this.AIdialogVisible = true;
      this.aiForm = {
        aiText: this.node.topic,
        aiSelect: "",
        aiCode: "",
      };
    },
    show_msg() {
      this.$message({
        type: "warning",
        message: this.$t("addMindMap.message11"),
      });
    },
    // 添加高亮效果
    add_highlight() {
      this.$message({
        type: "warning",
        message: this.$t("nodeDetail.message.error"),
      });
      let highlight = document.querySelectorAll(".highlight_cascader");
      for (let i = 0; i < highlight.length; i++) {
        highlight[i].firstChild.classList.remove("high_light_cas");
        setTimeout(() => {
          highlight[i].firstChild.classList.add("high_light_cas");
        });
      }
    },
    async putObject(data) {
      try {
        const result = await this.client.put(
          `${this.$store.state.project.tenantId}/drawio/exampleobject.txt`,
          data
        );
      } catch (e) {
        console.log(e);
      }
    },
    up_base64(base64) {
      let type = "text/plain;";
      let file = new File([base64], "drawio", { type });
      let form = new FormData();
      form.append("attachment", file);
      upload_drawio(form).then((res) => {
        return res;
      });
    },
    get_base64(url) {
      // console.log(url);
      if (url) {
        download_drawio(url).then((res) => {
          const reader = new FileReader();
          reader.readAsText(res);
          reader.onload = function (e) {
            // console.log(e.target.result);
            return e.target.result;
          };
        });
      }
    },
    away() {
      this.id_edit = false;
      this.save_draw_change();
    },
    async save_draw_change(id) {
      ossAuthorization.call(this);
      this.isload = true;
      if (this.custom) {
        this.node = await get_node_use_node_key(this.get_pid(), this.nodeKey);
        let nodedata = JSON.parse(JSON.stringify(this.node));
        try {
          for (let i in nodedata.content) {
            if (nodedata.content[i].fieldReferenceId == this.custom) {
              let bd = [];
              for (let ele of this.basedata) {
                let type = "text/plain;";
                let myDate = Date.now().toString().slice(2, -1);
                let j = "draw" + myDate;
                let file = new File([ele.data], "drawio", { type });
                const res = await this.client.put(
                  `${this.$store.state.project.tenantId}/drawio/${j}`,
                  file
                );
                bd.push({ id: ele.id, name: ele.name, data: res.url });
              }
              const params = {
                projectId: this.get_pid(),
                nodeKey: nodedata.key,
                data: {
                  isCustom: true,
                  fieldId: nodedata.content[i].fieldId,
                  value: JSON.stringify(bd),
                },
              };
              only_set_property(params);
              this.isload = false;
              return false;
            }
          }
        } catch {
          this.$message.warning(this.$t("nodeDetail.message.error3"));
        }
      } else {
        this.node = await get_node_use_node_key(this.get_pid(), this.nodeKey);
        let nodedata = JSON.parse(JSON.stringify(this.node));
        let bd = [];
        try {
          for (let ele of this.basedata) {
            let type = "text/plain;";
            let myDate = Date.now().toString().slice(2, -1);
            let i = "draw" + myDate;
            let file = new File([ele.data], i, { type });
            const res = await this.client.put(
              `${this.$store.state.project.tenantId}/drawio/${i}`,
              file
            );
            bd.push({ id: ele.id, name: ele.name, data: res.url });
          }
          nodedata.architectureDiagrams = bd;
          const params = {
            projectId: this.get_pid(),
            nodeKey: nodedata.key,
            data: {
              isCustom: false,
              fieldId: "architecture_diagrams",
              value: bd,
            },
          };
          only_set_property(params);
          this.$emit("updateDrawio", bd);
        } catch {
          this.$message.warning(this.$t("nodeDetail.message.error3"));
        }

        this.isload = false;
      }
    },
    c(e) {
      this.current = e;
    },
    binddata(id, data) {
      for (let i in this.basedata) {
        if (this.basedata[i].id == id) {
          if (this.$route.path.indexOf("lineword") > 0) {
            let dataKey = document.getElementById(
              "word_view_drawio_node_key"
            ).innerText;
            if (dataKey == this.nodeKey) {
              document.getElementById("word_view_drawio_node_key").innerText =
                "";
              this.basedata[i].data = data;
              this.save_draw_change();
              return;
            }
          } else {
            this.basedata[i].data = data;
            this.save_draw_change();
            return;
          }
        }
      }
    },
    edit_draw(id) {
      document.querySelectorAll("#" + id).forEach((item) => {
        if (item.getAttribute("node-key") == this.nodeKey) {
          Drawio.editElement(item);
        }
      });

      if (this.$route.path.indexOf("lineword") > 0) {
        document.getElementById("word_view_drawio_node_key").innerText =
          this.nodeKey;
      }
    },
    zoom(i) {
      this.visiblelist = true;
      this.itemdata = this.basedata[i].data;
    },
    closeImgViewer() {
      this.visiblelist = false;
    },
    del_draw() {
      for (let i in this.basedata) {
        this.once[i] += 1;
        if (this.basedata[i].id == this.delId) {
          this.basedata.splice(i, 1);
          this.save_draw_change();
          this.delStructDia = false;
          return;
        }
      }
    },
    showDelDia(id) {
      this.delStructDia = true;
      this.delId = id;
    },
    add_draw() {
      this.once.push(0);
      let myDate =
        new Date().getTime().toString() +
        "random" +
        Math.ceil(Math.random() * 100).toString() +
        "user" +
        this.$store.getters.userAccountId;
      let i = "draw" + myDate;
      this.basedata.push({
        name: this.$t("nodeDetail.message.info"),
        data: "data:image/svg+xml;base64,",
        id: i,
      });
      this.$nextTick(() => {
        this.new_graph = i;
        this.edit_draw(i);
      });
    },
  },
};
</script>

<style lang="scss">
.ai_res_content {
  z-index: 999999 !important;
  top: 15vh !important;
  padding: 14px 14px 14px 13px !important;
  .el-notification__group {
    width: calc(100%);
    margin: 0 !important;
  }
  .el-notification__content {
    max-height: 400px;
    padding: 0 5px;
    overflow-y: auto;
    table {
      border-spacing: 0;
      border: 1px solid;
      td {
        border: 1px solid;
      }
      th {
        border: 1px solid;
      }
    }
  }
}
.ai-mermaid-alert {
  z-index: 1000000 !important;
}
.r2 {
  cursor: not-allowed !important;
  // pointer-events:
  // pointer-events:none !important;
}
.con {
  .el-carousel__container {
    height: 100%;
  }
}
</style>
<style lang="scss" scoped>
.mermaid-wrap {
  display: flex;
  margin-top: 20px;
  background-color: #eee;
  padding: 6px;
  & > div {
    width: 50%;
  }
}
.f {
  display: flex;
  align-items: center;
}
.block_title {
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  margin: 0;
  // margin-right: 25px;
}
.limitHeight {
  height: 406px !important; /*no*/
}
.con {
  width: 95%;
  height: 500px;
}
.box {
  width: 100%;
  height: 500px;
  border: 1px solid rgba(207, 207, 207, 1);
  border-radius: 6px 6px 6px 6px;

  .tool {
    img {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .svgimg {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .p {
      font-size: 16px;
      color: rgba(128, 128, 128, 1);
    }

    .line {
      height: 100%;
      width: 1px;
      background-color: rgba(204, 204, 204, 1);
    }

    height: 40px;
    width: 100%;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    background-color: rgba(230, 230, 230, 1);
    display: grid;
    grid-template-columns: 15% 1px 15% 1px 8% 1px 8% 1px 8% 1px auto;
    align-items: center;
    justify-items: center;
  }

  .img {
    width: 100%;
    height: 450px;
    padding: 10px;
    display: flex;
    justify-content: center;
    // align-items: center;
    overflow: auto;

    img {
      width: auto;
      height: auto;
      // max-width: 100%;
      // max-height: 100%;
    }
    .limitImgHeight {
      max-height: 90%;
    }
  }
}
.svgimg1 {
  width: 25px;
  height: 25px;
  // margin-top: -4px;
  cursor: pointer;
}
</style>

<style>
.viewer .el-image-viewer__canvas img {
  background-color: rgb(255, 255, 255);
  padding: 30px;
}
/* .el-image-viewer__wrapper {
  z-index: 999999999999999999999999999999 !important;
} */
</style>
