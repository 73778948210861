import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取ones节点关联
export function getIssueBindings(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/ones/issueBindings`,
        method: 'get'
    })
}
// 获取ones节点关联
export function addIssueBindings(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ones/issueBindings`,
        method: 'post',
        data: params.data
    })
}
// 获取ones节点关联
export function deleteIssueBindings(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ones/issueBindings/${params.bindingId}`,
        method: 'delete'
    })
}