<template>
  <span class="status_tag">
    <el-tooltip effect="dark" placement="top" :disabled="!isShowTooltip">
      <div slot="content">{{ selected }}</div>
      <el-select
        v-model="selected"
        :class="{
          status: true,
          status_small: size == 'small',
          status_todo: node_type == 'TODO',
          status_progress: node_type == 'IN_PROGRESS',
          status_open: node_type == 'DONE',
        }"
        placeholder=" "
        :loading="loading"
        @mouseenter.native="visibilityChange($event)"
        @visible-change="getWorkflowList($event)"
        @change="changeWorkflow($event)"
      >
        <el-option
          v-for="item in options"
          :key="item.value.name"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-tooltip>
  </span>
</template>

<script>
import { get_node_use_node_key } from "@/network/node/index.js";
import { mapGetters } from "vuex";
import {
  get_workflow_list,
  set_workflow,
  set_workflow_myself,
} from "@/network/node/index.js";
export default {
  props: {
    use: {
      type: Boolean,
      default: true,
    },
    //TODO、IN_PROGRESS、Done
    status_type: {
      type: String,
      default: "TODO",
    },
    size: {
      type: String,
      default: "default",
    },
    value: {
      type: String,
      default: "",
    },
    nodeKey: {
      type: String,
      default: "",
    },
    get_version_detail: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      isShowTooltip: false,
      options: [],
      selected: " ",
      node_status: {},
      node_info: {},
      node_type: "",
      workflows: [],
      config: {
        fileTypeId: null,
        layer: null,
        projectId: null,
      },
      workflows_list: {},
      preNode: "",
    };
  },
  computed: {
    ...mapGetters({ allstatus: "status" }),
  },
  watch: {
    selected: {
      handler(val, oldval) {
        this.preNode = oldval;
      },
    },
    value: {
      handler(val) {
        this.selected = val;
      },
    },
    status_type: {
      handler(val) {
        this.node_type = val;
      },
    },
  },
  mounted() {
    this.selected = this.value;
    this.node_type = this.status_type;
  },
  methods: {
    visibilityChange(event) {
      const ev = event.target.children[0].children[0];
      const ev_weight = ev.scrollWidth - 1; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight && this.use) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    getWorkflowList(event) {
      if (event) {
        this.get_node_data();
      }
    },
    async get_node_data() {
      const res = await get_node_use_node_key(this.get_pid(), this.nodeKey);
      this.node_info = res;
      this.node_status.statusId = res.status;
      this.node_status.statusId
        ? (this.node_status.name =
            this.allstatus[this.node_status.statusId].name)
        : "";
      Object.keys(this.config).forEach((name) => {
        this.config[name] = res[name];
      });
      await this.get_status();
    },
    async get_status() {
      let params = {
        projectId: this.config.projectId,
        fileTypeId: this.config.fileTypeId,
        layer: this.config.layer,
        currentStatusId: this.node_status.statusId,
      };
      const res = await get_workflow_list({ ...params });
      this.workflows = res.filter((item) => {
        return item.name != this.node_status.name;
      });
      this.options = [];
      this.workflows.forEach((item) => {
        const name = item.name;
        this.options.push({
          value: name,
          label: name,
        });
        this.workflows_list[name] = item;
      });
      this.loading = false;
      this.$emit("headCallBack", this.node_status.name);
    },
    changeWorkflow(event) {
      this.set_properties_value(this.workflows_list[event]);
    },
    set_properties_value(val) {
      let params = {
        nodeId: this.node_info.id,
        nodeUuid: this.node_info.nodeUuid,
        targetStatus: val.statusId,
      };

      set_workflow_myself(this.config.projectId, params).then(
        (res) => {
          if (res.length != 0) {
            // 此时有验证条件未通过
            // 对弹窗进行改错
            this.$message({
              showClose: true,
              message: res,
              duration: 0,
              type: "error",
            });
            if (this.node_type == "TODO") {
              this.selected = "To Do";
            } else {
              this.selected = this.node_type;
            }
          } else {
            // 验证条件通过
            set_workflow(this.config.projectId, params)
              .then((res) => {
                this.node_status = val;
                this.node_type = val.statusCategory;
                this.$emit("changeStatus", this.nodeKey, val);
                this.get_status();
                if (this.get_version_detail) {
                  this.get_version_detail();
                }
              })
              .catch((err) => {
                this.selected = this.preNode;
              });
          }
        },
        (error) => {
          if (this.node_type == "TODO") {
            this.selected = "To Do";
          } else {
            this.selected = this.node_type;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.status_tag {
  width: 80%;
  display: inline-block;
  overflow: hidden;
}
.status_todo {
  background-color: rgba(166, 166, 166, 1);
  border: 1px solid rgba(166, 166, 166, 1);
  color: white;
}
.status_open {
  color: white;
  background-color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_progress {
  color: white;
  background-color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
.status {
  // width: 135px;
  height: 32px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.status .el-input__inner {
  background: transparent;
  color: #fff;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
}

.status .el-input .el-select__caret {
  color: #fff;
  font-weight: 800;
}

.status .el-input__suffix {
  right: 0px;
}
.status_small {
  /* prettier-ignore */
  width: 60PX !important;
  /*no*/
  /* prettier-ignore */
  height: 18PX !important;
  /*no*/
  font-size: 12px !important;
  .el-input__suffix {
    transform: scale(0.4);
    /* prettier-ignore */
    right: -10PX;
    /*no*/
  }
  .el-input__inner {
    font-size: 10px;
    /* prettier-ignore */
    padding: 0 10PX 0 0 !important;
    /*no*/
  }
}
</style>
