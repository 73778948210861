<template>
  <div class="test" style="padding-bottom: 40px;">
    <el-table :data="tableData" border style="90vw" v-loading="isLoading">
      <el-table-column
        prop="testPlan.name"
        :label="$t('nodeDetail.publish.testPlan')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="route_to_testPlan(scope.row)">{{
            scope.row.testPlan.name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('test.nodeTable.status')"
        align="left"
        header-align="left"
      >
        <template slot-scope="scope">
          <div style="display: flex; justify-content: left">
            <el-tooltip
              effect="dark"
              placement="top"
              :disabled="!isShowTooltip"
            >
              <div slot="content">
                {{
                  execution_status[scope.row.status]
                    ? execution_status[scope.row.status].statusName
                    : ""
                }}
              </div>
              <div
                class="excecute_status"
                :style="
                  'background-color:' + (execution_status[scope.row.status]
                    ? execution_status[scope.row.status].color
                    : '')
                "
                @mouseenter="visibilityChange($event)"
              >
                <span style="word-break: keep-all !important">{{
                  execution_status[scope.row.status]
                    ? execution_status[scope.row.status].statusName
                    : ""
                }}</span>
              </div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        show-overflow-tooltip
        :label="$t('test.nodeTable.executor2')"
      >
      </el-table-column>
      <el-table-column
        prop="createdAt"
        :label="$t('test.nodeTable.executionTime')"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { get_test_case_status } from "@/network/test/index";
export default {
  components: {},
  inject: ["nodeKey"],
  data() {
    return {
      tableData: [],
      detailReviewInfo: {},
      isLoading: false,
      testNodesCache: {},
      isShowTooltip: false,
    };
  },
  computed: {
    ...mapGetters(["user_list", "testPlan", "execution_status", "status"]),
  },
  async mounted() {
    try {
      this.isLoading = true;
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
      };
      const res = await get_test_case_status(params);
      const updates = res.map(async (row) => {
        row.createdAt = new Date(row.createdAt).Format("yyyy-MM-dd hh:mm:ss");
        row.testPlan = this.testPlan[row.testPlanId];
        row.createdBy = this.getUser(row.createdBy).nickname;
        return row;
      });
      this.tableData = await Promise.all(updates);
      // console.log(this.tableData);
      // console.log(this.execution_status);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    getTheme(status) {
      return this.dict[status].color;
    },
    getStatusText(status) {
      return this.dict[status].text;
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    route_to_testPlan(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/home/release/test/${row.testPlanId}`,
      });
      window.open(href, "_blank");
    },
    getUser(id) {
      return this.matchUserInfo(id);
    },
  },
};
</script>
<style scoped lang="scss">
.excecute_status {
  // display: flex;
  max-width: 180px;
  height: 25px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  // background-color: blue;
  // align-items: center;
  // justify-content: center;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.pingshen-status-tag {
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(48, 100, 143, 1);
}
</style>
<style lang="scss">
.test {
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
}
.demo {
  color: rgba(79, 176, 118, 1);
}
</style>
