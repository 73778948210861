import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取w模型映射
export function getwmodelFileTypeRelation(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/wmodelFileTypeRelation`,
    method: "get",
    params: params.vmodelProcess,
  });
}
// 修改w模型映射
export function setwmodelFileTypeRelation(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/wmodelFileTypeRelation`,
    method: "put",
    data: params.data,
  });
}
// 获取指定节点在w模型中的关联分布
export function getWnode(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/wmodel/nodes/${params.nodeKey}`,
    method: "get",
    data: params.data,
  });
}
// 查询Wmodel图表功能是否需要购买弹窗
export function get_pay_wmodel(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/wmodel/payFuture`,
    method: "get",
  });
}
// 查询Wmodel图表功能是否过期
export function get_expired_wmodel() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/tenant/wmodel/payFuture/expired`,
    method: "get",
  });
}
// 查询覆盖率
export function getWmodelNode(projectId, chartId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/wmodelNodeCoverage?chartId=${chartId}`,
    method: 'post',
  })
}
