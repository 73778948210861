import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 添加评论
export function add_comment(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${projectId}/comments`,
    method: 'post',
    data: data
  })
}

// 根据nodeKey获取评论
export function get_comments(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${projectId}/comments/nodeKey/${nodeKey}/like`,
    method: 'get'
  })
}

// 点赞
export function like_comments(projectId, commentUuid) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${projectId}/comments/${commentUuid}/like`,
    method: 'post'
  })
}

// 取消点赞
export function dislike_comments(projectId, commentUuid) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${projectId}/comments/${commentUuid}/like`,
    method: 'delete'
  })
}
