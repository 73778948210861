import tinymce from "tinymce";
tinymce.addI18n("zh-Hans", {
    Redo: "\u91cd\u505a",
    Undo: "\u64a4\u9500",
    Cut: "\u526a\u5207",
    Copy: "\u590d\u5236",
    Paste: "\u7c98\u8d34",
    "Select all": "\u5168\u9009",
    "New document": "\u65b0\u5efa\u6587\u6863",
    Ok: "\u786e\u5b9a",
    Cancel: "\u53d6\u6d88",
    "Visual aids": "\u7f51\u683c\u7ebf",
    Bold: "\u7c97\u4f53",
    Italic: "\u659c\u4f53",
    Underline: "\u4e0b\u5212\u7ebf",
    Strikethrough: "\u5220\u9664\u7ebf",
    Superscript: "\u4e0a\u6807",
    Subscript: "\u4e0b\u6807",
    "Clear formatting": "\u6e05\u9664\u683c\u5f0f",
    Remove: "\u79fb\u9664",
    "Align left": "\u5de6\u5bf9\u9f50",
    "Align center": "\u5c45\u4e2d\u5bf9\u9f50",
    "Align right": "\u53f3\u5bf9\u9f50",
    "No alignment": "\u672a\u5bf9\u9f50",
    Justify: "\u4e24\u7aef\u5bf9\u9f50",
    "Bullet list": "\u65e0\u5e8f\u5217\u8868",
    "Numbered list": "\u6709\u5e8f\u5217\u8868",
    "Decrease indent": "\u51cf\u5c11\u7f29\u8fdb",
    "Increase indent": "\u589e\u52a0\u7f29\u8fdb",
    Close: "\u5173\u95ed",
    Formats: "\u683c\u5f0f",
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
        "\u4f60\u7684\u6d4f\u89c8\u5668\u4e0d\u652f\u6301\u6253\u5f00\u526a\u8d34\u677f\uff0c\u8bf7\u4f7f\u7528Ctrl+X/C/V\u7b49\u5feb\u6377\u952e\u3002",
    Headings: "\u6807\u9898",
    "Heading 1": "\u4e00\u7ea7\u6807\u9898",
    "Heading 2": "\u4e8c\u7ea7\u6807\u9898",
    "Heading 3": "\u4e09\u7ea7\u6807\u9898",
    "Heading 4": "\u56db\u7ea7\u6807\u9898",
    "Heading 5": "\u4e94\u7ea7\u6807\u9898",
    "Heading 6": "\u516d\u7ea7\u6807\u9898",
    Preformatted: "\u9884\u5148\u683c\u5f0f\u5316\u7684",
    Div: "Div",
    Pre: "\u524d\u8a00",
    Code: "\u4ee3\u7801",
    Paragraph: "\u6bb5\u843d",
    Blockquote: "\u5f15\u6587\u533a\u5757",
    Inline: "\u6587\u672c",
    Blocks: "\u6837\u5f0f",
    "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.":
        "\u5f53\u524d\u4e3a\u7eaf\u6587\u672c\u7c98\u8d34\u6a21\u5f0f\uff0c\u518d\u6b21\u70b9\u51fb\u53ef\u4ee5\u56de\u5230\u666e\u901a\u7c98\u8d34\u6a21\u5f0f\u3002",
    Fonts: "\u5b57\u4f53",
    "Font sizes": "\u5b57\u4f53\u5927\u5c0f",
    Class: "\u7c7b\u578b",
    "Browse for an image": "\u6d4f\u89c8\u56fe\u50cf",
    OR: "\u6216",
    "Drop an image here": "\u62d6\u653e\u4e00\u5f20\u56fe\u50cf\u81f3\u6b64",
    Upload: "\u4e0a\u4f20",
    "Uploading image": "\u4e0a\u4f20\u56fe\u7247",
    Block: "\u5757",
    Align: "\u5bf9\u9f50",
    Default: "\u9884\u8bbe",
    Circle: "\u7a7a\u5fc3\u5706",
    Disc: "\u5b9e\u5fc3\u5706",
    Square: "\u5b9e\u5fc3\u65b9\u5757",
    "Lower Alpha": "\u5c0f\u5199\u82f1\u6587\u5b57\u6bcd",
    "Lower Greek": "\u5c0f\u5199\u5e0c\u814a\u5b57\u6bcd",
    "Lower Roman": "\u5c0f\u5199\u7f57\u9a6c\u6570\u5b57",
    "Upper Alpha": "\u5927\u5199\u82f1\u6587\u5b57\u6bcd",
    "Upper Roman": "\u5927\u5199\u7f57\u9a6c\u6570\u5b57",
    "Anchor...": "\u951a\u70b9...",
    Anchor: "\u951a\u70b9",
    Name: "\u540d\u79f0",
    ID: "ID",
    "ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.":
        "ID\u5e94\u8be5\u4ee5\u82f1\u6587\u5b57\u6bcd\u5f00\u5934\uff0c\u540e\u9762\u53ea\u80fd\u6709\u82f1\u6587\u5b57\u6bcd\u3001\u6570\u5b57\u3001\u7834\u6298\u53f7\u3001\u70b9\u3001\u5192\u53f7\u6216\u4e0b\u5212\u7ebf\u3002",
    "You have unsaved changes are you sure you want to navigate away?":
        "\u4f60\u8fd8\u6709\u6587\u6863\u5c1a\u672a\u4fdd\u5b58\uff0c\u786e\u5b9a\u8981\u79bb\u5f00\uff1f",
    "Restore last draft": "\u6062\u590d\u4e0a\u6b21\u7684\u8349\u7a3f",
    "Special character...": "\u7279\u6b8a\u5b57\u7b26...",
    "Special Character": "\u7279\u6b8a\u5b57\u7b26",
    "Source code": "\u6e90\u4ee3\u7801",
    "Insert/Edit code sample":
        "\u63d2\u5165/\u7f16\u8f91\u4ee3\u7801\u793a\u4f8b",
    Language: "\u8bed\u8a00",
    "Code sample...": "\u793a\u4f8b\u4ee3\u7801...",
    "Left to right": "\u7531\u5de6\u5230\u53f3",
    "Right to left": "\u7531\u53f3\u5230\u5de6",
    Title: "\u6807\u9898",
    Fullscreen: "\u5168\u5c4f",
    Action: "\u52a8\u4f5c",
    Shortcut: "\u5feb\u6377\u65b9\u5f0f",
    Help: "\u5e2e\u52a9",
    Address: "\u5730\u5740",
    "Focus to menubar": "\u79fb\u52a8\u7126\u70b9\u5230\u83dc\u5355\u680f",
    "Focus to toolbar": "\u79fb\u52a8\u7126\u70b9\u5230\u5de5\u5177\u680f",
    "Focus to element path":
        "\u79fb\u52a8\u7126\u70b9\u5230\u5143\u7d20\u8def\u5f84",
    "Focus to contextual toolbar":
        "\u79fb\u52a8\u7126\u70b9\u5230\u4e0a\u4e0b\u6587\u83dc\u5355",
    "Insert link (if link plugin activated)":
        "\u63d2\u5165\u94fe\u63a5 (\u5982\u679c\u94fe\u63a5\u63d2\u4ef6\u5df2\u6fc0\u6d3b)",
    "Save (if save plugin activated)":
        "\u4fdd\u5b58(\u5982\u679c\u4fdd\u5b58\u63d2\u4ef6\u5df2\u6fc0\u6d3b)",
    "Find (if searchreplace plugin activated)":
        "\u67e5\u627e(\u5982\u679c\u67e5\u627e\u66ff\u6362\u63d2\u4ef6\u5df2\u6fc0\u6d3b)",
    "Plugins installed ({0}):": "\u5df2\u5b89\u88c5\u63d2\u4ef6 ({0}):",
    "Premium plugins:": "\u4f18\u79c0\u63d2\u4ef6\uff1a",
    "Learn more...": "\u4e86\u89e3\u66f4\u591a...",
    "You are using {0}": "\u4f60\u6b63\u5728\u4f7f\u7528 {0}",
    Plugins: "\u63d2\u4ef6",
    "Handy Shortcuts": "\u5feb\u6377\u952e",
    "Horizontal line": "\u6c34\u5e73\u5206\u5272\u7ebf",
    "Insert/edit image": "\u63d2\u5165/\u7f16\u8f91\u56fe\u7247",
    "Alternative description": "\u66ff\u4ee3\u63cf\u8ff0",
    Accessibility: "\u8f85\u52a9\u529f\u80fd",
    "Image is decorative": "\u56fe\u50cf\u662f\u88c5\u9970\u6027\u7684",
    Source: "\u6e90",
    Dimensions: "\u5c3a\u5bf8",
    "Constrain proportions": "\u4fdd\u6301\u6bd4\u4f8b",
    General: "\u4e00\u822c",
    Advanced: "\u9ad8\u7ea7",
    Style: "\u6837\u5f0f",
    "Vertical space": "\u5782\u76f4\u95f4\u8ddd",
    "Horizontal space": "\u6c34\u5e73\u95f4\u8ddd",
    Border: "\u6846\u7ebf",
    "Insert image": "\u63d2\u5165\u56fe\u7247",
    "Image...": "\u56fe\u7247...",
    "Image list": "\u56fe\u7247\u6e05\u5355",
    Resize: "\u8c03\u6574\u5927\u5c0f",
    "Insert date/time": "\u63d2\u5165\u65e5\u671f/\u65f6\u95f4",
    "Date/time": "\u65e5\u671f/\u65f6\u95f4",
    "Insert/edit link": "\u63d2\u5165/\u7f16\u8f91\u94fe\u63a5",
    "Text to display": "\u8981\u663e\u793a\u7684\u6587\u672c",
    Url: "\u5730\u5740",
    "Open link in...": "\u94fe\u63a5\u6253\u5f00\u4f4d\u7f6e...",
    "Current window": "\u5f53\u524d\u7a97\u53e3",
    None: "\u65e0",
    "New window": "\u65b0\u7a97\u53e3",
    "Open link": "\u6253\u5f00\u94fe\u63a5",
    "Remove link": "\u79fb\u9664\u94fe\u63a5",
    Anchors: "\u951a\u70b9",
    "Link...": "\u94fe\u63a5...",
    "Paste or type a link": "\u7c98\u8d34\u6216\u8f93\u5165\u94fe\u63a5",
    "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?":
        "\u4f60\u6240\u586b\u5199\u7684URL\u5730\u5740\u4e3a\u90ae\u4ef6\u5730\u5740\uff0c\u9700\u8981\u52a0\u4e0amailto: \u524d\u7f00\u5417\uff1f",
    "The URL you entered seems to be an external link. Do you want to add the required http:// prefix?":
        "\u4f60\u6240\u586b\u5199\u7684URL\u5730\u5740\u5c5e\u4e8e\u5916\u90e8\u94fe\u63a5\uff0c\u9700\u8981\u52a0\u4e0ahttp:// \u524d\u7f00\u5417\uff1f",
    "The URL you entered seems to be an external link. Do you want to add the required https:// prefix?":
        "\u60a8\u8f93\u5165\u7684 URL \u4f3c\u4e4e\u662f\u4e00\u4e2a\u5916\u90e8\u94fe\u63a5\u3002\u60a8\u60f3\u6dfb\u52a0\u6240\u9700\u7684 https:// \u524d\u7f00\u5417\uff1f",
    "Link list": "\u94fe\u63a5\u6e05\u5355",
    "Insert video": "\u63d2\u5165\u89c6\u9891",
    "Insert/edit video": "\u63d2\u5165/\u7f16\u8f91\u89c6\u9891",
    "Insert/edit media": "\u63d2\u5165/\u7f16\u8f91\u5a92\u4f53",
    "Alternative source": "\u955c\u50cf",
    "Alternative source URL": "\u66ff\u4ee3\u6765\u6e90\u7f51\u5740",
    "Media poster (Image URL)": "\u5c01\u9762(\u56fe\u7247\u5730\u5740)",
    "Paste your embed code below:":
        "\u5c06\u5185\u5d4c\u4ee3\u7801\u7c98\u8d34\u5728\u4e0b\u9762:",
    Embed: "\u5185\u5d4c",
    "Media...": "\u591a\u5a92\u4f53...",
    "Nonbreaking space": "\u4e0d\u95f4\u65ad\u7a7a\u683c",
    "Page break": "\u5206\u9875\u7b26",
    "Paste as text": "\u7c98\u8d34\u4e3a\u6587\u672c",
    Preview: "\u9884\u89c8",
    Print: "\u6253\u5370",
    "Print...": "\u6253\u5370...",
    Save: "\u4fdd\u5b58",
    Find: "\u5bfb\u627e",
    "Replace with": "\u66ff\u6362\u4e3a",
    Replace: "\u66ff\u6362",
    "Replace all": "\u66ff\u6362\u5168\u90e8",
    Previous: "\u4e0a\u4e00\u4e2a",
    Next: "\u4e0b\u4e00\u4e2a",
    "Find and Replace": "\u67e5\u627e\u548c\u66ff\u6362",
    "Find and replace...": "\u67e5\u627e\u5e76\u66ff\u6362...",
    "Could not find the specified string.":
        "\u672a\u627e\u5230\u641c\u7d22\u5185\u5bb9\u3002",
    "Match case": "\u5927\u5c0f\u5199\u5339\u914d",
    "Find whole words only": "\u5168\u5b57\u5339\u914d",
    "Find in selection": "\u5728\u9009\u533a\u4e2d\u67e5\u627e",
    "Insert table": "\u63d2\u5165\u8868\u683c",
    "Table properties": "\u8868\u683c\u5c5e\u6027",
    "Delete table": "\u5220\u9664\u8868\u683c",
    Cell: "\u5355\u5143\u683c",
    Row: "\u884c",
    Column: "\u680f\u76ee",
    "Cell properties": "\u5355\u5143\u683c\u5c5e\u6027",
    "Merge cells": "\u5408\u5e76\u5355\u5143\u683c",
    "Split cell": "\u62c6\u5206\u5355\u5143\u683c",
    "Insert row before": "\u5728\u4e0a\u65b9\u63d2\u5165\u884c",
    "Insert row after": "\u5728\u4e0b\u65b9\u63d2\u5165\u884c",
    "Delete row": "\u5220\u9664\u884c",
    "Row properties": "\u884c\u5c5e\u6027",
    "Cut row": "\u526a\u5207\u884c",
    "Cut column": "\u526a\u5207\u5217",
    "Copy row": "\u590d\u5236\u884c",
    "Copy column": "\u590d\u5236\u5217",
    "Paste row before": "\u7c98\u8d34\u884c\u5230\u4e0a\u65b9",
    "Paste column before": "\u7c98\u8d34\u6b64\u5217\u524d",
    "Paste row after": "\u7c98\u8d34\u884c\u5230\u4e0b\u65b9",
    "Paste column after": "\u7c98\u8d34\u540e\u9762\u7684\u5217",
    "Insert column before": "\u5728\u5de6\u4fa7\u63d2\u5165\u5217",
    "Insert column after": "\u5728\u53f3\u4fa7\u63d2\u5165\u5217",
    "Delete column": "\u5220\u9664\u5217",
    Cols: "\u5217",
    Rows: "\u884c\u6570",
    Width: "\u5bbd\u5ea6",
    Height: "\u9ad8\u5ea6",
    "Cell spacing": "\u5355\u5143\u683c\u5916\u95f4\u8ddd",
    "Cell padding": "\u5355\u5143\u683c\u5185\u8fb9\u8ddd",
    "Row clipboard actions": "\u884c\u526a\u8d34\u677f\u64cd\u4f5c",
    "Column clipboard actions": "\u5217\u526a\u8d34\u677f\u64cd\u4f5c",
    "Table styles": "\u8868\u683c\u6837\u5f0f",
    "Cell styles": "\u5355\u5143\u683c\u6837\u5f0f",
    "Column header": "\u5217\u6807\u9898",
    "Row header": "\u884c\u5934",
    "Table caption": "\u8868\u683c\u6807\u9898",
    Caption: "\u6807\u9898",
    "Show caption": "\u663e\u793a\u6807\u9898",
    Left: "\u5de6",
    Center: "\u5c45\u4e2d",
    Right: "\u53f3",
    "Cell type": "\u50a8\u5b58\u683c\u522b",
    Scope: "\u8303\u56f4",
    Alignment: "\u5bf9\u9f50",
    "Horizontal align": "\u6c34\u5e73\u5bf9\u9f50",
    "Vertical align": "\u5782\u76f4\u5bf9\u9f50",
    Top: "\u4e0a\u65b9\u5bf9\u9f50",
    Middle: "\u5c45\u4e2d\u5bf9\u9f50",
    Bottom: "\u4e0b\u65b9\u5bf9\u9f50",
    "Header cell": "\u8868\u5934\u5355\u5143\u683c",
    "Row group": "\u884c\u7ec4",
    "Column group": "\u5217\u7ec4",
    "Row type": "\u884c\u7c7b\u578b",
    Header: "\u8868\u5934",
    Body: "\u8868\u4f53",
    Footer: "\u8868\u5c3e",
    "Border color": "\u6846\u7ebf\u989c\u8272",
    Solid: "\u5b9e\u7ebf",
    Dotted: "\u865a\u7ebf",
    Dashed: "\u865a\u7ebf",
    Double: "\u53cc\u7cbe\u5ea6",
    Groove: "\u51f9\u69fd",
    Ridge: "\u6d77\u810a\u5ea7",
    Inset: "\u5d4c\u5165",
    Outset: "\u5916\u7f6e",
    Hidden: "\u9690\u85cf",
    "Insert template...": "\u63d2\u5165\u6a21\u677f...",
    Templates: "\u6a21\u677f",
    Template: "\u6a21\u677f",
    "Insert Template": "\u63d2\u5165\u6a21\u677f",
    "Text color": "\u6587\u672c\u989c\u8272",
    "Background color": "\u80cc\u666f\u989c\u8272",
    "Custom...": "\u81ea\u5b9a\u4e49......",
    "Custom color": "\u81ea\u5b9a\u4e49\u989c\u8272",
    "No color": "\u65e0",
    "Remove color": "\u79fb\u9664\u989c\u8272",
    "Show blocks": "\u663e\u793a\u533a\u5757\u8fb9\u6846",
    "Show invisible characters": "\u663e\u793a\u4e0d\u53ef\u89c1\u5b57\u7b26",
    "Word count": "\u5b57\u6570",
    Count: "\u8ba1\u6570",
    Document: "\u6587\u6863",
    Selection: "\u9009\u62e9",
    Words: "\u5355\u8bcd",
    "Words: {0}": "\u5b57\u6570\uff1a{0}",
    "{0} words": "{0} \u5b57",
    File: "\u6587\u4ef6",
    Edit: "\u7f16\u8f91",
    Insert: "\u63d2\u5165",
    View: "\u67e5\u770b",
    Format: "\u683c\u5f0f",
    Table: "\u8868\u683c",
    Tools: "\u5de5\u5177",
    "Powered by {0}": "\u7531{0}\u9a71\u52a8",
    "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help":
        "\u7f16\u8f91\u533a\u3002\u6309ALT-F9\u6253\u5f00\u83dc\u5355\uff0c\u6309ALT-F10\u6253\u5f00\u5de5\u5177\u680f\uff0c\u6309ALT-0\u67e5\u770b\u5e2e\u52a9",
    "Image title": "\u56fe\u7247\u6807\u9898",
    "Border width": "\u8fb9\u6846\u5bbd\u5ea6",
    "Border style": "\u8fb9\u6846\u6837\u5f0f",
    Error: "\u9519\u8bef",
    Warn: "\u8b66\u544a",
    Valid: "\u6709\u6548",
    "To open the popup, press Shift+Enter":
        "\u6309Shitf+Enter\u952e\u6253\u5f00\u5bf9\u8bdd\u6846",
    "Rich Text Area": "\u5bcc\u6587\u672c\u533a\u57df",
    "Rich Text Area. Press ALT-0 for help.":
        "\u7f16\u8f91\u533a\u3002\u6309Alt+0\u952e\u6253\u5f00\u5e2e\u52a9\u3002",
    "System Font": "\u7cfb\u7edf\u5b57\u4f53",
    "Failed to upload image: {0}": "\u56fe\u7247\u4e0a\u4f20\u5931\u8d25: {0}",
    "Failed to load plugin: {0} from url {1}":
        "\u63d2\u4ef6\u52a0\u8f7d\u5931\u8d25: {0} \u6765\u81ea\u94fe\u63a5 {1}",
    "Failed to load plugin url: {0}":
        "\u63d2\u4ef6\u52a0\u8f7d\u5931\u8d25 \u94fe\u63a5: {0}",
    "Failed to initialize plugin: {0}":
        "\u63d2\u4ef6\u521d\u59cb\u5316\u5931\u8d25: {0}",
    example: "\u793a\u4f8b",
    Search: "\u641c\u7d22",
    All: "\u5168\u90e8",
    Currency: "\u8d27\u5e01",
    Text: "\u6587\u5b57",
    Quotations: "\u5f15\u7528",
    Mathematical: "\u6570\u5b66",
    "Extended Latin": "\u62c9\u4e01\u8bed\u6269\u5145",
    Symbols: "\u7b26\u53f7",
    Arrows: "\u7bad\u5934",
    "User Defined": "\u81ea\u5b9a\u4e49",
    "dollar sign": "\u7f8e\u5143\u7b26\u53f7",
    "currency sign": "\u8d27\u5e01\u7b26\u53f7",
    "euro-currency sign": "\u6b27\u5143\u7b26\u53f7",
    "colon sign": "\u5192\u53f7",
    "cruzeiro sign": "\u514b\u9c81\u8d5b\u7f57\u5e01\u7b26\u53f7",
    "french franc sign": "\u6cd5\u90ce\u7b26\u53f7",
    "lira sign": "\u91cc\u62c9\u7b26\u53f7",
    "mill sign": "\u5bc6\u5c14\u7b26\u53f7",
    "naira sign": "\u5948\u62c9\u7b26\u53f7",
    "peseta sign": "\u6bd4\u585e\u5854\u7b26\u53f7",
    "rupee sign": "\u5362\u6bd4\u7b26\u53f7",
    "won sign": "\u97e9\u5143\u7b26\u53f7",
    "new sheqel sign": "\u65b0\u8c22\u514b\u5c14\u7b26\u53f7",
    "dong sign": "\u8d8a\u5357\u76fe\u7b26\u53f7",
    "kip sign": "\u8001\u631d\u57fa\u666e\u7b26\u53f7",
    "tugrik sign": "\u56fe\u683c\u91cc\u514b\u7b26\u53f7",
    "drachma sign": "\u5fb7\u62c9\u514b\u9a6c\u7b26\u53f7",
    "german penny symbol": "\u5fb7\u56fd\u4fbf\u58eb\u7b26\u53f7",
    "peso sign": "\u6bd4\u7d22\u7b26\u53f7",
    "guarani sign": "\u74dc\u62c9\u5c3c\u7b26\u53f7",
    "austral sign": "\u6fb3\u5143\u7b26\u53f7",
    "hryvnia sign": "\u683c\u91cc\u592b\u5c3c\u4e9a\u7b26\u53f7",
    "cedi sign": "\u585e\u5730\u7b26\u53f7",
    "livre tournois sign": "\u91cc\u5f17\u5f17\u5c14\u7b26\u53f7",
    "spesmilo sign": "spesmilo\u7b26\u53f7",
    "tenge sign": "\u575a\u6208\u7b26\u53f7",
    "indian rupee sign": "\u5370\u5ea6\u5362\u6bd4",
    "turkish lira sign": "\u571f\u8033\u5176\u91cc\u62c9",
    "nordic mark sign": "\u5317\u6b27\u9a6c\u514b",
    "manat sign": "\u9a6c\u7eb3\u7279\u7b26\u53f7",
    "ruble sign": "\u5362\u5e03\u7b26\u53f7",
    "yen character": "\u65e5\u5143\u5b57\u6837",
    "yuan character": "\u4eba\u6c11\u5e01\u5143\u5b57\u6837",
    "yuan character, in hong kong and taiwan":
        "\u5143\u5b57\u6837\uff08\u6e2f\u53f0\u5730\u533a\uff09",
    "yen/yuan character variant one":
        "\u5143\u5b57\u6837\uff08\u5927\u5199\uff09",
    Emojis: "Emojis",
    "Emojis...": "Emojis...",
    "Loading emojis...": "\u6b63\u5728\u52a0\u8f7dEmojis...",
    "Could not load emojis": "\u65e0\u6cd5\u52a0\u8f7dEmojis",
    People: "\u4eba\u7c7b",
    "Animals and Nature": "\u52a8\u7269\u548c\u81ea\u7136",
    "Food and Drink": "\u98df\u7269\u548c\u996e\u54c1",
    Activity: "\u6d3b\u52a8",
    "Travel and Places": "\u65c5\u6e38\u548c\u5730\u70b9",
    Objects: "\u7269\u4ef6",
    Flags: "\u65d7\u5e1c",
    Characters: "\u5b57\u7b26",
    "Characters (no spaces)": "\u5b57\u7b26(\u65e0\u7a7a\u683c)",
    "{0} characters": "{0} \u4e2a\u5b57\u7b26",
    "Error: Form submit field collision.":
        "\u9519\u8bef: \u8868\u5355\u63d0\u4ea4\u5b57\u6bb5\u51b2\u7a81\u3002",
    "Error: No form element found.":
        "\u9519\u8bef: \u6ca1\u6709\u8868\u5355\u63a7\u4ef6\u3002",
    "Color swatch": "\u989c\u8272\u6837\u672c",
    "Color Picker": "\u9009\u8272\u5668",
    "Invalid hex color code: {0}":
        "\u5341\u516d\u8fdb\u5236\u989c\u8272\u4ee3\u7801\u65e0\u6548\uff1a {0}",
    "Invalid input": "\u65e0\u6548\u8f93\u5165",
    R: "R",
    "Red component": "\u7ea2\u8272\u90e8\u5206",
    G: "G",
    "Green component": "\u7eff\u8272\u90e8\u5206",
    B: "B",
    "Blue component": "\u767d\u8272\u90e8\u5206",
    "#": "#",
    "Hex color code": "\u5341\u516d\u8fdb\u5236\u989c\u8272\u4ee3\u7801",
    "Range 0 to 255": "\u8303\u56f40\u81f3255",
    Turquoise: "\u9752\u7eff\u8272",
    Green: "\u7eff\u8272",
    Blue: "\u84dd\u8272",
    Purple: "\u7d2b\u8272",
    "Navy Blue": "\u6d77\u519b\u84dd",
    "Dark Turquoise": "\u6df1\u84dd\u7eff\u8272",
    "Dark Green": "\u6df1\u7eff\u8272",
    "Medium Blue": "\u4e2d\u84dd\u8272",
    "Medium Purple": "\u4e2d\u7d2b\u8272",
    "Midnight Blue": "\u6df1\u84dd\u8272",
    Yellow: "\u9ec4\u8272",
    Orange: "\u6a59\u8272",
    Red: "\u7ea2\u8272",
    "Light Gray": "\u6d45\u7070\u8272",
    Gray: "\u7070\u8272",
    "Dark Yellow": "\u6697\u9ec4\u8272",
    "Dark Orange": "\u6df1\u6a59\u8272",
    "Dark Red": "\u6df1\u7ea2\u8272",
    "Medium Gray": "\u4e2d\u7070\u8272",
    "Dark Gray": "\u6df1\u7070\u8272",
    "Light Green": "\u6d45\u7eff\u8272",
    "Light Yellow": "\u6d45\u9ec4\u8272",
    "Light Red": "\u6d45\u7ea2\u8272",
    "Light Purple": "\u6d45\u7d2b\u8272",
    "Light Blue": "\u6d45\u84dd\u8272",
    "Dark Purple": "\u6df1\u7d2b\u8272",
    "Dark Blue": "\u6df1\u84dd\u8272",
    Black: "\u9ed1\u8272",
    White: "\u767d\u8272",
    "Switch to or from fullscreen mode": "\u5207\u6362\u5168\u5c4f\u6a21\u5f0f",
    "Open help dialog": "\u6253\u5f00\u5e2e\u52a9\u5bf9\u8bdd\u6846",
    history: "\u5386\u53f2",
    styles: "\u6837\u5f0f",
    formatting: "\u683c\u5f0f\u5316",
    alignment: "\u5bf9\u9f50",
    indentation: "\u7f29\u8fdb",
    Font: "\u5b57\u4f53",
    Size: "\u5b57\u53f7",
    "More...": "\u66f4\u591a...",
    "Select...": "\u9009\u62e9...",
    Preferences: "\u9996\u9009\u9879",
    Yes: "\u662f",
    No: "\u5426",
    "Keyboard Navigation": "\u952e\u76d8\u6307\u5f15",
    Version: "\u7248\u672c",
    "Code view": "\u4ee3\u7801\u89c6\u56fe",
    "Open popup menu for split buttons":
        "\u6253\u5f00\u5f39\u51fa\u5f0f\u83dc\u5355\uff0c\u7528\u4e8e\u62c6\u5206\u6309\u94ae",
    "List Properties": "\u5217\u8868\u5c5e\u6027",
    "List properties...": "\u6807\u9898\u5b57\u4f53\u5c5e\u6027",
    "Start list at number": "\u4ee5\u6570\u5b57\u5f00\u59cb\u5217\u8868",
    "Line height": "\u884c\u9ad8",
    "Dropped file type is not supported":
        "\u6b64\u6587\u4ef6\u7c7b\u578b\u4e0d\u652f\u6301\u62d6\u653e",
    "Loading...": "\u52a0\u8f7d\u4e2d...",
    "ImageProxy HTTP error: Rejected request":
        "\u56fe\u7247\u4ee3\u7406\u8bf7\u6c42\u9519\u8bef\uff1a\u8bf7\u6c42\u88ab\u62d2\u7edd",
    "ImageProxy HTTP error: Could not find Image Proxy":
        "\u56fe\u7247\u4ee3\u7406\u8bf7\u6c42\u9519\u8bef\uff1a\u65e0\u6cd5\u627e\u5230\u56fe\u7247\u4ee3\u7406",
    "ImageProxy HTTP error: Incorrect Image Proxy URL":
        "\u56fe\u7247\u4ee3\u7406\u8bf7\u6c42\u9519\u8bef\uff1a\u56fe\u7247\u4ee3\u7406\u5730\u5740\u9519\u8bef",
    "ImageProxy HTTP error: Unknown ImageProxy error":
        "\u56fe\u7247\u4ee3\u7406\u8bf7\u6c42\u9519\u8bef\uff1a\u672a\u77e5\u7684\u56fe\u7247\u4ee3\u7406\u9519\u8bef",
});
