import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
export function get_file_review(projectId, fileId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/NodeReview/fileId/${fileId}`,
    method: "get",
  });
}

export function get_user_review(projectId, fileId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodeReview/fileId/${fileId}/permitOperationNodes`,
    method: "get",
  });
}

export function get_file_process(projectId, fileId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodeReviewRecord/fileId/${fileId}/nodes`,
    method: "get",
  });
}

export function get_review_config(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/reviewConfigs`,
    method: "get",
  });
}

export function inviteReview(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/inviteReview`,
    method: "post",
    data,
  });
}

export function processReview(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ReviewProcess`,
    method: "post",
    data,
  });
}

// 查询文件是否存在
export function get_file_exist(projectId, fileId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileId}/fetch`,
    method: "get",
  });
}