<template>
  <div class="bg">
    <collapse :title="$t('nodeDetail.basicInfo.title')">
      <el-row>
        <el-col>
          <div class="field-info">
            <div key="assignee" class="field-info__item">
              <div class="field-info__item__label" @click.prevent="item_blur">
                <span>{{ $t("nodeDetail.basicInfo.responsibility") }}</span>
              </div>
              <div
                v-if="!assignee_edit"
                v-limits-of-authority="'NODE_EDIT'"
                class="not_edit"
                @click.stop="edit_assignee"
              >
                {{ assigneeName }}
              </div>
              <div v-else class="is_edit">
                <el-select
                  v-model="assignee"
                  popper-class="user_select"
                  :loading-text="$t('loading.loading2')"
                  :loading="user_loading"
                  filterable
                  clearable
                  @change="assignee_change"
                  :filter-method="filterMethod"
                  @visible-change="singleUserVisibleChange"
                >
                  <el-option
                    v-for="opt in filterUserList"
                    v-show="!opt.deleted && !opt.hide"
                    :key="opt.accountId"
                    :label="opt.nickname"
                    :value="opt.accountId"
                  >
                    <personal-avatar
                      class="avatar"
                      :avatar="opt.avatar"
                      :colorid="opt.accountId"
                      :nickname="opt.nickname"
                      :size="20"
                    >
                    </personal-avatar>
                    <span class="select_item">{{ opt.nickname }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>

            <div
              v-for="(property, index) in propertiesList"
              :key="property.fieldId"
              class="field-info__item"
              :class="{ text_area: property.fieldType === 'TEXTAREA' }"
            >
              <div class="field-info__item__label" @click.prevent="item_blur">
                <i
                  v-if="property.required"
                  style="color: red; vertical-align: top"
                  >*</i
                >
                <el-tooltip
                  v-ak-tooltip-auto-show
                  effect="dark"
                  :content="property.name"
                  placement="top"
                >
                  <span class="tooltip_span">{{ property.name }}</span>
                </el-tooltip>
              </div>
              <div
                v-if="!edit_list[index]"
                v-limits-of-authority="'NODE_EDIT'"
                class="not_edit"
                :class="{
                  disable_edit: nodeInfo.lock,
                  show_two_line:
                    property.fieldType === 'MULTI_USER_SELECT' ||
                    property.fieldType === 'SELECT_MULTI' ||
                    property.fieldType === 'VERSION',
                  show_one_line:
                    property.fieldType === 'TEXT' ||
                    property.fieldType === 'LINK',
                }"
                @click.stop="nodeInfo.lock ? add_highlight() : edit_item(index)"
                @mouseenter="
                  tool_tip_ishow(
                    $event,
                    property,
                    index,
                    show_label_build(
                      property.value,
                      property.fieldType,
                      property.extraData
                    ) + ''
                  )
                "
              >
                <el-tooltip
                  :disabled="!show_tips[index]"
                  effect="dark"
                  :content="
                    show_label_build(
                      property.value,
                      property.fieldType,
                      property.extraData
                    ) + ''
                  "
                  placement="top"
                  style="word-break: break-all; max-width: 1300px"
                >
                  <span>
                    <span v-if="property.fieldType != 'TEXTAREA'">
                      {{
                        show_label_build(
                          property.value,
                          property.fieldType,
                          property.extraData
                        )
                      }}
                    </span>
                    <span v-else>
                      <div
                        v-for="(item, index) in setMultiline(
                          show_label_build(
                            property.value,
                            property.fieldType,
                            property.extraData
                          )
                        )"
                        :key="index"
                      >
                        {{ item }}
                      </div>
                    </span>
                    <span
                      v-if="property.fieldType === 'NUMBER' && property.value"
                      style="margin-left: 6px"
                      >{{ property.unit }}</span
                    >
                  </span>
                </el-tooltip>
              </div>
              <div v-else class="is_edit">
                <el-input
                  v-if="
                    property.fieldType == 'TEXT' || property.fieldType == 'LINK'
                  "
                  v-model="property.value"
                  @change="
                    (val) => {
                      set_property_value(val, true, property.fieldId);
                    }
                  "
                >
                </el-input>

                <el-input
                  v-if="property.fieldType == 'NUMBER'"
                  v-model.number="property.value"
                  type="number"
                  :placeholder="$t('placeholder.number')"
                  @change="
                    (val) => {
                      set_property_value(val, true, property.fieldId);
                    }
                  "
                >
                </el-input>

                <el-input
                  v-if="property.fieldType == 'TEXTAREA'"
                  v-model="property.value"
                  type="textarea"
                  autosize
                  :placeholder="$t('placeholder.input')"
                  @change="
                    (val) => {
                      set_property_value(val, true, property.fieldId);
                    }
                  "
                >
                </el-input>

                <el-date-picker
                  v-if="property.fieldType == 'DATE'"
                  v-model="property.value"
                  value-format="yyyy-MM-dd"
                  type="date"
                  :placeholder="$t('placeholder.date')"
                  @change="
                    (val) => {
                      set_property_value(val, true, property.fieldId);
                    }
                  "
                >
                </el-date-picker>

                <el-select
                  v-if="property.fieldType == 'SELECT'"
                  v-model="property.value"
                  filterable
                  clearable
                  @change="
                    (val) => {
                      set_property_value(val, true, property.fieldId);
                    }
                  "
                >
                  <el-option
                    v-for="opt in property.extraData"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                  >
                  </el-option>
                </el-select>

                <el-select
                  v-if="
                    property.fieldType == 'SELECT_MULTI' ||
                    property.fieldType == 'VERSION'
                  "
                  v-model="property.value"
                  :placeholder="$t('placeholder.dropdown')"
                  multiple
                  filterable
                  @change="
                    (val) => {
                      set_property_value(val, true, property.fieldId);
                    }
                  "
                >
                  <el-option
                    v-for="opt in property.extraData"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                  >
                  </el-option>
                </el-select>

                <!-- 单用户下拉框 -->
                <el-select
                  v-if="property.fieldType == 'SINGLE_USER_SELECT'"
                  v-model="property.value"
                  popper-class="user_select"
                  :loading-text="$t('loading.loading2')"
                  :loading="user_loading"
                  :filter-method="filterMethod"
                  @visible-change="singleUserVisibleChange"
                  filterable
                  clearable
                  @change="
                    (val) => {
                      set_property_value(val, true, property.fieldId);
                    }
                  "
                >
                  <el-option
                    v-for="opt in filterUserList"
                    v-show="!opt.deleted && !opt.hide"
                    :key="opt.accountId"
                    :label="opt.nickname"
                    :value="opt.accountId"
                  >
                    <personal-avatar
                      class="avatar"
                      :avatar="opt.avatar"
                      :colorid="opt.accountId"
                      :nickname="opt.nickname"
                      :size="20"
                    >
                    </personal-avatar>
                    <span class="select_item">{{ opt.nickname }}</span>
                  </el-option>
                </el-select>

                <!-- 多用户下拉框 -->
                <el-select
                  v-if="property.fieldType == 'MULTI_USER_SELECT'"
                  v-model="property.value"
                  popper-class="user_select"
                  multiple
                  filterable
                  :loading="user_loading"
                  :filter-method="groupandUserfilterMethod"
                  @visible-change="mutUserVisibleChange"
                  :loading-text="$t('loading.loading2')"
                  clearable
                  @change="
                    (val) => {
                      setMultiUser(val, true, property.fieldId);
                    }
                  "
                >
                  <el-option-group
                    v-for="(group, index) in filterGroupandUser"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="user in group.options"
                      :key="user.value"
                      :label="user.label"
                      :value="user.value + index"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>

                <!-- 可创建条目 -->
                <el-select
                  v-if="property.fieldType == 'LABEL'"
                  v-model="property.value"
                  :placeholder="$t('placeholder.dropdown')"
                  :loading-text="$t('loading.loading2')"
                  multiple
                  allow-create
                  filterable
                  @change="
                    (val) => {
                      set_property_value(val, true, property.fieldId);
                    }
                  "
                  @visible-change="get_Label_data"
                >
                  <el-option
                    v-for="opt in project_labels"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-for="(obj, index) in drawio_list" :key="index">
        <el-col>
          <div key="assignee" class="field-info__item">
            <div class="field-info__item__label">
              <i v-if="obj.required" style="color: red; vertical-align: top"
                >*</i
              >
              <span>{{ obj.label }}</span>
            </div>
            <div
              v-limits-of-authority="'NODE_EDIT'"
              class="field-info__item__label"
              style="width: 100%; margin-top: 5px"
            >
              <Drawio
                :key="index"
                :node-key="$route.params.nodeKey || nodeInfo.key"
                :custom="obj.fieldReferenceId"
                :customvalue="JSON.stringify(obj.value)"
                class="draw"
              ></Drawio>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <div class="field-info">
            <div class="field-info__item">
              <div class="field-info__item__label" @click.prevent="item_blur">
                <span>{{ $t("nodeDetail.basicInfo.storyPoint") }}</span>
              </div>
              <div
                v-if="!storypoint_edit"
                v-limits-of-authority="'NODE_EDIT'"
                class="not_edit"
                :class="{ disable_edit: nodeInfo.lock }"
                @click.stop="edit_storypoint()"
              >
                {{ storyPoint === 0 ? "" : storyPoint }}
              </div>
              <div v-else class="is_edit">
                <el-input
                  v-model="storyPoint"
                  :placeholder="$t('placeholder.input')"
                  type="number"
                  min="0"
                  step="1"
                  size="mini"
                  @change="storypoint_change"
                ></el-input>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <div key=" assignee" class="field-info__item">
            <div class="field-info__item__label">
              <!-- <i style="color: red; vertical-align: top">*</i> -->
              <span>{{ $t("nodeDetail.basicInfo.structureDiagram") }}</span>
            </div>
            <div
              v-limits-of-authority="'NODE_EDIT'"
              class="field-info__item__label"
              style="width: 100%; margin-top: 5px; cursor: defult"
            >
              <Drawio
                :node-key="$route.params.nodeKey || nodeInfo.key"
                :iscustom="false"
                class="draw"
              ></Drawio>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="eolink_show && hasEoLink">
        <el-col>
          <div key="assignee" class="field-info__item">
            <div
              class="field-info__item__label basicInfo_api"
              @click.prevent="item_blur"
            >
              <span>{{ $t("nodeDetail.basicInfo.api") }} </span>
              <span style="vertical-align: top">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('nodeDetail.basicInfo.warn')"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </div>
            <div
              v-if="!api_edit"
              v-limits-of-authority="'NODE_EDIT'"
              class="not_edit api_edit"
              @click.stop="edit_api()"
            >
              <div
                v-for="(item, index) in api_list"
                :key="index"
                class="api_wrap"
              >
                <label
                  class="api_name"
                  style="cursor: pointer"
                  @click.stop="jump_eolink(item.webUrl)"
                >
                  {{ item.api_name }}
                </label>
                <span class="api_status">{{
                  api_status_map[item.api_status]
                }}</span>
              </div>
            </div>
            <div v-else>
              <div
                v-for="(item, index) in api_list"
                :key="index"
                style="
                  margin-bottom: 10px;
                  display: flex;
                  min-height: 30px;
                  line-height: 30px;
                  font-size: 16px;
                  align-items: center;
                "
              >
                <label class="api_name"> {{ item.api_name }} </label>
                <i
                  class="el-icon-remove-outline"
                  style="font-size: 24px; color: red; cursor: pointer"
                  @click.stop="delete_api(index)"
                ></i>
              </div>
              <div v-if="api_list.length < 50" style="display: flex">
                <i
                  class="iconfont icon-add"
                  style="
                    font-size: 20px;
                    margin-top: 7px;
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                  "
                  @click.stop="add_api"
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="hasJira">
        <el-col>
          <div key="assignee" class="field-info__item">
            <div
              class="field-info__item__label basicInfo_jira"
              @click.prevent="item_blur"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('nodeDetail.basicInfo.jira')"
                placement="top-start"
              >
                <span>{{ $t("nodeDetail.basicInfo.jira") }}</span>
              </el-tooltip>
              <span style="vertical-align: top">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('nodeDetail.basicInfo.warnjira')"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </div>

            <div
              v-if="!issue_edit"
              v-limits-of-authority="'NODE_EDIT'"
              class="not_edit issue_edit"
              @click.stop="edit_issue()"
            >
              <div
                v-for="(item, index) in issue_list"
                :key="index"
                style="display: flex"
              >
                <label
                  class="issue_key"
                  style="cursor: pointer"
                  @click.stop="jump_jira(item.url)"
                >
                  {{ item.key }}
                </label>
                <span
                  class="issue_name"
                  style="cursor: pointer"
                  :title="item.fields.summary"
                  @click.stop="jump_jira(item.url)"
                >
                  {{ item.fields.summary }}
                </span>
                <span
                  class="issue_status"
                  :class="{
                    issue_status: true,
                    todo:
                      item.fields.status.statusCategory.colorName ===
                      'blue-gray',
                    progress:
                      item.fields.status.statusCategory.colorName === 'yellow',
                    done:
                      item.fields.status.statusCategory.colorName === 'green',
                  }"
                  >{{ item.fields.status.name }}</span
                >
                <!-- issue_priority -->
                <span class="issue_priority">
                  <img
                    class="priority_icon"
                    :src="item.fields.priority.iconUrl"
                  />
                  <span
                    style="
                      width: 120px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :title="item.fields.priority.name"
                    >{{ item.fields.priority.name }}</span
                  >
                </span>
              </div>
            </div>

            <div v-else>
              <div
                v-for="(item, index) in issue_list"
                :key="index"
                style="
                  margin-bottom: 10px;
                  display: flex;
                  min-height: 30px;
                  line-height: 30px;
                  font-size: 16px;
                  align-items: center;
                "
              >
                <label class="issue_key"> {{ item.key }} </label>
                <label class="issue_name_edit">
                  {{ item.fields.summary }}
                </label>
                <i
                  class="el-icon-remove-outline"
                  style="font-size: 24px; color: red; cursor: pointer"
                  @click.stop="delete_issue(index)"
                ></i>
              </div>
              <div v-if="issue_list.length < 50" style="display: flex">
                <i
                  class="iconfont icon-add"
                  style="
                    font-size: 20px;
                    margin-top: 7px;
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                  "
                  @click.stop="add_issue"
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="hasOnes && hasOnesConfig">
        <el-col>
          <div key="assignee" class="field-info__item">
            <div
              class="field-info__item__label basicInfo_jira"
              @click.prevent="item_blur"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('nodeDetail.basicInfo.ones')"
                placement="top-start"
              >
                <span>{{ $t("nodeDetail.basicInfo.ones") }}</span>
              </el-tooltip>
              <span style="vertical-align: top">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('nodeDetail.basicInfo.warnones')"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </div>

            <div
              v-if="!ones_issue_edit"
              v-limits-of-authority="'NODE_EDIT'"
              class="not_edit issue_edit"
              @click.stop="edit_ones_issue()"
            >
              <div
                v-for="(item, index) in ones_issue_list"
                :key="index"
                style="display: flex"
              >
                <label
                  class="issue_key"
                  style="cursor: pointer"
                  @click.stop="jump_ones(item.url)"
                >
                  #{{ item.number }}
                </label>
                <span
                  class="issue_name"
                  style="cursor: pointer"
                  :title="item.title"
                  @click.stop="jump_ones(item.url)"
                >
                  {{ item.title }}
                </span>
                <span
                  class="issue_status"
                  :class="{
                    issue_status: true,
                    todo: item.status.category === 'to_do',
                    progress: item.status.category === 'in_progress',
                    done: item.status.category === 'done',
                  }"
                  >{{ item.status.name }}</span
                >
              </div>
            </div>

            <div v-else>
              <div
                v-for="(item, index) in ones_issue_list"
                :key="index"
                style="
                  margin-bottom: 10px;
                  display: flex;
                  min-height: 30px;
                  line-height: 30px;
                  font-size: 16px;
                  align-items: center;
                "
              >
                <label class="issue_key"> #{{ item.number }} </label>
                <label class="issue_name_edit">
                  {{ item.title }}
                </label>
                <i
                  class="el-icon-remove-outline"
                  style="font-size: 24px; color: red; cursor: pointer"
                  @click.stop="delete_ones_issue(index)"
                ></i>
              </div>
              <div v-if="issue_list.length < 50" style="display: flex">
                <i
                  class="iconfont icon-add"
                  style="
                    font-size: 20px;
                    margin-top: 7px;
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                  "
                  @click.stop="add_ones_issue"
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </collapse>
    <el-dialog
      :title="$t('nodeDetail.basicInfo.add')"
      :visible.sync="add_api_visible"
      :close-on-click-modal="false"
      :show-close="true"
      :append-to-body="true"
      width="25%"
    >
      <el-input v-model="doc_api_url" size="small"> </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="add_api_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_api_confirm">{{
          $t("btn.newBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('nodeDetail.basicInfo.addOnesIssue')"
      :visible.sync="add_ones_issue_visible"
      :close-on-click-modal="false"
      :show-close="true"
      v-loading="addIssueLoading"
      element-loading-spinner="el-icon-loading"
      :element-loading-text="$t('loading.loading1')"
      element-loading-background="rgba(0, 0, 0, 0.5)"
      :append-to-body="true"
      width="25%"
    >
      <el-input v-model="ones_issue_url" size="small"> </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="add_ones_issue_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_ones_issue_confirm">{{
          $t("btn.newBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('nodeDetail.basicInfo.addIssue')"
      :visible.sync="add_issue_visible"
      :close-on-click-modal="false"
      :show-close="true"
      :append-to-body="true"
      width="25%"
      v-loading="addIssueLoading"
      element-loading-spinner="el-icon-loading"
      :element-loading-text="$t('loading.loading1')"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <el-input v-model="doc_issue_url" size="small"> </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="add_issue_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_issue_confirm">{{
          $t("btn.newBtn")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  only_set_property,
  multiLayers_change_assignee,
  get_Labels,
} from "@/network/node/index.js";
import {
  addJiraIssue,
  deleteJiraIssue,
  getJiraIssue,
} from "@/network/jira/index";
import {
  getIssueBindings,
  addIssueBindings,
  deleteIssueBindings,
} from "@/network/ones/index";
import { getOnesConfigs } from "@/network/gitlab";
import { get_group_user_list } from "@/network/addGroup/index.js";
import Collapse from "./Collapse.vue";
import PersonalAvatar from "@/components/personal";
import {
  getapiDocs,
  addapiDocs,
  deleteapiDocs,
} from "@/network/eolink/index.js";
import { get_filetype_detail } from "@/network/fileType/index.js";
import { geteolinkConfig } from "@/network/eolink/index.js";
import Drawio from "@/components/mindmap/node_detail/Drawio.vue";
import { pinyin } from "pinyin-pro";
import { mapGetters } from "vuex";
export default {
  components: {
    Collapse,
    PersonalAvatar,
    Drawio,
  },
  props: {
    nodeInfo: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: String,
      default: "",
    },
    members: {
      type: [],
    },
    isSprint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawio_list: [],
      propertiesChangeFlag: false,
      fileTypeList: [],
      edit_list: [],
      propertiesList: [],
      doc_api_url: "",
      doc_issue_url: "",
      ones_issue_url: "",
      assignee_edit: false,
      storypoint_edit: false,
      api_edit: false,
      issue_edit: false,
      ones_issue_edit: false,
      controllability_edit: false,
      exposure_edit: false,
      severity_edit: false,
      safety_edit: false,
      add_api_visible: false,
      add_issue_visible: false,
      add_ones_issue_visible: false,
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      filterGroupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      user_list: [],
      filterUserList: [],
      assignee: "", // 负责人id
      api_list: [], // 负责人id
      issue_list: [],
      // ones线管
      ones_issue_list: [],
      project_labels: [],
      show_tips: [],
      user_loading: false,
      api_status_map: [
        this.$t("nodeDetail.basicInfo.api_status_map.published"),
        this.$t("nodeDetail.basicInfo.api_status_map.hold"),
        this.$t("nodeDetail.basicInfo.api_status_map.bin"),
        this.$t("nodeDetail.basicInfo.api_status_map.unsure"),
        this.$t("nodeDetail.basicInfo.api_status_map.develop"),
        this.$t("nodeDetail.basicInfo.api_status_map.test"),
        this.$t("nodeDetail.basicInfo.api_status_map.cor"),
        this.$t("nodeDetail.basicInfo.api_status_map.ana"),
        this.$t("nodeDetail.basicInfo.api_status_map.design"),
        this.$t("nodeDetail.basicInfo.api_status_map.complete"),
      ],
      storyPoint: 0,
      fileResult: {},
      asilOption: [],
      hazardOption: [],
      addIssueLoading: false,
      hasOnesConfig: false,
    };
  },
  computed: {
    ...mapGetters({
      all_user_list: "user_list",
    }),
    assigneeName() {
      let name = "";
      this.user_list.forEach((item) => {
        if (item.accountId === this.assignee) {
          name = item.nickname;
        }
      });
      return name;
    },
    eolink_show() {
      return this.nodeInfo.fileTypeId && this.fileTypeList
        ? this.fileTypeList.indexOf(this.nodeInfo.fileTypeId) !== -1
        : false;
    },
    hasEoLink() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "eolink"
      );
    },
    hasJira() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name === "Jira Cloud"
      );
    },
    hasOnes() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name === "Ones"
      );
    },
    // 数组对象的深度监听 会使newvalue 和 oldvalue值一致所以弄个副本用来监听内容的实际变化
    propertiesListCopy() {
      return JSON.parse(JSON.stringify(this.propertiesList));
    },
  },
  watch: {
    members() {
      let li = [];
      let my = {};
      if (this.members) {
        this.members.forEach((i) => {
          i.accountId == this.$store.getters.userAccountId
            ? (my = i)
            : li.push(i);
        });
      }
      const all_user_list = Object.values(this.all_user_list);
      this.user_list = [];
      this.user_list.push(my);
      this.user_list.push(...li);
      this.user_list = this.user_list.concat(
        ...all_user_list.filter((x) => {
          x.hide = true;
          return this.user_list.every((y) => y.accountId !== x.accountId);
        })
      );
      this.filterUserList = JSON.parse(JSON.stringify(this.user_list));
    },
    nodeInfo: {
      immediate: true,
      handler(val) {
        this.storyPoint = this.nodeInfo.storyPoint;
        this.firstTime = true;
        this.assignee = this.nodeInfo.assignee;
        this.propertiesList =
          this.nodeInfo && this.nodeInfo.content
            ? [...this.nodeInfo.content]
            : [];
        function order(a, b) {
          // 如果a是DRAWIO，它应该在最后，返回正数表示a应该在b之后
          if (a.fieldType === "DRAWIO") return 1;
          // 如果b是DRAWIO，它应该在a之后
          if (b.fieldType === "DRAWIO") return -1;
          // 如果a是TEXTAREA，且b不是DRAWIO（已经保证了），a在b前
          if (a.fieldType === "TEXTAREA") return 1;
          // 如果b是TEXTAREA，同时a不是DRAWIO（已经保证了），那么b排在a后
          if (b.fieldType === "TEXTAREA") return -1;
          // 对于其他，保持原有顺序
          return 0;
        }
        this.propertiesList.sort(order);
        this.propertiesList.forEach((item) => {
          this.show_tips.push(false);
        });
        // //暂时删除掉drawio 该代码后期删除
        for (let i = this.propertiesList.length - 1; i >= 0; i--) {
          if (this.propertiesList[i].fieldType === "DRAWIO") {
            this.drawio_list.push(
              Object.assign({}, this.propertiesList[i], {
                label: this.propertiesList[i].name,
              })
            );
            this.propertiesList.splice(i, 1);
          }
        }
        // 以上代码后期删除
        this.getapiDocs();
        if (this.hasJira) {
          this.getJiraIssue();
        }
        if (this.hasOnes) {
          getOnesConfigs().then((res) => {
            if (res.result) {
              this.getOnesIssue();
              this.hasOnesConfig = true;
            } else {
              this.hasOnesConfig = false;
            }
          });
        }
      },
    },
    assignee: {
      handler: function (newvalue, oldvalue) {
        if (this.firstTime) {
          this.oldAssignee = newvalue;
        } else {
          this.oldAssignee = oldvalue;
        }
      },
    },
  },
  created() {},
  mounted() {
    let li = [];
    let my = {};
    if (this.members) {
      this.members.forEach((i) => {
        i.accountId == this.$store.getters.userAccountId
          ? (my = i)
          : li.push(i);
      });
    }
    this.nodeInfo.content.map((item, index) => {
      if (item.fieldType == "MULTI_USER_SELECT") {
        if (item.value) {
          item.value.forEach((flag, num) => {
            if (flag.length == 19) {
              item.value[num] = flag + "0";
            }
          });
        }
      }
    });
    const all_user_list = Object.values(this.all_user_list);
    this.user_list = [];
    this.user_list.push(my);
    this.user_list.push(...li);
    this.user_list = this.user_list.concat(
      ...all_user_list.filter((x) => {
        x.hide = true;
        return this.user_list.every((y) => y.accountId !== x.accountId);
      })
    );
    this.filterUserList = JSON.parse(JSON.stringify(this.user_list));
    // this.get_user_list();
    this.geteolinkConfig();
    this.getGroupList();
  },
  methods: {
    // 人物多选失焦
    setMultiUser(val, isCustom, key) {
      this.mergedUsergroup(val, this.get_pid()).then((res) => {
        this.set_property_value(res, isCustom, key);
      });
    },
    setMultiline(e) {
      return e ? e.split("\n") : e;
    },
    getGroupList() {
      const group = Object.values(this.$store.getters.user_group_list);
      const user = Object.values(this.$store.getters.project_user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
        this.filterGroupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
        this.filterGroupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    jump_eolink(url) {
      window.open(url);
    },
    jump_jira(url) {
      window.open(url);
    },
    geteolinkConfig() {
      const params = {
        projectId: this.get_pid(),
      };
      geteolinkConfig(params).then((res) => {
        this.fileTypeList = res.fileTypeList;
      });
    },
    add_api_confirm(index) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        data: [this.doc_api_url],
      };
      addapiDocs(params).then((res) => {
        this.getapiDocs();
        this.add_api_visible = false;
      });
    },
    getapiDocs() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
      };
      getapiDocs(params).then((res) => {
        this.api_list = res;
      });
    },
    edit_api() {
      this.item_blur();
      this.api_edit = true;
    },
    delete_api(index) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        apiDocRelationId: this.api_list[index].apiDocRelationId,
      };
      deleteapiDocs(params).then((res) => {
        this.getapiDocs();
      });
    },
    add_api() {
      this.add_api_visible = true;
      this.doc_api_url = "";
    },
    edit_issue() {
      this.item_blur();
      this.issue_edit = true;
    },
    add_issue() {
      this.add_issue_visible = true;
      this.doc_issue_url = "";
    },
    add_issue_confirm(index) {
      this.addIssueLoading = true;
      if (this.doc_issue_url) {
        const params = {
          projectId: this.get_pid(),
          data: {
            issueUrl: this.doc_issue_url,
            nodeKey: this.nodeInfo.key,
          },
        };
        addJiraIssue(params)
          .then((res) => {
            this.addIssueLoading = false;
            this.getJiraIssue();
            this.add_issue_visible = false;
          })
          .catch((err) => {
            this.addIssueLoading = false;
          });
      } else {
        this.$message({
          message: this.$t("nodeDetail.basicInfo.addIssue"),
          type: "warning",
        });
        this.addIssueLoading = false;
      }
    },
    getJiraIssue() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
      };
      getJiraIssue(params).then((res) => {
        this.issue_list = res;
      });
    },
    delete_issue(index) {
      const params = {
        projectId: this.get_pid(),
        bindingId: this.issue_list[index].bindingId,
      };
      deleteJiraIssue(params).then((res) => {
        this.getJiraIssue();
      });
    },
    // 获取ones
    jump_ones(url) {
      window.open(url);
    },
    edit_ones_issue() {
      this.item_blur();
      this.ones_issue_edit = true;
    },
    getOnesIssue() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
      };
      getIssueBindings(params).then((res) => {
        this.ones_issue_list = res;
      });
    },
    delete_ones_issue(index) {
      const params = {
        projectId: this.get_pid(),
        bindingId: this.ones_issue_list[index].bindingId,
      };
      deleteIssueBindings(params).then((res) => {
        this.getOnesIssue();
      });
    },
    add_ones_issue() {
      this.add_ones_issue_visible = true;
      this.ones_issue_url = "";
    },
    makeLink(str) {
      if (str.indexOf("https://") !== -1) {
        return str.substring(str.indexOf("https://"), str.length);
      } else if (str.indexOf("http://") !== -1) {
        return str.substring(str.indexOf("http://"), str.length);
      } else {
        return str;
      }
    },
    add_ones_issue_confirm(index) {
      this.addIssueLoading = true;
      if (this.ones_issue_url) {
        const params = {
          projectId: this.get_pid(),
          data: {
            issueUrl: this.makeLink(this.ones_issue_url),
            nodeKey: this.nodeInfo.key,
          },
        };
        addIssueBindings(params)
          .then((res) => {
            this.addIssueLoading = false;
            this.getOnesIssue();
            this.add_ones_issue_visible = false;
          })
          .catch((err) => {
            this.addIssueLoading = false;
          });
      } else {
        this.$message({
          message: this.$t("nodeDetail.basicInfo.errOnesIssue"),
          type: "warning",
        });
        this.addIssueLoading = false;
      }
    },
    tool_tip_ishow(event, prop, index, inside) {
      if (
        prop.fieldType === "MULTI_USER_SELECT" ||
        prop.fieldType === "SELECT_MULTI" ||
        prop.fieldType === "VERSION" ||
        prop.fieldType === "TEXT" ||
        prop.fieldType === "LINK"
      ) {
        let TemporaryTag = document.createElement("span");
        TemporaryTag.innerText = inside;
        TemporaryTag.style.fontSize = "12px";
        TemporaryTag.className = "getTextWidth";
        document.querySelector("body").appendChild(TemporaryTag);
        let currentWidth = document.querySelector(".getTextWidth").offsetWidth;
        document.querySelector(".getTextWidth").remove();
        /*cellWidth为表格容器的宽度*/
        const cellWidth = event.target.offsetWidth;
        /*当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
        if (prop.fieldType === "TEXT" || prop.fieldType === "LINK") {
          currentWidth <= cellWidth - 10
            ? ""
            : this.$set(this.show_tips, index, true);
        } else {
          currentWidth <= 2 * cellWidth - 10
            ? ""
            : this.$set(this.show_tips, index, true);
        }
      }
    },
    assignee_change(val) {
      multiLayers_change_assignee({
        projectId: this.get_pid(),
        nodeKeys: [this.nodeInfo.key],
        data: {
          accountId: val,
        },
      }).then((res) => {
        this.firstTime = false;
      });
    },
    // 过滤方法
    getPinyinMatch(inputValue, list, label) {
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, {
        toneType: "none",
        type: "array",
      }).join("");
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, {
        pattern: "first",
        type: "array",
      }).join("");
      return list.filter((e) => {
        const pyE = pinyin(e[label ? label : "nickname"], {
          toneType: "none",
          type: "array",
        })
          .join("")
          .slice(0, pyInput.length);
        // 列表中每项的拼音首字母
        const headerPyE = pinyin(e[label ? label : "nickname"], {
          pattern: "first",
          type: "array",
        })
          .join("")
          .slice(0, headerPyInput.length);
        // 过滤匹配
        return (
          e[label ? label : "nickname"].includes(inputValue) || pyE === pyInput
          // 是否提取首字母匹配
          // ||
          // headerPyE === headerPyInput
        );
      });
    },
    filterMethod(val) {
      this.filterUserList = this.getPinyinMatch(val, this.user_list);
    },
    singleUserVisibleChange() {
      this.filterUserList = JSON.parse(JSON.stringify(this.user_list));
    },
    mutUserVisibleChange() {
      this.filterGroupandUser = JSON.parse(JSON.stringify(this.groupandUser));
    },
    groupandUserfilterMethod(val) {
      if (!val) {
        this.filterGroupandUser = JSON.parse(JSON.stringify(this.groupandUser));
      } else {
        this.$set(
          this.filterGroupandUser[0],
          "options",
          this.getPinyinMatch(val, this.groupandUser[0].options, "label")
        );
        this.$set(
          this.filterGroupandUser[1],
          "options",
          this.getPinyinMatch(val, this.groupandUser[1].options, "label")
        );
      }
    },
    storypoint_change(val) {
      this.storyPoint = Math.floor(val);
      this.set_property_value(this.storyPoint, false, "story_point");
    },
    chooseUserByGroup(id) {
      let list = [];
      let accountIdLists = [];
      const group = Object.values(this.$store.getters.user_group_list);
      const user = Object.values(this.$store.getters.project_user_list);
      const groupItem = group.find((item) => item.groupId === id);
      list = user.filter((item) =>
        groupItem.accountIdList.includes(item.accountId)
      );
      list.map((item) => {
        accountIdLists.push(item.accountId);
      });
      if (groupItem) {
        return accountIdLists;
      } else {
        return [];
      }
    },

    show_label_build(value, type, list) {
      let newValList = [];
      let listGroup = [];
      if (type == "MULTI_USER_SELECT") {
        if (value != null) {
          value.map((item) => {
            if (item.substr(-1) == 1) {
              listGroup = this.chooseUserByGroup(
                item.substr(0, item.length - 1)
              );
              listGroup.map((item) => {
                newValList.push(item);
              });
            } else {
              newValList.push(item.substr(0, item.length - 1));
            }
          });
          value = [...new Set(newValList)];
        }
      }
      let newvalue = value && typeof value === "object" ? [] : "";
      if (list && list.length) {
        const tempvalue = value && typeof value === "object" ? value : [value];
        tempvalue.forEach((val) => {
          list.forEach((li) => {
            if (li.value === val) {
              typeof value === "object"
                ? newvalue.push(li.label)
                : (newvalue = li.label);
            }
          });
        });
      } else if (type === "DATE" && value) {
        newvalue = new Date(value).Format("yyyy-MM-dd");
      } else if (
        type === "SINGLE_USER_SELECT" ||
        type === "MULTI_USER_SELECT"
      ) {
        const tempvalue = value && typeof value === "object" ? value : [value];
        tempvalue.forEach((val) => {
          this.user_list.forEach((li) => {
            if (li.accountId === val) {
              typeof value === "object"
                ? newvalue.push(li.nickname)
                : (newvalue = li.nickname);
            }
          });
        });
      } else {
        newvalue = value;
      }
      if (newvalue && typeof newvalue === "object") {
        newvalue = newvalue.join("，");
      }
      return newvalue;
    },
    edit_assignee() {
      this.item_blur();
      this.assignee_edit = true;
    },
    edit_controllability() {
      if (this.nodeInfo.lock) {
        this.add_highlight();
        return;
      }
      this.item_blur();
      this.controllability_edit = true;
    },
    edit_exposure() {
      if (this.nodeInfo.lock) {
        this.add_highlight();
        return;
      }
      this.item_blur();
      this.exposure_edit = true;
    },
    edit_severity() {
      if (this.nodeInfo.lock) {
        this.add_highlight();
        return;
      }
      this.item_blur();
      this.severity_edit = true;
    },
    edit_safety() {
      if (this.nodeInfo.lock) {
        this.add_highlight();
        return;
      }
      this.item_blur();
      this.safety_edit = true;
    },
    edit_storypoint() {
      if (this.nodeInfo.lock) {
        this.add_highlight();
        return;
      }
      this.item_blur();
      this.storypoint_edit = true;
    },
    edit_item(index) {
      this.item_blur();
      this.$set(this.edit_list, index, true);
    },
    item_blur() {
      this.assignee_edit = false;
      this.api_edit = false;
      this.controllability_edit = false;
      this.exposure_edit = false;
      this.severity_edit = false;
      this.safety_edit = false;
      this.issue_edit = false;
      this.ones_issue_edit = false;
      const regex = /^[0-9]\d*$/;
      if (!regex.test(this.storyPoint)) {
        this.$message.error(this.$t("addEditCopy.limit"));
      } else {
        this.storypoint_edit = false;
      }

      this.edit_list.forEach((item, index) => {
        this.$set(this.edit_list, index, false);
      });
    },
    async mergedUsergroup(arr, projectId) {
      let account = [];
      let group = [];
      if (arr) {
        arr.map((item, index) => {
          if (item.substr(-1) == 0) {
            account.push(item.substr(0, item.length - 1));
          } else {
            group.push(item.substr(0, item.length - 1));
          }
        });
      }
      const params = {
        projectId: projectId,
        accountIdList: account,
        groupIdList: group,
      };
      return await get_group_user_list(params);
    },
    async set_property_value(val, isCustom, key) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        data: {
          isCustom: isCustom,
          fieldId: key,
          value: val,
        },
      };
      await only_set_property(params);
    },

    // 添加高亮效果
    add_highlight() {
      this.$message({
        type: "warning",
        message: this.$t("nodeDetail.message.error"),
      });
      let highlight = document.querySelectorAll(".highlight_cascader");
      for (let i = 0; i < highlight.length; i++) {
        highlight[i].firstChild.classList.remove("high_light_cas");
        setTimeout(() => {
          highlight[i].firstChild.classList.add("high_light_cas");
        });
      }
    },
    get_Label_data(val) {
      if (val) {
        get_Labels(this.get_pid())
          .then((res) => {
            this.project_labels = res;
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #fff;
  padding: 20px 10px;
  margin: 20px 0;
  margin-bottom: 0;
  border-radius: 8px;
}
.draw {
  width: 100%;
}
.field-info__item__label,
.tooltip_span {
  font-weight: bold;
}
.field-info__item__label {
  text-align: left;
}
.basicInfo_api {
  display: flex;
}
.basicInfo_jira {
  display: flex;
}
.user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
    .avatar {
      display: flex;
    }
    .select_item {
      margin-left: 14px;
    }
  }
}
.field-info {
  display: flex;
  flex-wrap: wrap;
  ::v-deep {
    .el-input {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
  & > .text_area {
    width: 100%;
  }
  & > div {
    width: calc(33% - 40px);
    display: flex;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    align-items: start;
    margin: 0 40px 20px 0;
    // :active + .disable_edit {
    //   animation: light 2s 20;
    // }
    &__label {
      width: 200px;
      padding: 0 6px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      span {
        // width: 100%;
        margin-right: 10px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: 30px;
        line-height: 30px;
        width: 100%;
      }
    }
  }
}
.not_edit {
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-height: 30px;
  line-height: 30px;
  font-size: 14px;
  align-items: start;
  text-indent: 10px;
  &:hover {
    background-color: #e5e5e5;
  }
}
.priority_icon {
  width: 30px;
  height: 30px;
}
.api_edit {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: start;
  align-items: baseline;
  & > div {
    width: 51%;
    margin-bottom: 10px;
  }
  .api_status {
    padding: 0 26px;
    display: inline-block;
    border-radius: 4px;
    margin-top: 5px;
    background-color: rgba(182, 219, 242, 1);
  }
}
.api_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
}
.issue_edit {
  flex-wrap: wrap;
  align-items: baseline;
  & > div {
    margin-bottom: 10px;
  }
  .issue_status {
    padding: 0 26px;
    display: inline-block;
    border-radius: 4px;
    margin-left: 10px;
    font-weight: 800;
    text-indent: 0;
  }
  .todo {
    background-color: rgb(223, 225, 229);
    color: rgb(29, 42, 75);
  }
  .progress {
    background-color: rgb(33, 81, 197);
    color: #fff;
  }
  .done {
    background-color: green;
    color: #fff;
  }
  .issue_priority {
    padding: 0 26px;
    display: flex;
    border-radius: 4px;
    margin-left: 10px;
  }
}
.api_name {
  padding: 0 26px;
  display: inline-block;
  border-radius: 4px;
  text-align: left;
  background-color: #e5e5e5;
  margin-top: 5px;
}
.issue_key {
  padding: 0 10px;
  display: inline-block;
  border-radius: 4px;
  font-weight: 800;
  color: rgb(33, 81, 197);
}
.issue_name {
  padding-right: 26px;
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  max-width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
  // background-color: #e5e5e5;
}
.issue_name_edit {
  padding-right: 26px;
  display: inline-block;
  border-radius: 4px;
  background-color: #e5e5e5;
  overflow: hidden;
  cursor: pointer;
  max-width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.show_two_line {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2; //自定义行数
  line-height: 30px;
}
.show_one_line {
  display: inline-block;
  overflow: hidden; //超出文本隐藏
  white-space: nowrap; //不换行，只显示一行
  text-overflow: ellipsis; //超出部分省略号显示
  line-height: 30px;
}
.disable_edit {
  cursor: not-allowed;
}
.is_edit {
  width: 100%;
}
.float {
  width: 80vw;
  height: 80vh;
}
.grid_box {
  display: grid;
  grid-template-columns: 98% 2%;
  grid-column-gap: 20px;
  align-items: center;
}
</style>

<style lang="scss">
@keyframes light {
  from {
    box-shadow: 0px 0px 7px 2px rgb(55 159 254 / 80%);
  }
  to {
    // box-shadow: 0px 1px 0px 0px rgba(9, 13, 20, 1);
  }
}
.high_light_cas {
  animation: light 0.5s 5;
}

@keyframes light_back {
  from {
    // box-shadow: 0px 0px 7px 2px rgb(55 159 254 / 80%);
    background-color: #aed4ff;
  }
  to {
    // box-shadow: 0px 1px 0px 0px rgba(9, 13, 20, 1);
  }
}
.light_back_cas {
  animation: light_back 0.5s 5;
}

@keyframes light_click {
  from {
    // box-shadow: 0px 0px 7px 2px rgb(55 159 254 / 80%);
    background-color: #aed4ff;
  }
  to {
    // box-shadow: 0px 1px 0px 0px rgba(9, 13, 20, 1);
  }
}
.light_click_cas {
  animation: light_click 0.5s 2.5;
}
</style>
