<template>
  <div>
    <el-drawer
      class="draw_index"
      :visible="drawer"
      size="70%"
      :with-header="false"
      @close="$emit('close')"
      :append-to-body="true"
    >
      <div
        v-if="drawer"
        v-loading="drawer_loading"
        style="background-color: #f0f2f5"
        class="drawer_wrap"
        :element-loading-text="$t('loading.loading1')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        @click="blur_all($event)"
      >
        <!-- 节点key 名称 状态 -->
        <el-row class="header_content">
          <el-col :span="10" :offset="2" class="header_node">
            <span style="display: flex">
              <div class="box_item_l">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="file_icon_name"
                  placement="top"
                >
                  <span style="background-color: #014caa">
                    <i
                      v-if="file_icon !== '-1'"
                      class="iconfont"
                      :class="'icon-a-' + file_icon"
                      style="color: #fff"
                    ></i>
                    <span v-else style="color: white; font-weight: 700">{{
                      file_icon_name.slice(0, 1)
                    }}</span>
                  </span>
                </el-tooltip>
              </div>
              <div class="box_item_r">
                <span
                  class="box_font"
                  style="cursor: pointer"
                  @click="jump_node(current_node)"
                  >{{ current_node.key }}</span
                >
              </div>
            </span>
            <span
              style="display: flex; justify-content: center; align-items: top"
            >
              <i
                style="
                  font-size: 20px;
                  margin-left: 16px;
                  cursor: pointer;
                  color: rgb(48, 100, 143);
                  padding-top: 5px;
                "
                class="iconfont icon-node_link"
                @click="nodeLink"
              >
              </i>
            </span>
            <el-tooltip
              v-if="lock_line.length"
              effect="light"
              :content="
                $t('scrum.newSprint.info1') +
                `${lock_line.join(',')}` +
                $t('scrum.newSprint.info2')
              "
              placement="bottom"
            >
              <div class="baseline_lock">
                <i
                  class="iconfont icon-locked"
                  style="color: rgb(48, 100, 143)"
                >
                </i>
              </div>
            </el-tooltip>
          </el-col>

          <el-tooltip placement="left" :disabled="is_show_name">
            <div slot="content" v-html="addbr(current_node.topic)"></div>
            <el-col :span="14">
              <div
                class="node-name"
                @mouseenter="isShowTooltip($event, 'node_name')"
              >
                <span
                  v-show="node_name_span"
                  ref="node_name"
                  v-limits-of-authority="'NODE_EDIT'"
                  onselectstart="return false;"
                  style="-moz-user-select: none"
                  @dblclick="
                    current_node.lock ? '' : edit_nodename(current_node.topic)
                  "
                  >{{ current_node.topic }}</span
                >
                <el-input
                  v-show="!node_name_span"
                  ref="input_panel"
                  v-model="input_name"
                  type="textarea"
                  :rows="2"
                  :placeholder="$t('placeholder.input')"
                  @keyup.enter="away"
                  @blur="away"
                ></el-input>
              </div>
            </el-col>
          </el-tooltip>
          <el-col :offset="4" :span="3">
            <node-status-select
              v-if="current_node.key && drawer"
              ref="node_status_select"
              :node-info="current_node"
              :node-key="current_node.key"
              @show_workflow="show_workflow"
              @headCallBack="headCallBack"
            ></node-status-select>
          </el-col>
        </el-row>

        <!-- 节点基本信息 -->
        <el-row>
          <node-base-info-row
            v-if="membersShow"
            ref="base-info"
            :node-info="current_node"
            :project-id="get_pid()"
            :members="members"
            :is-sprint="false"
          ></node-base-info-row>
        </el-row>

        <el-row v-if="membersShow" style="margin-top: 20px">
          <node-release-info-row
            ref="release-info"
            :node-key="current_node.key"
            :node-info="current_node"
          ></node-release-info-row>
        </el-row>
        <el-row v-if="showTestPlan" style="margin-top: 20px">
          <node-test-plan
            :node-key="current_node.key"
            :lock="current_node.lock"
            :node-uuid="current_node.nodeUuid"
          ></node-test-plan>
        </el-row>
        <el-row style="margin-top: 20px">
          <gantt-node-detail-row
            :node-key="current_node.key"
          ></gantt-node-detail-row>
        </el-row>
        <el-row style="margin-top: 20px">
          <node-description-row
            :lock="current_node.lock"
            :project-id="get_pid()"
            :node-key="current_node.key"
            :description="current_node.description"
            :attachments="current_node.attachments"
            @update_description_content="update_description_content"
          ></node-description-row>
        </el-row>
        <el-row style="margin-top: 35px">
          <relation-graph-row
            v-if="node_status_show"
            :node_key="current_node.key"
            :node-info="current_node"
          ></relation-graph-row>
        </el-row>
        <el-row style="margin-top: 35px" v-if="show_third_web">
          <NodeThirdWebRow
            :node_key="current_node.key"
            :node-info="current_node"
          ></NodeThirdWebRow>
        </el-row>
        <el-row style="margin-top: 35px">
          <activity-row
            ref="ar"
            :members="members"
            :node-key="current_node.key"
            :node-uuid="current_node.nodeUuid"
            :file-test="showTestPlan"
            :node-info="current_node"
          ></activity-row>
        </el-row>
      </div>
    </el-drawer>
    <view-workflow
      v-if="
        workflow_dialog_visible &&
        current_node.layerNumber &&
        current_node.fileTypeId
      "
      :file-type-id="current_node.fileTypeId"
      :layer-number="current_node.layerNumber"
    ></view-workflow>
  </div>
</template>

<script>
import ViewWorkflow from "@/components/mindmap/ViewWorkflow";
import NodeStatusSelect from "@/components/mindmap/node_detail/NodeStatusSelect.vue";
import NodeBaseInfoRow from "@/components/mindmap/node_detail/NodeBaseInfoRow.vue";
import NodeReleaseInfoRow from "@/components/mindmap/node_detail/NodeReleaseInfoRow.vue";
import NodeDescriptionRow from "@/components/mindmap/node_detail/NodeDescriptionRow.vue";
import NodeTestPlan from "@/components/mindmap/node_detail/NodeTestPlan.vue";
import GanttNodeDetailRow from "@/components/mindmap/node_detail/GanttNodeDetailRow.vue";
import RelationGraphRow from "@/components/mindmap/node_detail/RelationGraphRow.vue";
import ActivityRow from "@/components/mindmap/node_detail/ActivityRow.vue";
import NodeThirdWebRow from "@/components/mindmap/node_detail/NodeThirdWebRow.vue";
import { get_component_license } from "@/network/license/index.js";
import { mapGetters } from "vuex";
import {
  get_node_use_node_key,
  only_set_property,
} from "@/network/node/index.js";
import { get_file } from "@/network/baseline/index.js";
import { inProgressBaselines } from "@/network/merge/index";
export default {
  name: "NodeDrawer",
  components: {
    NodeBaseInfoRow,
    NodeDescriptionRow,
    NodeStatusSelect,
    NodeThirdWebRow,
    NodeReleaseInfoRow,
    ViewWorkflow,
    NodeTestPlan,
    GanttNodeDetailRow,
    RelationGraphRow,
    ActivityRow,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    membersShow: {
      type: Boolean,
      default: false,
    },
    current_node: {
      type: Object,
      default() {
        return {};
      },
    },
    drawer_loading: {
      type: Boolean,
      default: false,
    },
    members: {
      type: Array,
      default() {
        return [];
      },
    },
    showTestPlan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show_third_web: false,
      workflow_dialog_visible: false,
      node_name_span: true,
      input_name: "",
      is_show_name: true,
      lock_line: [],
      file_icon: "",
      file_icon_name: "",
      input_name1: "",
      node_status_show: true,
    };
  },
  computed: {
    ...mapGetters(["fileType"]),
  },
  mounted() {
    get_component_license().then((res) => {
      for (let j of res) {
        if (j.feature == "THIRD_LINK") {
          this.show_third_web = true;
        }
      }
    });
  },
  watch: {
    current_node(newVal) {
      this.lock_line = [];
      const params = {
        projectId: this.get_pid(),
        nodeKey: newVal.key,
      };
      inProgressBaselines(params).then((res) => {
        res &&
          res.forEach((line) => {
            if (line.lock) {
              this.lock_line.push(line.name);
            }
          });
      });
      this.file_icon = this.fileType[newVal.fileTypeId].icon;
      this.file_icon_name = this.fileType[newVal.fileTypeId].name;
    },
  },
  methods: {
    nodeLink() {
      get_file(this.get_pid(), this.current_node.fileId).then((result) => {
        const { href } = this.$router.resolve({
          path: `/${this.get_pid()}/myMind/${result.key}`,
          query: {
            node_id: this.current_node.id, // 节点id
          },
        });
        window.open(href, "_blank");
      });
    },
    jump_node(item) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey: item.key,
        },
      });
      window.open(href, "_blank");
    },
    blur_all(event) {
      if (event.target.nodeName === "DIV") {
        this.$refs["base-info"].item_blur();
        this.$refs["release-info"].item_blur();
        this.$refs["node_status_select"].showWorkflow = false;
      }
    },
    addbr(topic) {
      // 设置变量存储返回值
      let newstr = "";
      // 如果长度超过20，就要截取插入字符
      if (topic && topic.length > 20) {
        // 第一次截取
        newstr = topic.slice(0, 20) + "<br/>";
        // 闭包再次调用，如果截取后的字段长度依然超过20，再次调用，如果没有直接返回当前值
        return newstr + this.addbr(topic.slice(20));
      } else {
        // 直接返回当前值
        return topic;
      }
    },
    /*双击节点名进行编辑 */
    edit_nodename(name) {
      this.node_name_span = false;
      this.input_name = name;
      this.$nextTick(() => {
        this.$refs["input_panel"].focus();
      });
      this.input_name1 = this.input_name;
    },
    show_workflow() {
      this.workflow_dialog_visible = true;
    },
    headCallBack() {
      this.$emit("loading-finished");
    },
    away() {
      this.node_name_span = true;
      if (/^\s*$/.test(this.input_name)) {
        this.name = this.input_name1;
      } else {
        this.name = this.input_name;
      }
      /*访问接口进行节点名称修改 */
      get_node_use_node_key(this.get_pid(), this.current_node.key).then(
        (res) => {
          this.$emit("update_current_node", res);
          let obj = res;
          obj["topic"] = this.name;
          const params = {
            projectId: this.get_pid(),
            nodeKey: res.key,
            data: {
              isCustom: false,
              fieldId: "topic",
              value: this.name,
            },
          };
          only_set_property(params).then((res) => {
            this.current_node.topic = this.name;
          });
        }
      );
    },
    update_description_content(description) {
      this.$emit("update_description_content", description);
      // this.current_node.description = description;
    },
    isShowTooltip(e, refname) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const pWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      const cWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      // 文字超出隐藏并弹出tooltip提示，文字未超出则不弹出tooltip提示的判断条件
      if (scrollHeight > clientHeight && pWidth >= cWidth) {
        this.is_show_name = false;
        this.is_show_topic = false;
      } else {
        this.is_show_name = true;
        this.is_show_topic = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.drawer_wrap {
  padding: 20px;
  // height: 100%;
  .header_content {
    display: flex;
    justify-content: flex-start;
    align-items: top;
    text-align: left;
    margin: 20px auto 0 auto;
    .header_node {
      display: flex;
      align-items: top;
      margin-left: 0 !important;
      // margin-right: 20px;
    }
    ::v-deep .el-input__inner {
      width: 180px;
    }
  }
  .top-node-id {
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 195, 0, 1);
    background-color: rgba(229, 229, 229, 1);
    width: 120px;
    height: 28px;
    border-radius: 2px;
    text-align: center;
    span {
      vertical-align: middle;
      line-height: 28px;
    }
  }

  .node-name {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -webkit-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .baseline_lock {
    color: rgba(166, 166, 166, 1);
    font-size: 14px;
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }
  .box_item_l {
    height: 30px;
    border: 1px solid #014caa;
    border-radius: 5px 0 0 5px;
    padding: 6px 14px;
    background-color: #014caa;
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .box_item_r {
    height: 30px;
    border: 1px solid rgba(219, 219, 219, 1);
    border-radius: 0 5px 5px 0;
    padding: 0 14px;
    display: flex;
    justify-items: center;
    align-items: center;
    .box_font {
      font-size: 18px;
      color: rgba(255, 195, 0, 1);
      font-weight: 700;
    }
  }
}
// .draw_index {
//   z-index: 2003 !important;
// }
</style>
