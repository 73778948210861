<template>
  <div class="node_test_plan">
    <collapse :title="$t('addNodeTestPlan.title')" :isEditCopy="isEditCopy">
      <!-- row-key是拖拽功能实现的唯一索引 -->
      <el-table
        :data="tableData"
        border
        row-key="id"
        class="filter"
        style="width: 100%"
        header-row-class-name="table_header"
      >
        <!-- 编号 -->
        <el-table-column
          type="index"
          width="100"
          class-name="table_index"
          :label="$t('addNodeTestPlan.lab1')"
          align="center"
        >
        </el-table-column>

        <!-- 前置条件 -->
        <el-table-column
          prop="preConditions"
          :label="$t('addNodeTestPlan.lab2')"
          align="left"
          header-align="center"
        >
          <template slot-scope="scope">
            <span v-if="!isedit && !isEditCopy" style="white-space: pre-wrap">{{
              scope.row.preConditions ? scope.row.preConditions : ""
            }}</span>
            <el-input
              v-else
              v-model="scope.row.preConditions"
              :autofocus="true"
              :placeholder="$t('addNodeTestPlan.place1')"
              :autosize="{ minRows: 1, maxRows: 3 }"
              type="textarea"
              class="textarea"
            />
          </template>
        </el-table-column>

        <!-- 测试步骤 -->
        <el-table-column
          prop="testingProcedure"
          :label="$t('addNodeTestPlan.lab3')"
          align="left"
          header-align="center"
        >
          <template slot-scope="scope">
            <span v-if="!isedit && !isEditCopy" style="white-space: pre-wrap">{{
              scope.row.testingProcedure ? scope.row.testingProcedure : ""
            }}</span>
            <el-input
              v-else
              v-model="scope.row.testingProcedure"
              :autofocus="true"
              :placeholder="$t('addNodeTestPlan.place2')"
              :autosize="{ minRows: 1, maxRows: 3 }"
              show-overflow-tooltip="true"
              type="textarea"
              class="textarea"
            />
          </template>
        </el-table-column>

        <!-- 预期结果 -->
        <el-table-column
          prop="expectedResult"
          :label="$t('addNodeTestPlan.lab4')"
          align="left"
          header-align="center"
        >
          <template slot-scope="scope">
            <span v-if="!isedit && !isEditCopy" style="white-space: pre-wrap">{{
              scope.row.expectedResult ? scope.row.expectedResult : ""
            }}</span>
            <el-input
              v-else
              v-model="scope.row.expectedResult"
              :autofocus="true"
              :placeholder="$t('addNodeTestPlan.place3')"
              :autosize="{ minRows: 1, maxRows: 3 }"
              type="textarea"
              class="textarea"
            />
          </template>
        </el-table-column>

        <!-- 删除按钮 -->
        <el-table-column v-if="isedit || isEditCopy" align="center" width="50">
          <template slot-scope="scope">
            <el-tooltip
              :content="$t('addNodeTestPlan.del')"
              transition=""
              :open-delay="750"
              placement="top"
              ><i
                class="iconfont icon-delete-red"
                style="cursor: pointer; font-size: 20px; color: red"
                @click="del(scope)"
              ></i
            ></el-tooltip>
            <el-tooltip
              :content="$t('addNodeTestPlan.copy')"
              transition=""
              :open-delay="750"
              placement="top"
            >
              <i
                class="el-icon-document-copy"
                style="cursor: pointer"
                @click="copy_step(scope)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加按钮 -->
      <div v-if="isedit || isEditCopy" class="svg_sty">
        <el-tooltip
          :content="$t('addNodeTestPlan.addnull')"
          transition=""
          :open-delay="850"
          placement="top"
        >
          <i
            class="iconfont icon-add"
            style="
              font-size: 25px;
              margin: 20px 0;
              cursor: pointer;
              color: rgb(48, 100, 143);
            "
            @click="add_plan"
          ></i>
        </el-tooltip>
      </div>
      <!-- 初始化 取消按钮 -->
      <el-button
        v-if="isedit && !isEditCopy"
        type="info"
        size="small"
        @click="init"
      >
        {{ $t("btn.cancelBtn") }}
      </el-button>
      <!-- 保存后触发更改 -->
      <el-button
        v-if="isedit && !isEditCopy"
        type="primary"
        size="small"
        @click="change"
      >
        {{ $t("addNodeTestPlan.btn1") }}
      </el-button>
      <el-tooltip
        v-if="!isedit && !isEditCopy"
        class="item"
        :disabled="!lock"
        effect="dark"
        :content="$t('canvas.testPlan.lock')"
        placement="top"
      >
        <!-- span是button的外层标签 -->
        <span>
          <el-button
            v-limits-of-authority="'NODE_EDIT'"
            type="info"
            size="small"
            :disabled="lock || limits_of_authority_disable('NODE_EDIT')"
            style="margin: 10px 0; margin-left: 0; margin-right: auto"
            @click="edit()"
          >
            {{ $t("addNodeTestPlan.btn2") }}
          </el-button>
        </span>
      </el-tooltip>
    </collapse>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import Collapse from "./Collapse.vue";
import {
  get_node_use_node_key,
  only_set_property,
} from "@/network/node/index.js";
import { limits_of_authority_disable } from "@/utils/limits";
export default {
  components: {
    Collapse,
  },
  props: {
    nodeKey: {
      type: String,
      default: "",
    },
    lock: {
      type: Boolean,
      default: false,
    },
    isEditCopy: {
      type: Boolean,
      default: false,
    },
    datalist: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      isedit: false,
      node_inf: [],
      tableData: [],
      tbody: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.init();
  },
  mounted() {
    // 阻止默认行为，防止拖拽功能被影响
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
  },
  methods: {
    limits_of_authority_disable,
    // 拷贝一行步骤
    copy_step(data) {
      this.tableData.push({
        id: Math.random(), //随机数作为唯一标示，拖拽的索引，去掉会拖拽异常
        expectedResult: data.row.expectedResult,
        preConditions: data.row.preConditions,
        testingProcedure: data.row.testingProcedure,
      });
    },
    // 拖拽抑制，添加过滤
    isSortable(isSort) {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      tbody.querySelectorAll("*").forEach((child) => {
        if (!isSort) {
          child.classList.add("filter");
        } else {
          child.classList.remove("filter");
        }
      });
    },
    // 编辑按钮
    edit() {
      // 判断数据列表是否为空
      if (this.tableData.length == 0) {
        // 为空则初始化
        this.tableData.push({
          id: Math.random(), //随机数作为唯一标示，拖拽的索引，去掉会拖拽异常
          expectedResult: "",
          preConditions: "",
          testingProcedure: "",
        });
      }
      //改变表格状态
      this.isedit = true;
      this.rowDrop(); //行列拖拽
      this.isSortable(this.isedit);
    },
    // 删除按钮操作
    del(data) {
      this.tableData.splice(data.$index, 1);
    },
    // 添加按钮
    add_plan() {
      this.tableData.push({
        id: Math.random(), //随机数作为唯一标示，拖拽的索引，去掉会拖拽异常
        expectedResult: "",
        preConditions: "",
        testingProcedure: "",
      });
    },
    // 表格改变后提交数据
    change() {
      let changeList = [];
      for (let i of this.tableData) {
        changeList.push({
          id: Math.random(), //随机数作为唯一标示，拖拽的索引，去掉会拖拽异常
          expectedResult: i.expectedResult,
          preConditions: i.preConditions,
          testingProcedure: i.testingProcedure,
        });
      }
      get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
        this.node_inf = JSON.parse(JSON.stringify(res)); //深拷贝一份整理到this.node_inf中。
        this.node_inf.testDetails = changeList;
        const params = {
          projectId: this.get_pid(),
          nodeKey: this.node_inf.key,
          data: {
            isCustom: false,
            fieldId: "test_details",
            value: changeList,
          },
        };
        only_set_property(params).then((res) => {
          this.$message.success(this.$t("addNodeTestPlan.message"));
          this.isedit = false;
          this.isSortable(this.isedit);
        });
      });
    },
    // 初始化
    init() {
      this.isedit = false;
      // 延时一秒获取dom
      setTimeout(() => {
        this.isSortable(this.isedit);
      }, 1000);
      get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
        this.node_inf = JSON.parse(JSON.stringify(res)); //深拷贝一份整理到this.node_inf中。
        this.tableData = []; // 清空列表
        if (!this.isEditCopy) {
          if (Array.isArray(res.testDetails)) {
            for (let i of res.testDetails) {
              this.tableData.push({
                id: Math.random(), //随机数作为唯一标示，拖拽的索引，去掉会拖拽异常
                expectedResult: i.expectedResult,
                preConditions: i.preConditions,
                testingProcedure: i.testingProcedure,
              });
            }
          }
        } else {
          this.tableData = this.datalist;
        }
      });
    },
    // 拖拽
    // 行拖拽
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        animation: 150, //定义排序动画的时间
        handle: ".table_index",
        filter: ".filter", //拖拽条件class
        // 结束拖拽
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
        },
      });
    },
  },
};
</script>

<style lang="scss">
.svg_sty {
  margin-top: 10px;
  margin-bottom: 10px;
}

.el-table__body {
  color: rgba(128, 128, 128, 1);
}

.node_test_plan {
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 8px;
  text-align: left;
  .table_header {
    color: black !important;
  }

  .el-table--border {
    border: 1px solid rgba(229, 229, 229, 1);
  }
}
</style>
