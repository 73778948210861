<template>
  <div
    v-loading="loading"
    class="reuse_records_wrap"
    ref="reuse_records_wrap"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div id="chartLine"></div>
  </div>
</template>

<script>
import { get_node_use_node_key, nodeOriginLog } from "@/network/node";
export default {
  components: {},
  inject: ["nodeKey"],
  data() {
    return {
      loading: false,
      lineChart: null,
      dataList: [],
      createdAt: "",
    };
  },
  mounted() {},
  methods: {
    async getNodeOriginLog() {
      this.dataList = [];
      this.loading = true;
      const res = await get_node_use_node_key(this.get_pid(), this.nodeKey);
      this.createdAt = res.createdAt;
      const params = {
        projectId: this.$route.params.projectId,
        nodeKey: this.nodeKey,
      };
      const downMap = {};
      nodeOriginLog(params).then((res) => {
        res &&
          res.forEach((item) => {
            if (item.newNodeKey === this.nodeKey) {
              const obj = {
                type: "down",
                time: Date.parse(item.createdAt),
                data: [item],
              };
              this.dataList.push(obj);
            } else if (item.sourceNodeKey === this.nodeKey) {
              if (downMap[item.newNodeKey]) {
                downMap[item.newNodeKey].data.push(item);
              } else {
                const obj = {
                  type: "up",
                  time: Date.parse(item.createdAt),
                  data: [item],
                };
                downMap[item.newNodeKey] = obj;
              }
            }
          });
        for (let key in downMap) {
          this.dataList.push(downMap[key]);
        }
        this.dataList.sort((a, b) => a.time - b.time);
        this.buildLine();
      });
    },
    buildLine() {
      const self = this;
      const xAxisData = [];
      const series = [];
      let i = 0;
      // 添加创建节点
      if (!this.dataList.length || this.dataList[0].type === "up") {
        i = 1;
        series.push({
          data: [0],
          type: "line",
          name: this.$t("nodeDetail.activity.reuse.CreateNode"),
          symbolSize: 18,
          label: {
            show: true,
            color: "#909399",
            fontSize: 14,
            formatter: function (params) {
              return (
                params.seriesName +
                "\n" +
                new Date(self.createdAt).Format("yyyy.MM.dd")
              );
            },
          },
          itemStyle: {
            color: "#0155AE",
          },
        });
      }
      this.dataList.forEach((item, dataListIndex) => {
        if (item.type === "down") {
          // 生成线条数据
          const seriesObj = {
            smooth: 1,
            type: "line",
            symbolSize: 18,
            itemStyle: {
              color: "#0155AE",
            },
            data: [],
          };
          for (let h = 0; h < i; h++) {
            seriesObj.data.push({
              value: null,
            });
          }
          seriesObj.data.push(
            ...[
              {
                value: -5,
                itemStyle: {
                  opacity: 0,
                },
              },
              {
                value: -5,
                name:
                  (dataListIndex
                    ? this.$t("nodeDetail.activity.reuse.sync")
                    : this.$t("nodeDetail.activity.reuse.copyF")) +
                  item.data[0].sourceNodeKey,
                custom: item.data[0],
                label: {
                  show: true,
                  color: "#909399",
                  fontSize: 14,
                  formatter: function (params) {
                    return (
                      params.name +
                      "\n" +
                      new Date(item.data[0].createdAt).Format("yyyy.MM.dd")
                    );
                  },
                },
              },
              {
                value: 0,
                itemStyle: {
                  opacity: 0,
                },
              },
            ]
          );
          series.push(seriesObj);
          // 生成X轴
          for (let j = 0; j < 3; j++) {
            xAxisData.push(i++);
          }
        } else {
          // 生成线条数据
          const seriesObj = {
            smooth: 1,
            type: "line",
            symbolSize: 18,
            itemStyle: {
              color: "#0155AE",
            },
            data: [],
          };
          for (let h = 0; h < i; h++) {
            seriesObj.data.push({
              value: null,
            });
          }
          seriesObj.data.push({
            value: 0,
            itemStyle: {
              opacity: 0,
            },
          });
          item.data.forEach((node, index) => {
            seriesObj.data.push({
              value: 5,
              name:
                (index
                  ? this.$t("nodeDetail.activity.reuse.sync")
                  : this.$t("nodeDetail.activity.reuse.copyT")) +
                node.newNodeKey,
              custom: node,
              label: {
                show: true,
                color: "#909399",
                fontSize: 14,
                formatter: function (params) {
                  return (
                    params.name +
                    "\n" +
                    new Date(node.createdAt).Format("yyyy.MM.dd")
                  );
                },
              },
            });
          });
          // 避免生成直线 补足一个点
          if (item.data.length === 1) {
            seriesObj.data.push({
              value: 5,
              itemStyle: {
                opacity: 0,
              },
            });
          }
          series.push(seriesObj);
          // 生成X轴
          for (
            let j = 0;
            j < (item.data.length + 1 < 3 ? 3 : item.data.length + 1);
            j++
          ) {
            xAxisData.push(i++);
          }
        }
      });
      this.initChart(xAxisData, series);
    },
    initChart(xAxisData, series) {
      const chart = document.getElementById("chartLine");
      const end = (8 / xAxisData.length) * 100;
      const new_options = {
        dataZoom: [
          {
            show: true,
            zoomLock: true,
            start: 0,
            end: end,
            filterMode: "none",
            brushSelect: false,
          },
        ],
        xAxis: {
          type: "category",
          data: xAxisData,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            show: false,
            // margin: -124,
          },
        },
        yAxis: {
          show: false,
          type: "value",
          min: -10,
          max: 10,
        },
        series: series,
      };
      this.lineChart = this.$echarts.init(chart);
      this.lineChart.setOption(new_options);
      this.lineChart.on("click", (param) => {
        let key = "";
        let pid = "";
        if (param.data.value === 5) {
          key = param.data.custom.newNodeKey;
          pid = param.data.custom.newProjectId;
        } else {
          key = param.data.custom.sourceNodeKey;
          pid = param.data.custom.sourceProjectId;
        }

        const new_route = this.$router.resolve({
          path: `/${pid}/nodes/key/${key}`,
        });
        window.open(new_route.href, "_blank");
      });
      this.loading = false;
      this.$nextTick(() => {
        let element = this.$refs["reuse_records_wrap"];
        this.observer = new ResizeObserver(() => {
          this.lineChart.resize();
        });
        this.observer.observe(element);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reuse_records_wrap {
  width: 100%;
  height: 400px;
  #chartLine {
    width: 100%;
    height: 100%;
  }
}
</style>
