<template>
  <div
    v-loading="loading"
    class="gerritcode_wrap"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <el-table :data="change_list" style="width: 95%">
      <el-table-column
        prop="title"
        :label="$t('nodeDetail.activity.gerrit.changeName')"
        show-overflow-tooltip
        min-width="150"
      >
        <template slot-scope="scope">
          <a :href="scope.row.url" target="_blank">{{
            scope.row.changeName
          }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="operator"
        :label="$t('nodeDetail.activity.gerrit.operator')"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.operator }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="$t('nodeDetail.activity.gerrit.createTime')"
        min-width="120"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="repo"
        :label="$t('nodeDetail.activity.gerrit.repo')"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.repo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="reviewList"
        :label="$t('nodeDetail.activity.gerrit.reviewList')"
        min-width="120"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.reviewer }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('nodeDetail.activity.gerrit.status1')"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="code_history_dialog"
      width="80%"
    >
      <el-table :data="code_history_list" style="width: 95%">
        <el-table-column
          prop="title"
          :label="$t('nodeDetail.activity.jenkins.dialog.email')"
          show-overflow-tooltip
          min-width="150"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.authorEmail }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="stageList" min-width="180">
          <label slot="label">commit&nbsp;id</label>
          <template slot-scope="scope">
            <span>{{ scope.row.commitId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="pipelineVulnerabilityCount"
          :label="$t('nodeDetail.activity.jenkins.dialog.summary')"
          min-width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.comment }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          :label="$t('nodeDetail.activity.jenkins.dialog.time')"
          show-overflow-tooltip
          min-width="180"
        >
          <template slot-scope="scope">
            <span>
              {{ new Date(scope.row.timestamp).Format("yyyy-MM-dd hh:mm:ss") }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getBuildHistory } from "@/network/jenkins/index";
import { getChangeList } from "@/network/gerrit/index";
export default {
  inject: ["nodeKey"],
  data() {
    return {
      loading: false,
      change_list: [],
      code_history_list: [],
      code_history_dialog: false,
    };
  },
  mounted() {
    this.get_change_list();
  },
  methods: {
    // gerritChangeList
    get_change_list() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
      };
      getChangeList(params)
        .then((res) => {
          this.change_list = res;
          res.forEach((item) => {
            let obj = {};
            let status_change = {
              IN_PROGRESS: this.$t("nodeDetail.activity.gerrit.status.st1"),
              MERGED: this.$t("nodeDetail.activity.gerrit.status.st2"),
              ABANDONED: this.$t("nodeDetail.activity.gerrit.status.st3"),
            };
            item.approvalDetail.forEach((i) => {
              if (i.username === item.operator) {
                item.operator =
                  i.value > 0
                    ? `${item.operator}(+${i.value})`
                    : `${item.operator}(${i.value})`;
              } else {
                obj[i.username] = i.value;
              }
            });
            let temp = [];
            item.reviewerList.forEach((i, index) => {
              if (obj[i]) {
                item.reviewerList.splice(
                  index,
                  1,
                  obj[i] > 0 ? `${i}(+${obj[i]})` : `${i}(${obj[i]})`
                );
              }
            });
            item.reviewer = item.reviewerList.join(", ");
            item.status = status_change[item.status];
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    show_code_history(row) {
      this.code_history_list = row.realChangeSet;
      this.code_history_dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.gerritcode_wrap {
  padding-bottom: 40px;
  ::v-deep {
    .el-table {
      border: 1px solid rgba(229, 229, 229, 1);
    }
  }
}
.result_icon {
  width: 30px;
  height: 30px;
  position: relative;
  transform: translateY(25%);
  margin-right: 5px;
}
</style>
