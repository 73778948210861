<template>
  <div class="node_description_row" @click.stop="item_blur">
    <collapse :title="$t('gantt.info1')">
      <div class="gantt_detail">
        <div class="gantt_row">
          <div class="gantt_title">{{ $t("gantt.info2") }}</div>

          <el-select
            v-if="fieldType === 'NAME'"
            v-model="gantt_name"
            clearable
            size="medium"
            @change="change_gantt"
          >
            <el-option
              v-for="item in showChartList"
              :key="item.ganttChartId"
              :label="item.name"
              :value="item.ganttChartId"
            >
            </el-option>
          </el-select>
          <!--
          <el-dropdown
            v-if="fieldType === 'NAME'"
            trigger="click"
            class="dropdown-link"
            @command="handleChart"
          >
            <span
              @click.stop
              style="
                display: inline-block;
                width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 30px;
              "
            >
              {{ showData.name }}
            </span>
            <i
              class="el-icon-error el-icon--right"
              style="cursor: pointer"
              @click.stop="deleteGanttNode"
              v-if="showData.name"
            ></i>
            <i class="el-icon-arrow-down el-icon--right"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in showChartList"
                :key="item.ganttChartId"
                :command="item"
              >
                <div
                  style="
                    width: 210px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.name }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <div
            v-else
            class="not_edit"
            v-limits-of-authority="'NODE_EDIT'"
            @click.stop="edit_name"
          >
            {{ showData.name }}
          </div>
        </div>

        <div class="gantt_row">
          <div class="gantt_title">{{ $t("gantt.startTime") }}</div>
          <div v-if="fieldType === 'START_TIME'" @click.stop>
            <el-date-picker
              v-model="showData.startTime"
              size="mini"
              type="date"
              :placeholder="$t('addMindMap.tip7')"
              align="right"
              :disabled="!ganttItemInfo.nodeKey"
              @change="timeChange(showData)"
            >
            </el-date-picker>
          </div>
          <div
            v-else
            v-limits-of-authority="'NODE_EDIT'"
            :class="showData.name == '' ? 'disable_edit' : 'not_edit'"
            @click.stop="edit_startTime"
          >
            {{ (showData.startTime + "").slice(0, 10) }}
          </div>
        </div>
        <div class="gantt_row">
          <div class="gantt_title">{{ $t("gantt.endTime") }}</div>
          <div v-if="fieldType === 'DUE_TIME'" @click.stop>
            <el-date-picker
              v-model="showData.dueTime"
              size="mini"
              type="date"
              :disabled="!ganttItemInfo.nodeKey"
              :placeholder="$t('addMindMap.tip7')"
              align="right"
              @change="timeChange(showData)"
            >
            </el-date-picker>
          </div>
          <div
            v-else
            v-limits-of-authority="'NODE_EDIT'"
            :class="showData.name == '' ? 'disable_edit' : 'not_edit'"
            @click.stop="edit_endTime"
          >
            {{ (showData.dueTime + "").slice(0, 10) }}
          </div>
        </div>
        <div class="gantt_alone_row">
          <div class="gantt_title">{{ $t("gantt.workHours") }}</div>
          <div class="edit_alone_time" v-limits-of-authority="'NODE_EDIT'">
            <!-- <div class="edit_add_time">
              {{ helpShowNumber(parseInt(showData.planManHour) / 8000)
              }}{{ $t("gantt.info4") }}
            </div> -->
            <el-popover
              :ref="'popover-work'"
              placement="right"
              width="330"
              :disabled="!popWorkVisible"
            >
              <div>
                <el-form
                  hide-required-asterisk
                  :model="ruleForm"
                  :rules="ruleinput"
                >
                  <el-form-item
                    :label="$t('gantt.workHours')"
                    label-width="80"
                    prop="addWorkProgressNum"
                  >
                    <div class="progress_dialog">
                      <el-input-number
                        v-model="ruleForm.addWorkProgressNum"
                        :min="0"
                        :max="999"
                        size="small"
                        controls-position="right"
                        style="width: 300px"
                      ></el-input-number>
                      <el-dropdown
                        trigger="click"
                        class="dropdown-link"
                        style="margin-left: 10px"
                        @command="handleWorkType"
                      >
                        <span
                          style="
                            display: inline-block;
                            width: 80%;
                            white-space: nowrap;
                            line-height: 40px;
                          "
                          @click.stop
                        >
                          {{ progressworkType }}
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            v-for="(item, index) in progressTypeList.filter(
                              (a) => {
                                return a.name !== progressworkType;
                              }
                            )"
                            :key="index"
                            :command="item"
                          >
                            <div>
                              {{ item.name }}
                            </div>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </el-form-item>
                  <el-button
                    type="primary"
                    style="float: right"
                    @click="changeProgressWorkButton"
                    >{{ $t("btn.confirmBtn") }}</el-button
                  >
                </el-form>
              </div>

              <div
                class="edit_add_time"
                slot="reference"
                :style="{
                  cursor: showData.name == '' ? 'not-allowed' : 'pointer',
                }"
                @click.stop="progressWorkShow"
              >
                {{ helpShowNumber(parseInt(showData.planManHour) / 8000)
                }}{{ $t("gantt.info4") }}
              </div>

              <!-- <i
                slot="reference"
                class="iconfont icon-plan_time"
                :style="{
                  cursor: showData.name == '' ? 'not-allowed' : 'pointer',
                }"
                style="
                  width: 18px;
                  margin: 0 4px;
                  font-size: 18px;
                  color: rgb(128, 128, 128);
                "
                @click.stop="progressWorkShow"
              ></i> -->
            </el-popover>
          </div>
        </div>
        <div class="gantt_alone_row gantt_alone_row1">
          <div class="gantt_title">{{ $t("gantt.info3") }}</div>
          <div class="edit_time" v-limits-of-authority="'NODE_EDIT'">
            <!-- <div class="edit_add_time">
              {{ helpShowNumber(parseInt(showData.manHour) / 8000)
              }}{{ $t("gantt.info4") }}
            </div> -->
            <el-popover
              :ref="'popover-gantt'"
              placement="right"
              width="330"
              :disabled="!popVisible"
            >
              <div>
                <el-form
                  hide-required-asterisk
                  :model="ruleForm"
                  :rules="ruleinput"
                >
                  <el-form-item
                    :label="$t('gantt.info8')"
                    label-width="80"
                    prop="addProgressNum"
                  >
                    <div class="progress_dialog">
                      <el-input-number
                        v-model="ruleForm.addProgressNum"
                        :min="0"
                        :max="999"
                        size="small"
                        controls-position="right"
                        style="width: 300px"
                      ></el-input-number>
                      <el-dropdown
                        trigger="click"
                        class="dropdown-link"
                        style="margin-left: 10px"
                        @command="handleType"
                      >
                        <span
                          style="
                            display: inline-block;
                            width: 80%;
                            white-space: nowrap;
                            line-height: 40px;
                          "
                          @click.stop
                        >
                          {{ progressType }}
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            v-for="(item, index) in progressTypeList.filter(
                              (a) => {
                                return a.name !== progressType;
                              }
                            )"
                            :key="index"
                            :command="item"
                          >
                            <div>
                              {{ item.name }}
                            </div>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </el-form-item>
                  <el-button
                    type="primary"
                    style="float: right"
                    @click="addProgressByTimeButton"
                    >{{ $t("btn.confirmBtn") }}</el-button
                  >
                </el-form>
              </div>

              <div
                class="edit_add_time"
                slot="reference"
                :style="{
                  cursor: showData.name == '' ? 'not-allowed' : 'pointer',
                }"
                @click.stop="progressShow()"
              >
                {{ helpShowNumber(parseInt(showData.manHour) / 8000)
                }}{{ $t("gantt.info4") }}
              </div>

              <!-- <img
                slot="reference"
                src="@/assets/img/addTime.png"
                alt=""
                style="width: 18px; margin: 0 4px; margin-top: 5px"
                :style="{
                  cursor: showData.name == '' ? 'not-allowed' : 'pointer',
                }"
                @click.stop="progressShow()"
              /> -->
            </el-popover>

            <img
              src="@/assets/img/arrow.png"
              alt=""
              style="width: 16px; margin: 0 10px; cursor: pointer"
              @click.stop="clickCalcTimeProgress()"
            />
            <div class="percent_all">
              <el-input-number
                v-model="showData.progress"
                :min="0"
                :max="100"
                size="mini"
                controls-position="right"
                style=""
                :disabled="!ganttItemInfo.nodeKey"
                @change="changeProgressButton"
              ></el-input-number>
            </div>
            &nbsp; %
          </div>
        </div>
      </div>
    </collapse>
  </div>
</template>

<script>
import Collapse from "./Collapse.vue";
import moment from "moment";
import {
  getAllGanttChart,
  get_ganttnode_by_node,
  move_ganttnode_to_newchart,
  add_ganttnode_by_nodekey,
  deleteNodeList,
  changeAllTime,
  ClearGanttOffsetTime,
  changeStartTime,
  changeDueTime,
  changeProgress,
  calcTimeProgress,
  addTimeProgress,
  addWorkTimeProgress,
} from "@/network/gantt/index.js";

//临时代码
import config from "@/../config/index";
export default {
  components: { Collapse },
  props: {
    nodeKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fieldType: "",
      showData: {
        name: "",
        startTime: "",
        dueTime: "",
        allTime: "",
        progress: 0,
        manHour: 0,
        planManHour: 0,
      },
      deletingNode: false,
      ganttItemInfo: {
        dueTime: "",
        ganttChartUuid: "",
        ganttItemUuid: "",
        nodeKey: "",
        progress: "",
        manHour: 0,
      },
      ruleForm: {
        addWorkProgressNum: 0,
        addProgressNum: 0,
      },
      ruleinput: {
        addWorkProgressNum: [
          {
            required: true,
            message: this.$t("addEditCopy.num"),
            trigger: "blur",
          },
        ],
        addProgressNum: [
          {
            required: true,
            message: this.$t("addEditCopy.num"),
            trigger: "blur",
          },
        ],
      },
      progressType: this.$t("gantt.info6"),
      progressworkType: this.$t("gantt.info6"),
      progressTypeList: [
        {
          name: this.$t("gantt.info6"),
          type: "hour",
        },
        {
          name: this.$t("gantt.info4"),
          type: "hour",
        },
        {
          name: this.$t("gantt.info5"),
          type: "hour",
        },
      ],
      progressData: {},
      formLabelWidth: "120px",
      popVisible: false,
      popWorkVisible: false,
      gantt_name: "",
    };
  },
  computed: {
    showChartList() {
      if (this.chartList && this.chartList.length) {
        return this.chartList;
      }
      return [];
    },
  },
  watch: {},
  mounted: function () {
    this.initGanttNode();
  },
  methods: {
    change_gantt() {
      if (this.gantt_name) {
        this.showChartList.forEach((item) => {
          if (item.ganttChartId == this.gantt_name) {
            this.handleChart(item);
            this.showData.name = item.name;
          }
        });
      } else {
        this.showData.name = "";
        this.deleteGanttNode();
      }
    },
    async initGanttNode() {
      let chartList = await getAllGanttChart(this.get_pid());
      let ganttInfo = await get_ganttnode_by_node(this.get_pid(), this.nodeKey);
      this.ganttItemInfo = ganttInfo.ganttItemInfo || {};
      if (chartList && chartList.length) {
        this.chartList = chartList;
        chartList.forEach((item) => {
          if (item.ganttChartId == this.ganttItemInfo.ganttChartUuid) {
            this.showData.name = item.name || "";
            this.gantt_name = item.ganttChartId || "";
            this.showData.startTime = ganttInfo.ganttItemInfo.startTime || "";
            this.showData.dueTime = ganttInfo.ganttItemInfo.dueTime || "";
            this.showData.progress = ganttInfo.ganttItemInfo.progress || "";
            this.showData.manHour = ganttInfo.ganttItemInfo.manHour || 0;
            this.showData.planManHour =
              ganttInfo.ganttItemInfo.planManHour || 0;
          }
        });
      }
      this.chartDrop = [];
    },
    handleChart(item) {
      let ganttChartUuid = item.ganttChartId || "";
      let ganttItemUuid = this.ganttItemInfo.ganttItemUuid || "";
      let sourceChartUuid = this.ganttItemInfo.ganttChartUuid || "";
      let data = [this.nodeKey];
      if (this.showData.name) {
        //移动
        move_ganttnode_to_newchart(
          this.get_pid(),
          ganttItemUuid,
          ganttChartUuid,
          sourceChartUuid
        ).then((res) => {
          this.$message.success(this.$t("gantt.info10"));
          this.initGanttNode();
        });
      } else {
        add_ganttnode_by_nodekey(this.get_pid(), ganttChartUuid, data).then(
          (res) => {
            this.$message.success(this.$t("gantt.info11"));
            this.initGanttNode();
          }
        );
      }
    },
    changeProgressWorkButton(data) {
      if (this.ruleForm.addWorkProgressNum >= 0) {
        if (this.progressworkType === this.$t("gantt.info6")) {
          this.ruleForm.addWorkProgressNum *= 1000;
        } else if (this.progressworkType === this.$t("gantt.info4")) {
          this.ruleForm.addWorkProgressNum *= 8000;
        } else {
          this.ruleForm.addWorkProgressNum *= 40000;
        }
        this.ruleForm.addWorkProgressNum = Math.round(
          this.ruleForm.addWorkProgressNum
        );
        let params = {
          projectId: this.get_pid(),
          ganttItemUuid: this.ganttItemInfo.ganttItemUuid,
          planManHour: this.ruleForm.addWorkProgressNum,
        };
        this.popWorkVisible = false;
        addWorkTimeProgress(params)
          .then((res) => {
            this.initGanttNode();
            this.ruleForm.addWorkProgressNum = 0;
          })
          .catch((err) => {});
      }
    },
    handleWorkType(item) {
      this.progressworkType = item.name;
    },
    handleType(item) {
      this.progressType = item.name;
    },
    item_blur() {
      this.fieldType = "";
    },
    timeChange(data) {
      let startTime = data.startTime;
      let dueTime = data.dueTime;
      if (!startTime && dueTime) {
        // console.log("有一个为空");
        data.dueTime = moment(data.dueTime).format();
        return;
      } else if (startTime && !dueTime) {
        // console.log("有一个为空");
        data.startTime = moment(data.startTime).format();
        return;
      } else if (!startTime && !dueTime) {
        let param = {
          projectId: this.get_pid(),
          ganttItemUuid: this.ganttItemInfo.ganttItemUuid,
        };
        ClearGanttOffsetTime(param);
        return;
      }
      if (startTime && dueTime && startTime > dueTime) {
        // console.log("结束时间快于开始时间");
        let timer = data.startTime.getTime();
        data.dueTime = new Date(timer);
      }
      data.startTime = moment(data.startTime).format();
      data.dueTime = moment(data.dueTime).format();
      let timeParam = {
        projectId: this.get_pid(),
        ganttItemUuid: this.ganttItemInfo.ganttItemUuid,
        data: {
          startTime: data.startTime,
          dueTime: data.dueTime,
        },
      };
      changeAllTime(timeParam).then((res) => {
        this.$message.success(this.$t("gantt.info12"));
      });
    },
    changeProgressButton() {
      let params = {
        projectId: this.get_pid(),
        ganttItemUuid: this.ganttItemInfo.ganttItemUuid,
        progress: this.showData.progress,
      };
      params.progress = Math.round(params.progress);

      changeProgress(params)
        .then((res) => {
          this.initGanttNode();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteGanttNode() {
      let params = {
        projectId: this.get_pid(),
        ganttItemIdList: [this.ganttItemInfo.ganttItemUuid],
        ganttChartId: this.ganttItemInfo.ganttChartUuid,
      };
      deleteNodeList(params).then((res) => {
        this.$message.success(this.$t("gantt.info13"));
        this.showData = {
          name: "",
          startTime: "",
          dueTime: "",
          allTime: "",
        };
        this.initGanttNode();
      });
    },
    edit_name() {
      this.fieldType = "NAME";
    },
    edit_startTime() {
      if (this.showData.name == "") {
        this.choseGantTip();
        return;
      }
      this.fieldType = "START_TIME";
    },
    edit_endTime() {
      if (this.showData.name == "") {
        this.choseGantTip();
        return;
      }
      this.fieldType = "DUE_TIME";
    },
    helpShowNumber(num) {
      if (num || num === 0 || num === "0") {
        num = parseFloat(num);
      } else {
        return "0";
      }
      if (num % 1 < 0.1) {
        return Math.floor(num);
      } else {
        return num.toFixed(1);
      }
    },
    addProgressByTimeButton() {
      if (this.ruleForm.addProgressNum >= 0) {
        if (this.progressType === this.$t("gantt.info6")) {
          this.ruleForm.addProgressNum *= 1;
        } else if (this.progressType === this.$t("gantt.info4")) {
          this.ruleForm.addProgressNum *= 8;
        } else {
          this.ruleForm.addProgressNum *= 40;
        }
        this.ruleForm.addProgressNum = Math.round(this.ruleForm.addProgressNum);
        let params = {
          projectId: this.get_pid(),
          ganttItemUuid: this.ganttItemInfo.ganttItemUuid,
          manHour: this.ruleForm.addProgressNum,
        };
        this.popVisible = false;
        addTimeProgress(params).then((res) => {
          this.initGanttNode();
          this.ruleForm.addProgressNum = 0;
        });
      }
    },
    closeProgressDialog() {
      this.ruleForm.addProgressNum = 0;
    },
    clickCalcTimeProgress() {
      let params = {
        projectId: this.get_pid(),
        ganttItemUuid: this.ganttItemInfo.ganttItemUuid,
      };
      calcTimeProgress(params).then((res) => {
        this.$message.success(this.$t("gantt.info14"));
        this.initGanttNode();
      });
    },
    //progres弹窗
    progressWorkShow() {
      if (this.showData.name !== "") {
        this.closeWorkPop();
        this.popWorkVisible = true;
      } else {
        this.choseGantTip();
        this.popWorkVisible = false;
      }
    },
    progressShow() {
      if (this.showData.name !== "") {
        this.closePopover();
        this.popVisible = true;
      } else {
        this.choseGantTip();
        this.popVisible = false;
      }
    },
    closeWorkPop(index, item) {
      this.ruleForm.addWorkProgressNum = 0;
      for (const key in this.$refs) {
        if (key.indexOf("popover-gantt") !== -1) {
          this.$refs[key] && this.$refs[key].doClose();
        }
      }
    },
    closePopover(index, item) {
      //每次只显示一个Popover弹窗 使用doClose()
      // 遍历所有的refs，判断索引名中是否有 'popover-' ;如果有，都使用doClose关掉
      for (const key in this.$refs) {
        if (key.indexOf("popover-work") !== -1) {
          this.$refs[key].doClose();
        }
      }
    },
    choseGantTip() {
      if (this.gantt_name === "") {
        this.$message({
          type: "warning",
          message: this.$t("gantt.nochoseGrantTip"),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 表格样式修改
$tree_node_height: 50px;
$tree_node_center: 30px;
$tree_node_name: 200px;
$tree_node_key: 160px;
.node_description_row {
  background-color: #fff;
  padding: 20px 10px; /*no*/
  border-radius: 8px;
  padding-top: 20px;
  padding-left: 10px;
  .gantt_detail {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    .gantt_alone_row {
      width: 33%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: left;
      .gantt_title {
        width: 140px;
        white-space: nowrap;
      }
    }
    .gantt_alone_row1 {
      width: 35%;
    }
    .gantt_row {
      width: 33%;
      display: flex;
      align-items: center;
      // justify-content: center;
      .gantt_title {
        min-width: 130px;
        white-space: nowrap;
      }
      ::v-deep .el-input__inner {
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color 0.2s
          cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }
}
::v-deep .el-input__prefix {
  display: none;
}
.edit_alone_time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // width: 16%;
}

.edit_time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  img {
    width: 10%;
  }
  .percent_all {
    // border: 1px solid #a6a6a6;
    width: 30%;
    border-radius: 8px;
    // line-height: $tree_node_center;
    // height: $tree_node_center;
    ::v-deep .el-input__inner {
      // width: 70% !important;
      // padding: 0 !important;
      padding-left: 0;
      padding-right: 10px;
    }
    ::v-deep .el-input-number {
      width: 100%;
    }
    ::v-deep .el-input-number__increase,
    ::v-deep .el-input-number__decrease {
      width: 20%;
    }
  }
}
.disable_edit {
  cursor: not-allowed;
  width: 200px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-indent: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background-color: #e5e5e5;
  }
}
.not_edit {
  cursor: pointer;
  width: 200px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-indent: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background-color: #e5e5e5;
  }
}
.dropdown-link {
  width: 250px;
  height: 30px;
  padding-left: 6px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(166, 166, 166, 1);
  border-radius: 4px;
  user-select: none;
  ::v-deep {
    .el-icon--right {
      float: right;
      vertical-align: middle;
      line-height: 40px;
    }
  }
}
.dialog_progress {
  .table_tab_progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
//dialog样式修改
::v-deep .el-dialog {
  width: 25%;
  .el-form-item__label {
    text-align: left;
  }
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    display: none;
  }
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
    content: "*";
    color: #f56c6c;
    margin-left: 4px;
  }
  .el-textarea {
    textarea {
      min-height: 150px !important;
    }
  }
}
.progress_dialog {
  display: flex;
  height: 40px;
  /* no */
  align-items: center;
}
</style>
