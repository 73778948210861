<template>
  <div>
    <el-dialog
      :title="this.$t('addNodeDetail.message5')"
      :visible="merge_visible"
      width="40%"
      :close-on-click-modal="false"
      :show-close="true"
      :append-to-body="true"
      @close="closeDialog"
      style="font-family: '思源黑体'; font-size: 16px"
    >
      <div
        class="modal_line"
        :style="{
          'margin-bottom': merge_type === '1' ? '24px' : 0,
        }"
      >
        <el-form ref="request_form" :model="request_form" label-width="110px">
          <el-form-item prop="name">
            <div slot="label">
              <span style="color: red">*</span>{{ $t("addNodeDetail.lab1") }}
            </div>
            <el-input
              v-if="merge_type === '4'"
              v-model="request_form.name"
            ></el-input>
            <div v-else style="text-align: left; margin-left: 10px">
              {{ request_form.name }}
            </div>
          </el-form-item>
          <el-form-item prop="reviewerList">
            <div slot="label">
              <span style="color: red">*</span>{{ $t("addNodeDetail.lab2") }}
            </div>
            <div
              v-if="(merge_type === '4' && switchFlag) || merge_type !== '4'"
              class="reviewer_list"
            >
              <span
                v-for="(item, index) in build_user_list"
                :key="index"
                class="reviewer"
                >{{ item.label }}</span
              >
              <label
                v-if="merge_type !== '4' && build_user_list.length"
                style="
                  font-size: 16px;
                  color: rgba(42, 130, 228, 1);
                  float: right;
                  cursor: pointer;
                "
                @click="show_review_results"
              >
                {{ $t("addNodeDetail.lab3") }}
              </label>
              <span
                v-if="!switchFlag && !build_user_list.length"
                style="color: red"
                >{{ $t("addNodeDetail.info") }}</span
              >
            </div>
            <div v-if="merge_type === '4' && !switchFlag">
              <el-select
                v-model="request_form.reviewerList"
                :placeholder="$t('addNodeDetail.place')"
                multiple
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="opt in user_list"
                  v-show="!opt.deleted"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item :label="$t('addNodeDetail.lab4')">
            <el-input
              v-if="merge_type === '4'"
              v-model="request_form.description"
              type="textarea"
            ></el-input>
            <div v-else style="text-align: left; margin-left: 10px">
              {{ request_form.description }}
            </div>
          </el-form-item>
          <el-form-item prop="relation_node">
            <div slot="label">{{ $t("mindmap.ChangeImpactScope") }}</div>
            <div style="text-align: left; margin-left: 10px">
              <span
                v-for="(item, index) in relation_node"
                :key="index"
                style="color: rgb(42, 130, 228); cursor: pointer"
                @click="jumpToNode(item)"
              >
                {{ item.nodeKey }},
              </span>
            </div>
          </el-form-item>
          <el-form-item prop="impactAnalysis">
            <div slot="label">
              <span style="color: red">*</span
              >{{ $t("mindmap.ChangeImpactAnalysis") }}
            </div>
            <el-input
              v-if="merge_type === '4'"
              v-model="request_form.impactAnalysis"
              type="textarea"
            ></el-input>
            <div v-else style="text-align: left; margin-left: 10px">
              {{ request_form.impactAnalysis }}
            </div>
          </el-form-item>
        </el-form>
        <div class="table_border">
          <el-table
            :height="300"
            :data="request_tableData"
            header-row-class-name="header_row_class"
            style="width: 100%"
          >
            <el-table-column
              prop="field"
              align="center"
              :label="$t('addNodeDetail.field')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="old"
              align="center"
              :label="$t('addNodeDetail.now')"
              width="180"
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.field === $t('addNodeDetail.desc')"
                  v-html="scope.row.old"
                ></span>
                <span v-else-if="scope.row.fieldType == 'DRAWIO'">
                  <el-image
                    v-if="scope.row.old && scope.row.old.length === 1"
                    style="width: 100px; height: 100px"
                    :src="scope.row.old[0]"
                    :preview-src-list="scope.row.old"
                  >
                  </el-image>
                  <span
                    v-if="scope.row.old && scope.row.old.length > 1"
                    class="img-viewer-text"
                    @click="showImgViewer(scope.row.old)"
                    >+{{ scope.row.old.length }}</span
                  >
                </span>
                <span v-else-if="scope.row.fieldType == 'attachments'">
                  <file-list
                    ref="fileList"
                    :files="scope.row.old"
                    :deletebtn="false"
                    :show_all="true"
                  ></file-list>
                </span>

                <span v-else-if="scope.row.fieldType === 'DATE'">
                  {{
                    scope.row.old
                      ? new Date(scope.row.old).Format("yyyy-MM-dd")
                      : ""
                  }}</span
                >
                <span v-else-if="scope.row.fieldType === 'SELECT_MULTI'">
                  {{
                    typeof scope.row.old === "object"
                      ? scope.row.old.join("，")
                      : scope.row.old
                  }}
                </span>
                <span v-else>{{
                  scope.row.fieldType === "SINGLE_USER_SELECT" ||
                  scope.row.fieldType === "MULTI_USER_SELECT"
                    ? getUserNickNames(scope.row.old)
                    : scope.row.old
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="new"
              align="center"
              :label="$t('addNodeDetail.change')"
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.field === $t('addNodeDetail.desc')"
                  v-html="scope.row.new"
                ></span>
                <span v-else-if="scope.row.fieldType == 'DRAWIO'">
                  <el-image
                    v-if="scope.row.new && scope.row.new.length === 1"
                    style="width: 100px; height: 100px"
                    :src="scope.row.new[0]"
                    :preview-src-list="scope.row.new"
                  >
                  </el-image>
                  <span
                    v-if="scope.row.new && scope.row.new.length > 1"
                    class="img-viewer-text"
                    @click="showImgViewer(scope.row.new)"
                    >+{{ scope.row.new.length }}</span
                  >
                </span>
                <span v-else-if="scope.row.fieldType == 'attachments'">
                  <file-list
                    ref="fileList"
                    :files="scope.row.new"
                    :deletebtn="false"
                    :show_all="true"
                  ></file-list>
                </span>

                <span v-else-if="scope.row.fieldType === 'DATE'">
                  {{
                    scope.row.new
                      ? new Date(scope.row.new).Format("yyyy-MM-dd")
                      : ""
                  }}</span
                >
                <span v-else-if="scope.row.fieldType === 'SELECT_MULTI'">
                  {{
                    typeof scope.row.new === "object"
                      ? scope.row.new.join("，")
                      : scope.row.new
                  }}
                </span>
                <span v-else>
                  {{
                    scope.row.fieldType === "SINGLE_USER_SELECT" ||
                    scope.row.fieldType === "MULTI_USER_SELECT"
                      ? getUserNickNames(scope.row.new)
                      : scope.row.new
                  }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-dialog
        :title="$t('addNodeDetail.res')"
        width="25%"
        :close-on-click-modal="false"
        :show-close="true"
        :visible.sync="review_results_view"
        :append-to-body="true"
        custom-class="edit_dialog"
      >
        <div class="review_records_class modal_line">
          <div>
            <span>{{ $t("addNodeDetail.people") }}</span>
            <span>{{ $t("addNodeDetail.res") }}</span>
            <span>{{ $t("addNodeDetail.comment") }}</span>
          </div>
          <div v-for="(item, index) in review_records_list" :key="index">
            <span>{{ matchUserInfo(item.createdBy).nickname }}</span>
            <span>
              <span v-if="item.approved">
                <i class="iconfont icon-approved"></i>
              </span>
              <span v-else>
                <i class="iconfont icon-refuse"></i>
              </span>
              {{
                item.approved
                  ? $t("addNodeDetail.pass")
                  : $t("addNodeDetail.rej")
              }}
            </span>
            <span>{{ item.message }}</span>
          </div>
        </div>
        <span slot="footer" style="padding-right: 100px"> </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import {
  get_allProjectMember,
  get_node_use_node_key,
  get_relation_data,
} from "@/network/node/index.js";
import FileList from "@/components/mindmap/node_detail/FileList";
import {
  get_copies_detail,
  get_requests_detail,
  reviewRecords,
} from "@/network/merge/index";
import { download_drawio } from "@/network/fileOperation/index.js";
export default {
  components: { FileList },
  props: {
    merge_visible: {
      type: Boolean,
      default: false,
    },
    changeRequestId: {
      type: String,
      default: "",
    },
    reviewerListAll: {
      type: Array,
      default() {
        return [];
      },
    },
    nodeKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      request_form: {},
      merge_type: "2",
      switchFlag: false,
      reviewerList: [],
      review_records_list: [],
      review_results_view: false,
      user_list: [],
      edit_form: {},
      relation_node: [],
      node_info: {},
    };
  },
  computed: {
    request_tableData() {
      const arr = [];
      this.edit_form.systemFields &&
        this.edit_form.systemFields.forEach((sys) => {
          let obj = {};
          if (sys.fieldName === "topic") {
            obj = {
              field: this.$t("addNodeDetail.message6"),
              old: this.node_info.topic || "",
              new: sys.value || "",
            };
          } else if (sys.fieldName === "description") {
            obj = {
              field: this.$t("addNodeDetail.desc"),
              old: this.node_info.description || "",
              new: sys.value || "",
            };
          } else if (sys.fieldName === "attachments") {
            obj = {
              field: this.$t("addNodeDetail.attachments"),
              fieldType: "attachments",
              old: this.node_info.attachments || "",
              new: sys.value || "",
            };
          }

          arr.push(obj);
        });
      this.edit_form.customFields &&
        this.edit_form.customFields.forEach((cus) => {
          if (cus.fieldType === "DRAWIO") {
            let record = {};
            record["beforeSrcList"] = [];
            record["afterSrcList"] = [];
            const reader = function (file) {
              return new Promise(function (resolve, reject) {
                const reader = new FileReader();
                reader.onload = function () {
                  resolve(reader.result);
                };
                reader.readAsText(file);
              });
            };
            const asyncFuncs = [];
            const getImgs = async function (list, type) {
              for (const item of list) {
                asyncFuncs.push(
                  download_drawio(item.data).then((res) => {
                    return { res, type };
                  })
                );
                // blobToDataURL(i, (baseData)=>{
                //   record[`${type}SrcList`].push(baseData);
                // })
              }
            };
            let oldItem = this.node_info.content.find((item) => {
              return item.name === cus.name;
            });
            oldItem = oldItem ? oldItem.value : null;
            const curItem = cus.value;
            if (oldItem) {
              getImgs(
                typeof oldItem == "string" ? JSON.parse(oldItem) : oldItem,
                "before"
              );
            }
            if (curItem) {
              getImgs(
                typeof curItem == "string" ? JSON.parse(curItem) : curItem,
                "after"
              );
            }
            Promise.all(asyncFuncs).then((res) => {
              const afuncs = [];
              res.forEach((item) => {
                afuncs.push(
                  reader(item.res).then((res) => {
                    return { res, type: item.type };
                  })
                );
              });
              Promise.all(afuncs).then((res) => {
                res.forEach((item) => {
                  this.$nextTick(() => {
                    record[`${item.type}SrcList`].push(item.res);
                  });
                });
              });
            });

            const obj = {
              field: cus.name,
              old: record["beforeSrcList"],
              new: record["afterSrcList"],
              fieldType: cus.fieldType,
            };
            arr.push(obj);
          } else {
            const oldValue = this.node_info.content.find((item) => {
              return item.name === cus.name;
            });
            const obj = {
              field: cus.name,
              old: oldValue ? oldValue.value : "",
              new: cus.value || "",
              fieldType: cus.fieldType,
            };
            arr.push(obj);
          }
        });
      return arr;
    },
    build_user_list() {
      const user = [];
      this.reviewerList.forEach((reviewer) => {
        this.user_list.forEach((one) => {
          if (one.value === reviewer) {
            user.push(one);
          }
        });
      });
      return user;
    },
  },
  mounted() {
    if (this.changeRequestId != "") {
      this.get_copies_detail_table();
    }
    // if (this.nodeKey && this.nodeKey != "") {
    //   get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
    //     this.node_info = res;
    //   });
    // }
    this.get_allProjectMember();
  },
  watch: {
    merge_visible() {
      if (this.changeRequestId != "") {
        this.get_copies_detail_table();
      }
    },
    changeRequestId(newVal) {
      if (this.changeRequestId != "") {
        this.get_copies_detail_table();
      }
    },
    nodeKey(newVal) {
      if (this.nodeKey && this.nodeKey != "") {
        get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
          this.node_info = res;
        });
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    getchangeRequestConfig() {
      getchangeRequestConfig(this.get_pid()).then((res) => {
        this.reviewerList = res.reviewerList;
        this.switchFlag = res.switchFlag;
        this.threshold = res.rule.threshold;
      });
    },
    showImgViewer(srcList) {
      this.currentSrcList = srcList;
      this.isViewerVisible = true;
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    jumpToNode(params) {
      const { href } = this.$router.resolve({
        path: `/${params.projectId}/nodes/key/${params.nodeKey}`,
      });
      window.open(href, "_blank");
    },
    // 包括离职的
    getUserNickNames(name) {
      let res = [];
      if (typeof name == "string" && name != "") {
        return this.matchUserInfo(name).nickname;
      }
      if (typeof name != "string" && name && name.length > 0) {
        name.forEach((item) => {
          res.push(this.matchUserInfo(item).nickname);
        });
        return res.join(", ");
      }
      return name;
    },

    get_user_name(params) {
      let newarr = typeof params === "object" ? [] : "";
      typeof params === "object"
        ? params.forEach((item) => {
            this.user_list.forEach((user) => {
              if (user.value === item) {
                newarr.push(user.label);
              }
            });
          })
        : this.user_list.forEach((user) => {
            if (user.value === params) {
              newarr = user.label;
            }
          });
      return typeof newarr === "object" ? newarr.join("，") : newarr;
    },
    get_allProjectMember() {
      get_allProjectMember(this.get_pid()).then((res) => {
        let my = {};
        let li = [];
        res.forEach((ele) => {
          let obj = {
            label: ele.nickname,
            value: ele.accountId,
            userAccountId: ele.accountId,
            avatar: ele.avatar,
            deleted: ele.deleted,
          };
          ele.accountId == this.$store.getters.userAccountId
            ? (my = obj)
            : li.push(obj);
        });
        this.user_list = [];
        this.user_list.push(my);
        this.user_list.push(...li);
      });
    },
    show_review_results() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
        changeRequestId: this.changeRequestId,
      };
      reviewRecords(params).then((res) => {
        this.review_records_list = res;
        this.review_results_view = true;
      });
    },
    get_relation_data() {
      get_relation_data(this.get_pid(), this.nodeKey)
        .then((res) => {
          this.relation_node = res.nodeRelationGraphList.filter((item) => {
            return item.nodeKey !== this.nodeKey;
          });
        })
        .catch((err) => console.log(err));
    },
    get_copies_detail(copyId) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
        copyId: copyId,
      };
      get_copies_detail(params).then((res) => {
        this.edit_form = res;
        this.creat_visible = false;
        this.checked_copyid = res.copyId;
        this.edit_visible = true;
      });
    },
    get_copies_detail_table() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
        changeRequestId: this.changeRequestId,
      };
      get_requests_detail(params).then((res) => {
        this.request_form = res;
        if (!this.switchFlag) {
          this.reviewerList = res.reviewerList.length
            ? res.reviewerList
            : this.reviewerListAll;
        }
        const params = {
          projectId: this.get_pid(),
          nodeKey: this.nodeKey,
          copyId: res.copyId,
        };
        get_copies_detail(params).then((res) => {
          this.edit_form = res;
          // this.merge_visible = true;
          this.$nextTick(() => {
            this.$refs["request_form"]
              ? this.$refs["request_form"].clearValidate()
              : "";
          });
        });
        this.get_relation_data();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.reviewer_list {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.reviewer {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  background: rgba(229, 229, 229, 1);
  padding: 0 6px;
  margin: 4px;
  border-radius: 4px;
}
.header_row_class {
  font-weight: bold;
}
.edit_dialog_wrap {
  padding: 0 100px;
  & > p {
    color: rgba(0, 0, 0, 1);
    font-size: 24px;
  }
  & > div {
    height: 70vh;
    background-color: #fff;
    border-radius: 8px;
    overflow-y: scroll;
  }
}
.review_records_class {
  & > div {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    &:first-of-type {
      font-size: 16px;
      line-height: 30px;
    }
    & > span {
      display: inline-block;
      width: 33%;
      line-height: 20px;
      text-align: center;
    }
  }
}
</style>
