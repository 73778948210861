import request from "@/utils/request"
import serviceConfig from '@/network/serviceConfig'
// 获取副本列表
export function get_node_copies(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/nodeCopies`,
    method: "get",
  })
}
// 创建副本
export function set_node_copies(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/nodeCopies`,
    method: "post",
    data: params.data
  })
}
// 获取节点副本详情
export function get_copies_detail(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/nodeCopies/${params.copyId}`,
    method: "get"
  })
}
// 修改节点副本
export function edit_copies_detail(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/nodeCopies/${params.copyId}`,
    method: "put",
    data: params.data
  })
}
// 删除节点副本
export function delete_copies_detail(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/nodeCopies/${params.copyId}`,
    method: "DELETE"
  })
}
// 获取合并请求列表
export function get_requests_list(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/changeRequests?showType=${params.showType}`,
    method: "get"
  })
}
// 获取合并请求详情
export function get_requests_detail(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/changeRequests/${params.changeRequestId}`,
    method: "get"
  })
}
// 提交合并请求
export function commit_request(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/nodeCopies/${params.copyId}/commit`,
    method: "POST",
    data: params.data
  })
}
// 获取评审详情
export function reviewRecords(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/changeRequests/${params.changeRequestId}/reviewRecords`,
    method: "GET"
  })
}
// 评审通过或者拒绝
export function setreviewRecords(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/changeRequests/${params.changeRequestId}/reviewRecords`,
    method: "POST",
    data: params.data
  })
}
// 评审通过或者拒绝
export function confirmMerge(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/changeRequests/${params.changeRequestId}/confirmMerge/?agreed=${params.agreed}`,
    method: "POST"
  })
}
// 查询当前节点所在基线
export function inProgressBaselines(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/inProgressBaselines`,
    method: "GET"
  })
}