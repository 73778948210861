<template>
  <div id="view_workflow">
    <el-dialog
      :visible="true"
      :title="$t('editFileType.workflow.title')"
      :close-on-click-modal="false"
      :show-close="true"
      width="35%"
      class="workflow_dialog"
      @close="handleClose(false)"
    >
      <div id="graph_container" v-loading="loading" style="flex: 1"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose(false)">{{
          $t("btn.closeBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get_workflow_resource } from "@/network/workflow/index.js";
import {
  workflow_config,
  registerWorkflowCell,
} from "@/components/workflowGraph/GraphConfig.js";
import { Graph } from "@antv/x6";
export default {
  props: {
    fileTypeId: {
      type: String,
      default: "",
    },
    layerNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      workflow_data: null,
      graph: null,
      init_ball: workflow_config.init_ball, // 小灰球
      init_edge: workflow_config.init_edge, // 小灰球的边
      loading: true,
    };
  },
  computed: {},
  mounted() {
    registerWorkflowCell();
    this.get_workflow_data();
  },

  methods: {
    handleClose(val) {
      this.$parent.workflow_dialog_visible = val;
    },
    get_workflow_data() {
      get_workflow_resource(
        this.get_pid(),
        this.fileTypeId,
        this.layerNumber
      ).then((res) => {
        this.workflow_data = res; // 保存无小灰球的接口数据
        this.create_graph();
      });
    },
    get_node_width(label) {
      let len = label.length;
      if (/[\u4E00-\u9FA5]+/g.test(label)) {
        return len * 17;
      } else {
        return len * 11;
      }
    },
    create_graph() {
      this.graph = new Graph({
        container: document.getElementById("graph_container"),
        width: "100%",
        height: 300,
        autoResize: true,
        interacting: {
          nodeMovable: false,
        },
        connecting: {
          router: {
            name: "manhattan",
            args: {
              padding: 1,
            },
          },
        },
      });
      //加入allin节点
      this.workflow_data.nodes
        .filter((item) => {
          return item.data && item.data.allIn;
        })
        .forEach((ele) => {
          let allin_node = {
            shape: "allin-rect",
            x: ele.x + 240,
            y: ele.y,
            id: `${ele.id}-allin`,
          };
          let allin_edge = {
            shape: "grey-edge",
            source: allin_node.id,
            target: ele.id,
          };
          this.workflow_data.nodes.push(allin_node);
          this.workflow_data.edges.push(allin_edge);
        });

      // 加入小灰球
      let [init_target] = this.workflow_data.nodes.filter((item) => {
        return item.data && item.data.initialStatus == true;
      });
      this.init_edge.target = init_target.id;
      this.workflow_data.nodes.unshift(this.init_ball);
      this.workflow_data.edges.unshift(this.init_edge);
      this.workflow_data.nodes.forEach((node) => {
        if (node.label) {
          const width = this.get_node_width(node.label);
          node.width = width > 200 ? width : 200;
          node.height = 30;
        }
      });
      this.graph.fromJSON(this.workflow_data);
      this.graph.zoom(-0.3);
      this.graph.centerContent(); // 内容显示在画布中央
      this.graph.scaleContentToFit();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#view_workflow {
  .workflow_dialog {
    text-align: left;
  }
}
</style>
