<template>
  <div class="activity-tab">
    <div class="activity-tab-header">
      <span style="margin-right: 15px">{{
        $t("nodeDetail.activity.info")
      }}</span>
      <el-radio-group
        v-model="activeTabName"
        size="small"
        fill=""
        @change="changeTab"
      >
        <el-radio-button label="comment">{{
          $t("nodeDetail.activity.tab.comment")
        }}</el-radio-button>
        <el-radio-button label="history">{{
          $t("nodeDetail.activity.tab.history")
        }}</el-radio-button>
        <el-radio-button v-if="hasGitLab" label="gitlab">{{
          $t("nodeDetail.activity.tab.code")
        }}</el-radio-button>
        <el-radio-button v-if="hasGitee" label="gitee">{{
          $t("nodeDetail.activity.tab.code1")
        }}</el-radio-button>
        <el-radio-button v-if="hasGithub" label="github">{{
          $t("nodeDetail.activity.tab.code2")
        }}</el-radio-button>
        <el-radio-button v-if="hasJenkins" label="jenkins">{{
          $t("nodeDetail.activity.tab.code3")
        }}</el-radio-button>
        <el-radio-button v-if="hasGerrit" label="gerrit">{{
          $t("nodeDetail.activity.tab.code4")
        }}</el-radio-button>
        <el-radio-button v-if="hasSVN" label="SVN"> SVN</el-radio-button>
        <el-radio-button label="pingshen">{{
          $t("nodeDetail.activity.tab.review")
        }}</el-radio-button>
        <el-radio-button v-if="fileTest" label="testRecords">{{
          $t("nodeDetail.activity.tab.testRecords")
        }}</el-radio-button>
        <el-radio-button label="reuse">{{
          $t("nodeDetail.activity.tab.reuse")
        }}</el-radio-button>
      </el-radio-group>
    </div>
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="" name="comment">
        <comment-activity></comment-activity>
      </el-tab-pane>
      <el-tab-pane label="" name="history">
        <history-record ref="hr"></history-record>
      </el-tab-pane>
      <el-tab-pane v-if="hasGitLab" label="" name="gitlab">
        <git-lab-code></git-lab-code>
      </el-tab-pane>
      <el-tab-pane v-if="hasGitee" label="" name="gitee">
        <gitee-code></gitee-code>
      </el-tab-pane>
      <el-tab-pane v-if="hasGithub" label="" name="github">
        <github-code></github-code>
      </el-tab-pane>
      <el-tab-pane v-if="hasSVN" label="" name="SVN">
        <svn-code></svn-code>
      </el-tab-pane>
      <el-tab-pane v-if="hasJenkins" label="" name="jenkins">
        <jenkins-code></jenkins-code>
      </el-tab-pane>
      <el-tab-pane v-if="hasGerrit" label="" name="gerrit">
        <gerrit-code></gerrit-code>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('nodeDetail.activity.tab.review')"
        name="pingshen"
      >
        <pingshen-record :node-info="nodeInfo"></pingshen-record>
      </el-tab-pane>
      <el-tab-pane
        v-if="fileTest"
        :label="$t('nodeDetail.activity.tab.testRecords')"
        name="testRecords"
      >
        <test-record></test-record>
      </el-tab-pane>
      <el-tab-pane label="" name="reuse">
        <reuse-records ref="re"></reuse-records>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CommentActivity from "./CommentActivity.vue";
import HistoryRecord from "./HistoryRecord.vue";
import GitLabCode from "./GitLabCode.vue";
import GiteeCode from "./GiteeCode.vue";
import GithubCode from "./GithubCode.vue";
import JenkinsCode from "./JenkinsCode.vue";
import GerritCode from "./GerritCode.vue";
import SvnCode from "./SVNCode.vue";
import pingshenRecord from "./PingshenRecord.vue";
import TestRecord from "./TestRecord.vue";
import ReuseRecords from "./ReuseRecords.vue";
import {
  gitlabProjects,
  getGiteeProjects,
  getGithubProjects,
} from "@/network/gitlab";
export default {
  components: {
    CommentActivity,
    HistoryRecord,
    GitLabCode,
    JenkinsCode,
    GerritCode,
    pingshenRecord,
    GiteeCode,
    GithubCode,
    TestRecord,
    SvnCode,
    ReuseRecords,
  },
  props: {
    nodeInfo: {
      type: Object,
    },
    nodeUuid: {
      type: String,
      default: "",
    },
    fileTest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTabName: "comment",
      historyLoaded: 0,
      projectId: "",
    };
  },
  computed: {
    hasGitLab() {
      if (this.$store.state.project.repository_list.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    hasGitee() {
      if (this.$store.state.project.gitee_repository_list.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    hasGithub() {
      if (this.$store.state.project.github_repository_list.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    hasJenkins() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "jenkins"
      );
    },
    hasGerrit() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "gerrit"
      );
    },
    hasSVN() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "svn"
      );
    },
  },
  mounted() {
    this.getRepository();
  },
  methods: {
    changeTab(tab, event) {
      if (tab === "history") {
        this.$refs.hr
          ? this.$refs.hr.getHistory(this.get_pid(), this.nodeUuid)
          : "";
      } else if (tab === "reuse") {
        this.$refs.re.getNodeOriginLog();
      }
    },
    getRepository() {
      const params = {
        projectId: this.get_pid(),
      };
      gitlabProjects(params).then((res) => {
        // 同步到vuex
        this.$store.commit("SET_REPOSITORY_LIST", res);
      });
      getGiteeProjects(params).then((res) => {
        // 同步到vuex
        this.$store.commit("SET_GITEE_REPOSITORY_LIST", res);
      });
      getGithubProjects(params).then((res) => {
        // 同步到vuex
        this.$store.commit("SET_GITHUB_REPOSITORY_LIST", res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-tab-header {
  text-align: left;
  font-size: 14px;
  margin-bottom: 35px;

  ::v-deep .el-radio-button__inner {
    border: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    padding: 0;
    padding-bottom: 3px;
    margin-right: 28px;
    border-radius: 0;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: rgba(48, 100, 143, 1);
    border-bottom: 1px solid rgba(48, 100, 143, 1);
    background-color: transparent;
    box-shadow: none;
  }
}

.activity-tab {
  ::v-deep .el-tabs__header {
    display: none;
  }
}

::v-deep .el-tabs__content {
  overflow: visible;
}
</style>
