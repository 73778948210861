<template>
  <div class="bg">
    <collapse :title="$t('nodeDetail.relationChart.title')">
      <relation-echarts
        v-loading="loading"
        :node_key="node_key"
        :node-info="nodeInfo"
        @hideLoading="hideLoading"
      />
    </collapse>
  </div>
</template>

<script>
import RelationEcharts from "../RelationEcharts.vue";
import Collapse from "./Collapse.vue";
export default {
  components: { Collapse, RelationEcharts },
  props: {
    node_key: {
      type: String,
    },
    nodeInfo: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    hideLoading(val) {
      this.loading = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 8px;
}
</style>
